import {useState} from 'react'
import libraryService from '../../../../services/apiServices/libraryService';

const useEditAsset = ({item, setMore, setRerenderSave, activeBtnHeader}) => {
    const [name, setName] = useState(item.name);
    const [descr, setDescr] = useState(item.description || '');
    const [privacyID, setPrivacyID] = useState(item.privacyID);
    
    const updateAsset = () => {
        const nameTemp = name.trim();
        const descrTemp = descr.trim();

        if(nameTemp.length > 0 ){
            
            const dataSend = {
                ID: item.id,
                Name:nameTemp,
                Description: descrTemp,
                PrivacyID: privacyID
                }
            
            if(activeBtnHeader === 1){

                const {req} = libraryService.updateAsset(dataSend);
                req.then( () => {setMore(false); setRerenderSave(prev => !prev)})
                    .catch(err => console.log(err));//add toast error msg
            }
            else if(activeBtnHeader === 0){

                const {req} = libraryService.updateLibraryScenes(dataSend);   //.updateAsset(dataSend);
                
                req.then( () => {setMore(false); setRerenderSave(prev => !prev)})
                    .catch(err => console.log(err));//add toast error msg
            }

        }else{
            alert('Name must not be empty!');
        }
      }
    
  return {name, descr, privacyID, setName, setDescr, setPrivacyID, updateAsset};
}

export default useEditAsset