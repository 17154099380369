import { Grid, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import stripeService from '../../../services/apiServices/stripeService';
import CurrentPlan from './CurrentPlan';
import Divider from '@mui/material/Divider';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import ButtonFab from '../../../styled/buttons/ButtonFab';

const classesStyle = makeStyles({
  buttons: {
    marginTop:'8px',
    width: '160px',
    height: '60.6px',
    backgroundColor: '#A996FF',
    borderRadius: '20px',
    fontFamily: 'Poppins',
    fontSize: '17px',
    color: '#4700AB',
    transitionDuration: '0.4s',
    webkitTransitionDuration: '0.4s',
    '&:hover': { backgroundColor: '#c9befa', transitionDuration: '0.1s' },
    '&:active': { boxShadow: '0 0 0 0 white', borderRadius: '4em', opacity: '1', transition: '0s' }
  },
  headlines: {
    fontFamily: 'Poppins',
    fontSize: '15px',
    color: 'white',
    opacity: '0.5'
  },
  currentPlanStyle: {
    backgroundColor: '#29293E',
    padding: '23px',
    width: '416px',
    fontFamily: "'Poppins'",
    borderRadius: '20px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  }
});

const SettingsBilling = ({  currentPlan }) => {

  const classes = classesStyle();
  const history = useHistory();

  const [openOptionsDialog, setOpenOptionsDialog] = useState(false);

  const handleClickPlans = () => {
    history.push('/plans', { currentPlan: currentPlan });
  };
  // const FabButton = styled(Fab)(({ theme }) => ({
  //   width: '144px',
  //   textTransform: 'none',
  //   marginTop: '20px',
  //   borderRadius: '16px',
  //   height: '61px',
  //   backgroundColor: '#A996FF',
  //   color: '#4700AB',
  //   fontWeight: 'bold',
  //   borderColor: '#A996FF',
  //   outline: 'none',
  //   fontSize: '17px',
  //   '&:hover': {
  //     backgroundColor: '#c9befa',
  //     color: '#4700AB'
  //   }
  // }));
  const openCustomerPortal = () => {
    const { axiosReq, cancelTokenSource } = stripeService.openCustomerPortal();
    axiosReq
      .then(async (resp) => {
        if (resp.data.success === true) {
          window.location.href = resp.data.url;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Grid container style={{ paddingLeft: '40px', color: 'white' }}>
        <Grid item xs={12} lg={12} md={12} sm={12} style={{ marginBottom: '20px' }}>
          <div className={classes.headlines}>Current Plans</div>
        </Grid>

        <Grid item xs={4} lg={12} md={4} sm={4}>
          {currentPlan === null && <div>No current plan selected</div>}
          {currentPlan !== null && <CurrentPlan currentPlan={currentPlan} classes={classes} showProgress={true} />}
        </Grid>

        <Grid item xs={6} lg={6} md={6} sm={6}>
          {currentPlan === null &&  <ButtonFab tittle='View Plans' onClickHandler={handleClickPlans} /> }
        </Grid>
        <Grid item xs={7} lg={7} md={7} sm={7}>
          <Divider light sx={{ height: '5px', color: '#FFFFFF', marginTop: '20px', marginBottom: '20px' }} />
        </Grid>
        <Grid item xs={12} lg={12} md={12} sm={12} style={{ marginBottom: '20px' }}>
          <div className={classes.headlines}>Payment method</div>
        </Grid>
        <Grid item xs={4} lg={4} md={4} sm={4}>
          {currentPlan !== null && (
            <div className={classes.headlines}>
              Visa ending with {currentPlan.last4} Exp. {currentPlan.expiring}
            </div>
          )}
        </Grid>
        <Grid item xs={6} lg={6} md={6} sm={6}>
          {currentPlan !== null && (
            <button className={classes.buttons} onClick={() => setOpenOptionsDialog(true)}>
              Update
            </button>
          )}
        </Grid>
      </Grid>

      <Dialog
        PaperProps={{
          sx: {
            minWidth: '50%',
            margin: '19px',
            padding:'28px'
          },
          style: { backgroundColor: '#14142B', borderRadius: '10px', color: 'white' }
        }}
        onClose={() => setOpenOptionsDialog(false)}
        open={openOptionsDialog}
      >
        <DialogTitle style={{ marginLeft: '0px', fontFamily: "'Poppins'", fontStyle: 'normal', fontWeight: '700', fontSize: '42px', lineHeight: '63px' }}>Payment options</DialogTitle>
          <DialogContent sx={{ display: 'flex', marginTop:'15px' }}>
            <div style={{width: '51%' }}>
              <div style={{ fontFamily: "'Poppins'", fontStyle: 'normal', marginLeft: '14px' }}>Current plan:</div>
              <div style={{width:'100%', marginLeft: '13px', marginTop: '10px' }}>
                <CurrentPlan currentPlan={currentPlan} classes={classes}  />
              </div>
            </div>
            <div style={{ marginLeft: '25px', marginTop:'10px'}}>
              <br />
              <div>
                We will nor charge your card without your permission <br /> <br /> You can change or cancel your subscription anytime
              </div>

              <button onClick={() => openCustomerPortal()}>Go to customer portal</button>
              <br />
              <br />
            </div>
          </DialogContent>
      </Dialog>
    </>
  );
};

export default SettingsBilling;
