import { makeStyles } from '@material-ui/core';
import PauseIcon from '@mui/icons-material/Pause';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';

const styleSoundAsset = makeStyles({
  soundAssetWrapp: {
    width: '100%',
    minHeight: '30px'
  },
  soundName: {
    position: 'absolute',
    top: '-40px',
    marginLeft: '20px',
    color: 'white',
    fontFamily: "'Poppins', sans-serif",
    fontStyle: 'normal',
    fontWeight: '700',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    zIndex: 10
  },
  musicLinesDetails: {
    position: 'relative',
    marginTop: '70px',
    width: '100%',
    display: 'flex',
    height: '50px'
  },

  musicLinesDetailsMobile: {
    position: 'relative',
    marginTop: '70px',
    width: '100%',
    display: 'flex'
  },
  musicBtnsWrapp: {
    width: '10%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    '& svg': {
      cursor: 'pointer'
    }
  },
  musicWavesWrapp: {
    width: '90%',
    padding: '5px',
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: '-44px'
  },
  musicWavesWrappMobile: {
    width: '99%',
    padding: '0px',
    display: 'flex',
    alignItems: 'flex-start',
    height: '30px'
  },
  timerSound: {
    width: '96%',
    padding: '0 15px',
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'space-between'
  },

  timerSoundMobile: {
    width: '99%',
    padding: '0px',
    marginTop: '4px',
    display: 'flex',
    justifyContent: 'space-between'
  },

  buttonsMix: {
    width: '100%',
    display: 'flex',
    paddingTop: '10px'
  },
  btns: {
    padding: '5px 10px',
    backgroundColor: '#A996FF',
    color: '#14142B',
    borderRadius: '6px',
    marginLeft: '10px',
    fontWeight: 'bold',
    border: '1px solid #A996FF'
  },
  divideLineWrapp: {
    width: '100%',
    padding: '10px'
  },
  divideLineWrappMobile: {
    width: '100%',
    padding: '5px'
  },
  assetWrapp: {
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: '12px'
  },
  waves: {
    width: '99%',
    height: '100%'
  },
  wavesMobile: {
    width: '99%'
  }
});

const SoundAsset = ({ isMobile, activeSound }) => {
  const [togglePlay, setTogglePlay] = useState(false);
  const [waveform, setWaveform] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const classes = styleSoundAsset();
  const testRef = useRef();

  const dataFormat = (data) => {
    if (!!data) {
      return new Date(data * 1000).toISOString().substr(14, 5);
    } else {
      return data;
    }
  };

  useEffect(() => {
    if (!!waveform) {
      waveform.destroy();
    }

    if (Object.keys(activeSound).length > 0) {
      setWaveform(
        WaveSurfer.create({
          container: '#waveform',
          waveColor: '#164A56',
          progressColor: '#199591',
          barWidth: 3,
          barRadius: 1,
          responsive: true,
          barGap: 2,
          height: isMobile ? 30 : 128
        })
      );

      return () => {
        if (!!waveform) {
          waveform.destroy();
          setWaveform(null);
        }
      };
    }
  }, [activeSound]);

  useEffect(() => {
    if (!!waveform && Object.keys(activeSound).length > 0 && !!activeSound.url) {
      waveform.load(activeSound.url);
      waveform.on('ready', function () {
        setTogglePlay(true);
        waveform.play();
      });

      waveform.on('audioprocess', () => {
        setCurrentTime(waveform.getCurrentTime());
      });

      waveform.on('finish', () => {
        waveform.setCurrentTime(0);
      });
    }

    return () => {
      pauseVideo();
    };
  }, [waveform]);

  const playBtnClick = () => {
    setTogglePlay(true);
    waveform.playPause();
  };

  const pauseVideo = () => {
    if (!!waveform && !waveform.isDestroyed && waveform.isPlaying()) {
      setTogglePlay(false);
      waveform.playPause();
    }
  };

  let mobileStyle = isMobile
    ? {
        fontSize: '12px',
        marginLeft: 0
      }
    : {};

  return (
    <>
      {Object.keys(activeSound).length > 0 && (
        <article className={classes.soundAssetWrapp}>
          <div>
            <div className={isMobile ? classes.musicLinesDetailsMobile : classes.musicLinesDetails}>
              <div className={classes.soundName} style={mobileStyle}>
                {activeSound.name}
              </div>
              {!isMobile && (
                <div className={classes.musicBtnsWrapp}>
                  <SkipPreviousIcon />
                  {togglePlay ? <PauseIcon onClick={pauseVideo} /> : <PlayCircleOutlineIcon onClick={playBtnClick} />}
                  <SkipNextIcon />
                </div>
              )}

              <div className={isMobile ? classes.musicWavesWrappMobile : classes.musicWavesWrapp}>
                <div className={isMobile ? classes.waves : classes.waves} id="waveform" ref={testRef}></div>
              </div>
            </div>

            <div className={isMobile ? classes.timerSoundMobile : classes.timerSound}>
              <p style={{ fontSize: isMobile && '12px' }}>{dataFormat(currentTime.toFixed(0))}</p>

              {isMobile && (
                <div className={classes.musicBtnsWrapp}>
                  <SkipPreviousIcon />
                  {togglePlay ? <PauseIcon onClick={pauseVideo} /> : <PlayCircleOutlineIcon onClick={playBtnClick} />}
                  <SkipNextIcon />
                </div>
              )}

              <p style={{ fontSize: isMobile && '12px' }}>{dataFormat(activeSound.originalLength)}</p>
            </div>

            {!isMobile && (
              <div className={classes.buttonsMix}>
                {activeSound.assetTags.map((item) => (
                  <button key={item.id} type="button" className={classes.btns}>
                    {item.name}
                  </button>
                ))}
              </div>
            )}
          </div>

          <div className={isMobile ? classes.divideLineWrappMobile : classes.divideLineWrapp}>
            <hr />
          </div>
        </article>
      )}
    </>
  );
};

export default SoundAsset;
