import { useEffect, useState } from 'react';
import Paginate from '../assetSection/Paginate';

const MobilePaginate = ({ clipsCount, setSkip, skip }) => {
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    let totPages = Math.ceil(clipsCount / 8);
    setTotalPages(totPages);
  }, [clipsCount]);

  return (
    <div style={{ width: '100%', overflowX: 'auto', padding: '10px' }}>
      <Paginate totalPages={totalPages} setSkip={setSkip} skip={skip} />
    </div>
  );
};

export default MobilePaginate;
