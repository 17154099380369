import { styled } from '@mui/material/styles';
import Fab from '@mui/material/Fab'; 

const ButtonFab = ({tittle, onClickHandler }) => {
  const FabButton = styled(Fab)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight:'bold',
    marginTop:'10px',
    width: '130.14px',
    height: '49.15px',
    border: '2px solid #A996FF !important',
    color: '#4700AB',
    borderRadius: '15px !important',
    backgroundColor:'#A996FF',
    '&:hover': {
      backgroundColor: '#c9befa',
      color: '#4700AB',
      transition: 'background-color 0.5s !important'
    },
    '@media (max-width: 548px)': {
      height: '55px',
      width: '142px'
    }
  }));
  return <FabButton size="large" variant="extended" onClick={onClickHandler}> {tittle} </FabButton>
} 
export default ButtonFab

ButtonFab.defaultProps = {
    tittle: 'Save',
    onClickHandler: ()=>{} 
}