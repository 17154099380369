const headWrapp = { width: '100%', display: 'flex', paddingBottom: '10px', borderBottom: '0.2px solid rgba(255,255,255, 0.25)', marginTop: '10px' };
const headItem = { display: 'flex', overflow: 'auto', alignItems: 'center', width: '11.5%', color: 'white', fontWeight: '600', fontSize: '13px', textAlign: 'left', fontFamily: "'Poppins'" };
const smallCellItem = { display: 'flex', alignItems: 'center', width: '8%' };

const TableHead = ({ setSelectedId }) => {
  const tableHead = ['Preview', 'Project Name', 'Date', 'Contributors', 'Status', 'Recipient', 'Template', ''];
  return (
    <div style={headWrapp} onClick={() => setSelectedId('')}>
      {tableHead.map((item) => (
        <div key={item} style={headItem}>
          {item}
        </div>
      ))}
      <div style={smallCellItem}></div>
    </div>
  );
};

export default TableHead;
TableHead.defaultProp = {
  setSelectedId: ()=>{}
}
