import React from 'react';
import delAsset from './Delete.module.css';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const DeleteModal = ({ setOpenDeleteModal, assetsForDelete, deleteFuncion, type, text, projectData, activeTab }) => {
  return (
    <div className={delAsset.delModWrapp}>
      <div className={delAsset.container}>
        <div className={delAsset.containerText}>
          {type === 'asset' && assetsForDelete.length <= 0 && <h4 style={{ paddingTop: '16px' }}>There is nothing selected for deletion!</h4>}

          {((type === 'asset' && assetsForDelete.length > 0) || type === 'scene-asset' || type === 'project') && (
            <div style={{ display: 'flex' }}>
                <h2>{type === 'project' ? 'Delete project?' : 'Delete asset?'}</h2>
              <IconButton
                style={{ marginLeft: 'auto', color: 'white' }}
                onClick={(e) => {
                  setOpenDeleteModal(false);
                  e.stopPropagation();
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          )}

          {assetsForDelete.length > 0 && type === 'asset' && <p> {text}</p>}
          {type === 'project' && (
            <p>
              {text} {projectData.name}
            </p>
          )}
          {type === 'scene-asset' && <p>{text}</p>}
        </div>
        <div className={delAsset.btnWrapper}>
          <button onClick={() => setOpenDeleteModal(false)} className={delAsset.cancelBtn}>
            Cancel
          </button>

          {((type === 'asset' && assetsForDelete.length > 0) || type === 'project' || type === 'scene-asset') && (
            <button
              className={delAsset.deleteBtn}
              onClick={() => {
                if (type === 'project') {
                  deleteFuncion(projectData.id);
                } else if (type === 'asset' || type === 'scene-asset') {
                  deleteFuncion();
                }
                setOpenDeleteModal(false);
              }}
            >
              Delete
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;

DeleteModal.defaultProps = {
  assetsForDelete: [],
  text: 'Are you sure you want to delete',
  type: '',
  projectData: { id: '' }
};
