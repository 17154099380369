import { Grid, makeStyles } from '@material-ui/core';
import { fabric } from 'fabric';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { AiFillCloseCircle, AiOutlineSave } from 'react-icons/ai';
import { toast } from 'react-toastify';
import assetsService from '../../../../../services/apiServices/assetsService';
import DeleteModal from '../../../../modals/deleteModal/DeleteModal';
import { rangeColors } from '../../../../timeline/projectTime/AssetRangeColors';
import AssetVolume from './sceneAsset/AssetVolume';
import AudioSceneAsset from './sceneAsset/AudioSceneAsset';
import ImageSceneAsset from './sceneAsset/ImageSceneAsset';
import TextSceneAsset from './sceneAsset/TextSceneAsset';
import VideoSceneAsset from './sceneAsset/VideoSceneAsset';

const useStyles = makeStyles({
  sceneContent: {
    display: 'flex',
    backgroundColor: '#262338',
    borderRadius: '5px',
    overflowX: 'scroll',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      width: '10px'
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555'
    }
  },
  sceneAsset: {
    height: '70px'
  },
  volumeStyle: {
    display: 'flex',
    position: 'absolute',
    width: '135px',
    marginTop: '5px',
    bottom: '0px'
  },
  saveTextIcon: {
    fill: 'black',
    cursor: 'pointer',
    position: 'absolute',
    bottom: '0px',
    left: '0px'
  }
});

const getItemStyle = (isDragging, draggableStyle, item) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: isDragging ? '1px' : '0px',
  // margin: `0 35px 0 0`
  display: 'flex',
  justifyContent: 'end',
  borderRadius: '8px',
  position: 'relative',
  outline: '4px solid ' + rangeColors[item.asset.typeID],
  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'transparent',

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = (isDraggingOver, itemsLength) => ({
  //background: '#14142A', //isDraggingOver ? "lightblue" : "lightgrey",
  display: 'flex',
  padding: 8,
  position: 'relative'
  //width: itemsLength * 68.44 + 16
});

const SceneContent = ({ activeSceneContent = [], setSelectedAsset, setSceneAssetsReorder, setOpenAdvancedTextOptions, setInteractObject, setCurrentAttribute, setLibraryUpdated, selectAssetFromSceneList }) => {
  const classes = useStyles();
  const [confirmRemoveAssetID, setConfirmRemoveAssetID] = useState(null);
  const [showAssetOption, setShowAssetOption] = useState(null);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const masterClipIndex = list.indexOf(list.find((x) => x.isMaster === true));
    if (masterClipIndex === 0) {
      endIndex = endIndex + 1;
      startIndex = startIndex + 1;
    }
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  // https://codesandbox.io/s/4wrv2v0lo9
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(activeSceneContent, result.source.index, result.destination.index);

    // set z-index
    for (let i = 1; i <= items.length; i++) {
      let index = items.length - 1;

      // set master clip behind all assets on screen
      if (items[index - i + 1].isMaster) {
        items[index - i + 1].z = 1;
      } else {
        items[index - i + 1].z = i + 1;
      }
    }

    setSceneAssetsReorder(items[0].sceneID, items);
  };

  const displayItem = (item) => {
    if (item.asset.type === 'video') {
      return <VideoSceneAsset key={item.assetStyleID} asset={item} setSelectedAsset={selectAssetFromSceneList} activeSceneContent={activeSceneContent} setCurrentAttribute={setCurrentAttribute} />;
    } else if (item.asset.type === 'image') {
      return <ImageSceneAsset key={item.assetStyleID} asset={item} setSelectedAsset={selectAssetFromSceneList} activeSceneContent={activeSceneContent} setCurrentAttribute={setCurrentAttribute} />;
    } else if (item.asset.type === 'html') {
      return (
        <TextSceneAsset
          key={item.assetStyleID}
          asset={item}
          setSelectedAsset={selectAssetFromSceneList}
          activeSceneContent={activeSceneContent}
          setCurrentAttribute={setCurrentAttribute}
          setOpenAdvancedTextOptions={setOpenAdvancedTextOptions}
        />
      );
    } else if (item.asset.type === 'audio') {
      return <AudioSceneAsset key={item.assetStyleID} asset={item} setSelectedAsset={selectAssetFromSceneList} activeSceneContent={activeSceneContent} setCurrentAttribute={setCurrentAttribute} />;
    }
  };

  const deleteAssetFromScenes = () => {
    if (!!confirmRemoveAssetID) {
      let item = {};
      item.type = 'removeasset';
      item.assetStyleID = confirmRemoveAssetID;
      setInteractObject(item);
    }
    setConfirmRemoveAssetID(null);
  };

  return (
    <Grid item xs={12} className={classes.sceneContent} id="asset-list">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver, activeSceneContent.length)} {...provided.droppableProps}>
              {activeSceneContent
                .filter((x) => x.deleted === false && x.isMaster !== true)
                .map((item, index) => (
                  <Draggable key={item.assetStyleID} draggableId={item.assetStyleID} index={index} disableInteractiveElementBlocking={true}>
                    {(provided, snapshot) => (
                      <div
                        style={{ margin: `0 35px 0 0` }}
                        onMouseEnter={(e) => {
                          e.stopPropagation();
                          setShowAssetOption(item.assetStyleID);
                        }}
                        onMouseLeave={(e) => {
                          e.stopPropagation();
                          setShowAssetOption(null);
                        }}
                      >
                        <div data-z={item.z} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, item)}>
                          <AiFillCloseCircle
                            style={{ color: '#CA024F', backgroundColor: 'white', borderRadius: '50%', fontSize: '25px', position: 'absolute', cursor: 'pointer', zIndex: 5, alignSelf: 'center', right: '-12px' }}
                            onMouseOver={(e) => {
                              e.target.style.color = 'green';
                            }}
                            onMouseOut={(e) => {
                              e.target.style.color = '#CA024F';
                            }}
                            onClick={() => {
                              setConfirmRemoveAssetID(item.assetStyleID);
                            }}
                          />
                          {displayItem(item)}
                        </div>
                        {(item.asset.type === 'audio' || item.asset.type === 'video') && showAssetOption === item.assetStyleID && (
                          <AssetVolume asset={item} color={item.asset.type === 'audio' ? 'black' : 'white'} volumeStyle={classes.volumeStyle} setInteractObject={setInteractObject} />
                        )}
                        {item.asset.type === 'html' && showAssetOption === item.assetStyleID && (
                          <div style={{ position: 'relative' }}>
                            <AiOutlineSave
                              size={30}
                              className={classes.saveTextIcon}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                let styles = {};
                                if (!!item.canvasStyle) {
                                  styles = JSON.parse(item.canvasStyle);
                                }

                                let text = new fabric.IText(item.asset.text?.replace('\\n', '\n'), {
                                  ...styles,
                                  ...{
                                    lockScalingFlip: true,
                                    width: item.w,
                                    height: item.h,
                                    top: item.y,
                                    left: item.x
                                  }
                                });
                                item.htmlImageBase64 = text.toDataURL('image/png');
                                const { req, cancelTokenSource } = assetsService.saveAssetToLibrary(item);
                                req.then((resp) => {
                                  if (resp.data.success === true) {
                                    setLibraryUpdated(new Date().getTime());
                                    toast.success('Asset saved.', {
                                      position: 'top-right',
                                      autoClose: 2000,
                                      hideProgressBar: false,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true
                                    });
                                  }
                                });
                              }}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {!!confirmRemoveAssetID && <DeleteModal setOpenDeleteModal={setConfirmRemoveAssetID} deleteFuncion={deleteAssetFromScenes} type="scene-asset" text="Are you sure you want to delete the asset from the scene?" />}
      {/*       
      <Dialog open={!!confirmRemoveAssetID} onClose={() => setConfirmRemoveAssetID(null)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Remove asset from the scene?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setConfirmRemoveAssetID(null)}>Close</Button>
          <Button
            onClick={() => {
              if (!!confirmRemoveAssetID) {
                let item = {};
                item.type = 'removeasset';
                item.assetStyleID = confirmRemoveAssetID;
                setInteractObject(item);
              }
              setConfirmRemoveAssetID(null);
            }}
            autoFocus
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog> */}
    </Grid>
  );
};

export default SceneContent;
