import { useReducer } from 'react';

const initialState = {
  stepStart: {
    projectName: ''
  },
  step0: {
    templateId: ''
  },
  step1: [
    [
      //client
      {
        id: Math.random().toString(),
        value: `What were your challenges \nbefore you started using our \nproduct/service?`
      },
      {
        id: Math.random().toString(),
        value: 'How has our product/service \nhelped you in your business?'
      },
      {
        id: Math.random().toString(),
        value: 'Would you recommend others \nthis product/service?'
      }
    ],

    [
      //employee
      {
        id: Math.random().toString(),
        value: 'What’s your name and what \nposition do you have?'
      },
      {
        id: Math.random().toString(),
        value: 'Could you share a few details \nabout the favorite part \nof your role?'
      },
      {
        id: Math.random().toString(),
        value: 'What stands out to you \nabout the culture?'
      }
    ],
    [
      //partner
      {
        id: Math.random().toString(),
        value: 'Could you describe \nhow you work with us?'
      },
      {
        id: Math.random().toString(),
        value: 'What has been one of the \nbenefits of your work with us?'
      }
    ],
    [
      //education -- ex product
      {
        id: Math.random().toString(),
        value: 'Why did you choose \nto take this course?'
      },
      {
        id: Math.random().toString(),
        value: 'What was the best thing \nyou took away from the course?'
      },
      {
        id: Math.random().toString(),
        value: 'What would you like to say \nto others who are considering \ntaking the course?'
      }
    ]
  ],
  step2: {
    msg: 'Hello, first of all thank you very much for taking the time to respond with your honest experience about us with a video. It means a lot to us to hear your thoughts to share your experience so we can share it with others.',
    instructions: `-Make sure you have the light in your eyes and not in the lens.\n-Listen so that it is not noisy around you, if it is, it might be better to find a quieter place. If you have a headset it might be good to use it…\n-Record a 5-30 sec long answer to each question.\n-Be yourself and smile, we want to see you and not a script. Do a retake if you're not satisfied and don't forget to smile, be happy and share your joy\n-Don't forget to look into the camera and not the screen ;)`,
    email: ''
  }
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_VALUE':
      return { ...state, step1: [...state.step1.map((x, index) => (index === action.payload.typeId - 1 ? [...x.map((item) => (item.id === action.payload.id ? action.payload : item))] : x))] };
    case 'UPDATE_TEMPLATE':
      return { ...state, step0: { ...state.step0, templateId: action.payload } };
    case 'UPDATE_PROJECT_NAME':
      return { ...state, stepStart: { ...state.stepStart, projectName: action.payload } };
    case 'ADD_QUESTION':
      let allQs = [...state.step1];
      allQs[action.typeId - 1] = [...allQs[action.typeId - 1], action.payload];
      return { ...state, step1: [...allQs] };
    case 'DELETE_QUESTION':
      if (state.step1[action.typeId - 1].length > 1) {
        let newQs = [...state.step1];
        newQs[action.typeId - 1] = state.step1[action.typeId - 1].filter((x) => x.id !== action.payload);
        return { ...state, step1: [...newQs] };
      }
      return state;
    case 'UPDATE_INSTRUCTIONS':
      return { ...state, step2: { ...state.step2, instructions: action.payload } };
    case 'UPDATE_EMAIL':
      return { ...state, step2: { ...state.step2, email: action.payload } };
    case 'UPDATE_MESSAGE':
      return { ...state, step2: { ...state.step2, msg: action.payload } };
    case 'INIT DRAFT':
      const step1update = [...state.step1].map( (array, index) => (action?.payload?.typeId -1) === index ? action?.payload?.step1  : array);
      return { stepStart: action.payload.stepStart, step0: action.payload.step0, step1: step1update, step2: action.payload.step2 };
    default:
      return state;
  }
}

const useQuestions = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return { state, dispatch };
};

export default useQuestions;
