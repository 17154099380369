import Grid from '@material-ui/core/Grid';
import ProjectThumbnail from './ProjectThumbnail';

const ProjectsList = ({ isMobile, projects, setOpenProjSettingsDialog, setProjects, selectedMoreId, setSelectedMoreId }) => {
  return (
    <Grid container style={{ marginTop: '20px' }}>
      {projects.map((p) => (
        <Grid key={p.id} item xl={3} lg={4} md={6} sm={6} xs={12} align="center">
          <ProjectThumbnail
            isMobile={isMobile}
            selectedMoreId={selectedMoreId}
            setSelectedMoreId={setSelectedMoreId}
            key={p.id}
            data={p}
            setOpenProjSettingsDialog={setOpenProjSettingsDialog}
            projects={projects}
            setProjects={setProjects}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProjectsList;
