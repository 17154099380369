import Grid from '@material-ui/core/Grid';
import NewMoviePageThumbnail from './NewMoviePageThumbnail';
import companyApi from '../../services/apiServices/companyApi';
import { useState, useEffect } from 'react';

const ExportsList = ({ movies, setOpenModal, setVideoUrl, setMovies, selectedMoreId, setSelectedMoreId,isMobile  }) => {
  const [languageList, setLanguageList] = useState([]);

  useEffect(() => {
    const { req } = companyApi.getLanguages();

    req.then((res) => {
      if (res.data.success) {
        setLanguageList(res.data.languages);
      }
    });
  }, []);

  return (
    <Grid container style={{ marginTop: '20px' }}>
      {movies.map((p) => (
        <Grid key={p.id} item xl={3} lg={4} md={6} sm={6} xs={12} align="center">
          <NewMoviePageThumbnail isMobile={isMobile}  key={p.id} selectedMoreId={selectedMoreId} setSelectedMoreId={setSelectedMoreId} data={p} setOpenModal={setOpenModal} setMovies={setMovies} movies={movies} setVideoUrl={setVideoUrl} languageList={languageList} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ExportsList;
ExportsList.defaultProps = {
  movies: [], 
  setOpenModal: ()=>{}, 
  setVideoUrl: ()=>{}, 
  setMovies: ()=>{}, 
  selectedMoreId: '', 
  setSelectedMoreId: ()=>{},
  isMobile: false
}
