import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    filtWrapp:{
      width:'100%',
      display:'flex',
      marginTop:'8px'
    },
    filterLeft:{
      display:'flex',
      alignItems:'center',
      marginBottom: '10px'
    },
    filterRight:{
      display:'flex',
      justifyContent:'flex-end',
      alignItems:'center'
    },
    selWrapp:{
      width:'37%',
      '& select':{
        width:'100%',
        borderRadius:'6px',
        padding:'6px',
        color:'white',
        backgroundColor:'#262338',
        border:'1px solid #262338',
        outline:'none',
        cursor:'pointer',
      }
    },
    pageWrapp:{
      marginLeft:'20px',
      color:'white',
      '& span':{
        borderRight: '1px solid white',
        paddingRight:'6px',
        fontWeight:'bold'
      }
    },
    rightWrapp:{
      display:'flex',
      alignItems:'center'
    },
    deleteBtnFilt:{
      marginRight: '10px',
      border:'3px solid white',
      borderRadius:'5px',
      color:'white',
      backgroundColor:'#262338',
      fontWeight:'bold',
      padding:'5px 27px'
    },
    uploadWrapp:{
      cursor:'pointer',
      height:'90%',
      padding:'5px',
      backgroundColor:'#CA0250',
      border: '2px solid #CA0250',
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      marginLeft:'10px',
      borderRadius:'6px',
      '& span':{
        marginLeft:'5px'
      }
    },

    uploadMobileWrapp:{
      cursor:'pointer',
      height:'100%',
      padding:'10px 20px',
      backgroundColor:'#CA0250',
      border: '2px solid #CA0250',
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      marginLeft:'10px',
      borderRadius:'12px',
      '& span':{
        marginLeft:'11px'
      }
    },
    uploadModal: {
      position: 'absolute',
      width: 500,
      backgroundColor: '#14142B',
      outline: 'none',
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
      padding:'30px',
      borderRadius:'12px',
      ['@media (max-width:500px)']: { 
        width: '90%'
      }
    },
    uploadTitleWrapp:{
      display:'flex'
    },
    uploadBody:{
      marginTop:'22px',height: '250px', color:'white', backgroundColor: '#14142B', display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center'
    }
  });

const useFilterAssetStyle = () => {
    const classes = useStyles();
  
    return {classes}
}

export default useFilterAssetStyle