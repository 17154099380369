import testimonialService from '../../../services/testimonialService';

const useInspiration = ({ setDraftObject, setIsDraftedTemplate }) => {
  const checkIsDrafted = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const testimonialId = urlParams.get('testimonialId');

    if (!!testimonialId) {
      const { req } = testimonialService.getSingleDraftedTestimonial(testimonialId);

      req
        .then((response) => {
          if (response?.data?.success) {
            req.then(response => {
              if (response?.data?.success) {
                const fetchedData = response?.data?.data;
                const editedObjectResponse = {
                  typeId: fetchedData?.testimonialTypeID,
                  stepStart: {
                    projectName: fetchedData?.name
                  },
                  step0: {
                    templateId: fetchedData?.templateProjectID
                  },
                  step1: fetchedData?.questions,
                  step2: {
                    msg: fetchedData?.message,
                    instructions: fetchedData?.instructions,
                    email: fetchedData?.email
                  }
                };

                setDraftObject(editedObjectResponse);
                setIsDraftedTemplate(true);
              }
            });
          }
        })
        .catch(() => setIsDraftedTemplate(true));
    }
  };

  return { checkIsDrafted };
};

export default useInspiration;
useInspiration.defaultProps = {
  setDraftObject: () => {},
  setIsDraftedTemplate: () => {}
};
