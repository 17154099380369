import template2img from '../../../pics/template_2.png';
import QuestionButton from '../../button/next/QuestionButton';
import styleStepZero from './stepZero.module.css';

const TEMPLATE1_ID = '24a345d5-d14b-4211-9a16-d195814505c7';
const TEMPLATE2_ID = 'd6e425dc-ee23-4a6e-b50f-5af2c367183b';

const StepZero = ({ state, dispatch, setQuestionSteps }) => {
  const chooseTemplate = (id) => {
    dispatch({ type: 'UPDATE_TEMPLATE', payload: id });
  };

  return (
    <>
      <div>Choose Template:</div>
      <div className={styleStepZero.templateContainer}>
        {/* <img
          style={{ outline: state?.step0?.templateId === TEMPLATE1_ID ? '2px solid white' : '' }}
          className={styleStepZero.templateImg}
          src={template1img}
          alt="template 1"
          onClick={() => chooseTemplate(TEMPLATE1_ID)}
        /> */}
        <img
          style={{ outline: state?.step0?.templateId === TEMPLATE2_ID ? '2px solid white' : '' }}
          className={styleStepZero.templateImg}
          src={template2img}
          alt="template 2"
          onClick={() => chooseTemplate(TEMPLATE2_ID)}
        />
      </div>

      <div className={styleStepZero.buttonArea}>
        <QuestionButton setQuestionSteps={setQuestionSteps} state={state} isZeroStep={true} />
      </div>
    </>
  );
};

export default StepZero;
StepZero.defaultProps = {
  state: [],
  dispatch: () => {},
  setQuestionSteps: () => {}
};
