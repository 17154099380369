import { styled } from '@mui/material/styles';
import Fab from '@mui/material/Fab';

const SaveDraftButton = ({ tittle, onClickHandler }) => {
  const SaveButton = styled(Fab)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    marginRight: '5px',
    marginTop: '10px',
    width: '130.14px',
    height: '49.15px',
    border: '2px solid white !important',
    color: 'white',
    borderRadius: '15px !important',
    backgroundColor: '#14142B',
    '&:hover': {
      backgroundColor: '#282e30',
      transition: 'background-color 0.5s !important'
    },
    '@media (max-width: 548px)': {
      height: '55px',
      width: '142px'
    }
  }));
  return (
    <SaveButton size="large" variant="extended" onClick={onClickHandler}>
      {tittle}
    </SaveButton>
  );
};

export default SaveDraftButton;
