import Modal from '@mui/material/Modal';
import { useState } from 'react';
import useProjectsSection from '../custom/useProjectsSection';
import tableStyle from './table.module.css';
import TableHead from './TableHead';
import TableItem from './TableItem';

const Table = ({ allProjects, setAllProjects, selectedId, isMobile, setSelectedId }) => {
  const [openPreview, setOpenPreview] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const { deleteHandler, selectProject } = useProjectsSection({ allProjects, setAllProjects });

  return (
    <div className={tableStyle.tableWrapp}>
      <div className={tableStyle.tableMain}>
        <TableHead setSelectedId={setSelectedId} />

        <div style={{ width: '100%' }}>
          {allProjects.length > 0 &&
            allProjects.map((item) => (
              <TableItem
                key={item.id}
                allProjects={allProjects}
                setAllProjects={setAllProjects}
                isMobile={isMobile}
                item={item}
                setSelectedId={setSelectedId}
                selectedId={selectedId}
                selectProject={selectProject}
                deleteHandler={deleteHandler}
                setOpenPreview={setOpenPreview}
                setVideoUrl={setVideoUrl}
              />
            ))}
        </div>
      </div>

      <Modal
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <video controls width={'70%'}>
          <source src={videoUrl} />
        </video>
      </Modal>
    </div>
  );
};

export default Table;
Table.defaultProps = {
  allProjects: [],
  setAllProjects: () => {},
  selectedId: '',
  isMobile: false,
  setSelectedId: () => {}
};
