import { useState, useEffect } from 'react';
import stylePlay from './PlayVideo.module.css';
import playMovieService from '../../services/apiServices/movieShareService';

const PlayVideo = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    let tempUrl = window.location.href;
    let payloadUrl = tempUrl.split('play-video');
    let tempLink = payloadUrl[1];
    let shareId = tempLink.substring(1);
    const { req } = playMovieService.getShareUrl(shareId);

    req.then((res) => {
      if (res.data.success === true) {
        setData(res.data.data);
      }
    });
  }, []);

  return (
    <>
      {Object.keys(data).length > 0 && (
        <>
          <section className={stylePlay.video__Wrapp}>
            <video className={stylePlay.video} controls>
              <source src={data.url} type="video/mp4" />
            </video>
          </section>
        </>
      )}
    </>
  );
};

export default PlayVideo;
