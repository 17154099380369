import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useState } from 'react';
import searchIcon from '../../movies/media_movie/Search.png';
import FilterRight from '../assetSection/filterAsset/FilterRight';
import MobileTabsLibrary from './MobileTabsLibrary';

const MobileLibraryHeader = ({
  mobileSubLinks,
  activeSubLibTab,
  setActiveSubLibTab,
  activeBtnHeader,
  assetsData,
  setAssetsData,
  deleteAssets,
  showUpload,
  activeTab,
  callAssets,
  assetsForDelete,
  setSearchTerm,
  setActiveBtnHeader
}) => {
  const [folderMobileModal, setFolderMobileModal] = useState(false);
  const [searchInputMobileModal, setSearchInputMobileModal] = useState(false);

  return (
    <Grid style={{ width: '100%', padding: '5px', marginTop: '75px' }}>
      <MobileTabsLibrary tittleSection="Asset Library" mobileSubLinks={mobileSubLinks} setActiveSubLibTab={setActiveSubLibTab} activeSubLibTab={activeSubLibTab} />

      <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '15px' }}>
        <div style={{ position: 'relative' }}>
          {/* <img src={folderIcon} alt='folder' onClick={()=>{
              setSearchInputMobileModal(false);
              setFolderMobileModal(prev => !prev);
            }} /> */}

          {/* {folderMobileModal && <div style={{position:'absolute', top: -8, left: '55px', backgroundColor:'#262237', borderRadius:'10px', width:'120px'}}>
              <div style={{padding:'5px', marginLeft:'12px'}}  
                  onClick={() => {  
                    setActiveBtnHeader(0)
                    setFolderMobileModal(false);
                  }}
              >
                  <span>Scenes</span>
              </div>

              <div style={{padding:'5px', marginLeft:'12px'}}
              onClick={() => {  
                setActiveBtnHeader(1)
                setFolderMobileModal(false);
              }
            }
              >
                  <span>Assets</span>
              </div>
            </div>
            } */}

          {searchInputMobileModal && (
            <input
              type="text"
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                position: 'absolute',
                top: -38,
                left: '50px',
                marginRight: '5px',
                fontSize: '14px',
                height: '32px',
                color: 'white',
                paddingLeft: '6px',
                backgroundColor: '#262237',
                borderRadius: '10px',
                border: '1px solid #262237',
                outline: 'none'
              }}
            />
          )}

          <img
            src={searchIcon}
            alt="search"
            onClick={() => {
              setFolderMobileModal(false);
              setSearchInputMobileModal((prev) => !prev);
            }}
          />
        </div>

        <Grid style={{ width: '45%' }}>
          <FilterRight
            onMobile={true}
            assetsData={assetsData}
            setAssetsData={setAssetsData}
            deleteAssets={deleteAssets}
            showUpload={showUpload}
            activeTab={activeTab}
            callAssets={callAssets}
            assetsForDelete={assetsForDelete}
            width="150px"
          />
        </Grid>
        {/* <div style={{position:'relative', display:'flex', alignItems:'center'}}>
           <UploadFilAsset setOpenUploadModal={()=>{}} isOnBodyPicker={false} />

        </div> */}
      </Box>
    </Grid>
  );
};

export default MobileLibraryHeader;
