import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import ProjectThumbnailInspiration from './ProjectThumbnailInspiration';
import sceneService from '../../services/apiServices/sceneService';
import FullPreviewModal from '../modals/FullPreviewModal';
import SoundtrackStage from '../stage/stage-objects/soundtrackStage/SoundtrackStage';

const useStyles = makeStyles({
  projectListInspiration: {
    display: 'flex',
    marginTop: '20px',
    width: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.4em',
      height: '7px',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  }
});

const ProjectsListInspiration = ({ type, projects }) => {
  const classes = useStyles();
  const [selectedProjectID, setSelectedProjectID] = useState(null);
  const [scenes, setScenes] = useState([]);
  const [soundtrack, setSoundtrack] = useState({});
  const [projectDuration, setProjectDuration] = useState({});
  const [openFullPreviewModal, setOpenFullPreviewModal] = useState(false);

  const GetProjectScenes = (projectID) => {
    const { req, cancelTokenSource } = sceneService.getProjectScenes(projectID);
    req.then((resp) => {
      if (resp.data.success === true) {
        if (!!resp.data.project) {
          if (!!resp.data.project.soundtrack) {
            setSoundtrack(resp.data.project.soundtrack);
          } else {
            setSoundtrack({});
          }
          let scenes = resp.data.scenes;
          setScenes(scenes);
          let totalDuration = 0;
          for (let i = 0; i < scenes.length; i++) {
            let sceneAssets = scenes[i].assets.filter((x) => x.deleted === false).sort((a, b) => b.z - a.z);

            if (sceneAssets.length > 0) {
              for (let j = 0; j < sceneAssets.length; j++) {
                // scene duration is by the first clip
                let masterClipLength = sceneAssets[j].isMaster === true ? sceneAssets[j].length : 0;
                totalDuration += masterClipLength;
              }
            }
          }
          setProjectDuration(totalDuration);
          setOpenFullPreviewModal(true);
        }
      }
    });
  };

  useEffect(() => {
    if (!openFullPreviewModal) {
      setSoundtrack({});
    }
  }, [openFullPreviewModal]);

  return (
    <div className={classes.projectListInspiration}>
      {projects.map((p) => (
        <div key={type + p.id} align="center" style={{ marginRight: '2%' }}>
          <ProjectThumbnailInspiration type={type} data={p} setSelectedProjectID={setSelectedProjectID} GetProjectScenes={GetProjectScenes} />
        </div>
      ))}

      <FullPreviewModal
        key={selectedProjectID}
        scenes={scenes}
        projectDuration={projectDuration}
        setOpenFullPreviewModal={setOpenFullPreviewModal}
        openFullPreviewModal={openFullPreviewModal}
        hasUseTemplate={true}
        projectId={selectedProjectID}
      />
      <SoundtrackStage key={soundtrack.id} soundtrack={soundtrack} activeSceneID={null} scenes={scenes} openFullPreviewModal={openFullPreviewModal} setInteractObject={() => {}} isHidden={true} />
    </div>
  );
};

export default ProjectsListInspiration;
