import Grid from '@mui/material/Grid';
import { useRef } from 'react';
import { FiUpload } from 'react-icons/fi';
import MovieThumbnailUpload from '../../MovieThumbnailUpload';
import useMovieThumbnailUpload from '../useMovieThumbnailUpload';

const myStyles = {
  gridWrapp: { overflow: 'auto', borderRadius: '15px', marginTop: '6px' },
  imgWrapp: { display: 'flex', p: 2, width: '106px', height: '70px', borderRadius: '15px' },
  uploadBtn: { padding: '0px 40px', height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '2px dotted #4E4B66', borderRadius: '15px', marginRight: '5px' },
  imagg: { opacity: 0.5, borderRadius: '15px', marginRight: '5px', cursor: 'pointer', width: '106px', height: '61px', border: '2px solid  #4E4B66' }
};

const ThumbnailPart = ({ classes, data, thumbnails, thumbnailUrl, setThumbnailUrl }) => {
  const { onMovieThumbnailUpload } = useMovieThumbnailUpload({ data, setThumbnailUrl });
  const uploadFileRef = useRef(null);

  const uploadThumbnail = (e) => {
    e.stopPropagation();
    let el = uploadFileRef.current;
    el.click();
  };

  return (
    <>
      <p className={classes.shareMovieDialogText}>Thumbnail:</p>
      <Grid container spacing={1} style={myStyles.gridWrapp}>
        <Grid item xs={3}>
          <div style={myStyles.imgWrapp}>
            <div
              style={myStyles.uploadBtn}
              onClick={(e) => {
                uploadThumbnail(e);
              }}
            >
              <FiUpload color="#4E4B66" size={20} />
              <MovieThumbnailUpload data={data} setThumbnailUrl={setThumbnailUrl} uploadFileRef={uploadFileRef} />
            </div>
            {!!thumbnailUrl && (
              <img
                src={thumbnailUrl}
                style={{
                  borderRadius: '15px',
                  border: '2px solid #A996FF',
                  marginRight: '3px',
                  cursor: 'pointer',
                  minWidth: '106px',
                  width: '106px',
                  height: '61px'
                }}
              />
            )}
            {thumbnails.map((item) => {
              return (
                <img
                  key={item}
                  src={item}
                  style={myStyles.imagg}
                  alt="thumbnail"
                  onClick={async (e) => {
                    const response = await fetch(item);
                    const blob = await response.blob();
                    const blobFile = new File([blob], 'thumbnail', { type: 'image/jpeg' });

                    onMovieThumbnailUpload(e, blobFile);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                />
              );
            })}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default ThumbnailPart;
