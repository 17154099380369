import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Modal from '@mui/material/Modal';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import projectApi from '../../services/apiServices/projects/projectApi';
import HeaderAsset from '../header/HeaderAsset';
import FilterLeft from '../library/assetSection/filterAsset/FilterLeft';
import MobilePaginate from '../library/mobile/MobilePaginate';
import ExportsList from './ExportsList';
import searchIcon from './media_movie/Search.png';
import ProjectsList from './ProjectsList';
import AddProjectSection from './projsett/add-project/AddProjectSection';

const newMoviesPage = makeStyles({
  libraryWrapp: {
    marginLeft: '0px',
    marginRight: '4%',
    color: 'white',
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    ['@media (max-width: 360px)']: {
      marginRight: '0px'
    },
    ['@media (max-width: 451px)']: {
      marginLeft: '4%'
    },
    ['@media (min-width: 960px)']: {
      marginLeft: '4%'
    }
  }
});

const NewMoviesPage = ({ triggerNotifications, isMobile }) => {
  const cssStyle = newMoviesPage();
  const location = useLocation();
  const [activeBtnHeader, setActiveBtnHeader] = useState(1);
  const [movies, setMovies] = useState([]);
  const [projects, setProjects] = useState([]);
  const [moviesTotalPages, setMoviesTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState(null);
  const [moviesPage, setMoviesPage] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [selectedMoreId, setSelectedMoreId] = useState('');
  const [folderMobileModal, setFolderMobileModal] = useState(false);
  const [searchInputMobileModal, setSearchInputMobileModal] = useState(false);

  const GetNewMovies = (timeout, page) => {
    const searchTimeout = setTimeout(() => {
      const { axiosReq, cancelTokenSource } = projectApi.getNewMovies(page, 12, searchTerm);

      axiosReq.then((resp) => {
        if (resp.data.success === true) {
          setMovies(resp.data.data);

          setMoviesTotalPages(Math.ceil(resp.data.totalCount / 12));
        }
      });
    }, timeout);

    return searchTimeout;
  };

  const GetProjects = (timeout, page) => {
    const searchTimeout = setTimeout(() => {
      const { axiosReq, cancelTokenSource } = projectApi.getAllProjects(page, 12, searchTerm, false);
      axiosReq.then((resp) => {
        if (resp.data.success === true) {
          setProjects(resp.data.data);
          setMoviesTotalPages(Math.ceil(resp.data.totalCount / 12));
        }
      });
    }, timeout);

    return searchTimeout;
  };

  useEffect(() => {
    if (searchTerm === null) return;

    let searchTimeout = activeBtnHeader === 1 ? GetNewMovies(1100, moviesPage) : GetProjects(1100, moviesPage);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchTerm, activeBtnHeader]);

  useEffect(() => {
    let searchTimeout = activeBtnHeader === 1 ? GetNewMovies(200, moviesPage) : GetProjects(200, moviesPage);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [moviesPage, activeBtnHeader, triggerNotifications]);

  useEffect(() => {
    if (!!location.state) {
      const projectTab = location.state.projectTab;
      if (projectTab === true) {
        setActiveBtnHeader(0);
      }
    }
  }, []);

  return (
    <div className={cssStyle.libraryWrapp}>
      {!isMobile ? (
        <HeaderAsset title="Movies" setSearchTerm={setSearchTerm} isMobile={isMobile}></HeaderAsset>
      ) : (
        <Grid style={{ width: '100%', padding: '5px', marginTop: '75px' }}>
          <Box style={{ color: 'white', fontFamily: 'Poppins', fontWeight: 700 }}>
            <h4>Movies</h4>
          </Box>

          <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '5px' }}>
            <div style={{ position: 'relative' }}>
              {/* <img
                src={folderIcon}
                alt="folder"
                onClick={() => {
                  setSearchInputMobileModal(false);
                  setFolderMobileModal((prev) => !prev);
                }}
              />

              {folderMobileModal && (
                <div style={{ position: 'absolute', top: -8, left: '55px', backgroundColor: '#262237', borderRadius: '10px', width: '120px' }}>
                  <div
                    style={{ padding: '5px', marginLeft: '12px' }}
                    onClick={() => {
                      setMoviesTotalPages(1);
                      setActiveBtnHeader(0);
                      setFolderMobileModal(false);
                    }}
                  >
                    <span>Projects</span>
                  </div>

                  <div
                    style={{ padding: '5px', marginLeft: '12px' }}
                    onClick={() => {
                      setMoviesTotalPages(1);
                      setActiveBtnHeader(1);
                      setFolderMobileModal(false);
                    }}
                  >
                    <span>Exports</span>
                  </div>
                </div>
              )} */}
            </div>

            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              {searchInputMobileModal && (
                <input
                  type="text"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ marginRight: '5px', fontSize: '14px', height: '32px', color: 'white', paddingLeft: '6px', backgroundColor: '#262237', borderRadius: '10px', border: '1px solid #262237', outline: 'none' }}
                />
              )}
              <img
                src={searchIcon}
                alt="search"
                onClick={() => {
                  setFolderMobileModal(false);
                  setSearchInputMobileModal((prev) => !prev);
                }}
              />
            </div>
          </Box>
        </Grid>
      )}

      {!isMobile && (
        <Grid container>
          <Grid item xs={12} sm={12} lg={6} md={6}>
            <FilterLeft totalPages={moviesTotalPages} setSkip={setMoviesPage} skip={moviesPage} />
          </Grid>
          <Grid item xs={12} sm={12} lg={6} md={6}>
            <AddProjectSection />
          </Grid>
        </Grid>
      )}

      {activeBtnHeader === 1 && (
        <ExportsList isMobile={isMobile} movies={movies} setOpenModal={setOpenModal} setVideoUrl={setVideoUrl} setMovies={setMovies} selectedMoreId={selectedMoreId} setSelectedMoreId={setSelectedMoreId} />
      )}
      {activeBtnHeader === 0 && <ProjectsList isMobile={isMobile} projects={projects} setProjects={setProjects} selectedMoreId={selectedMoreId} setSelectedMoreId={setSelectedMoreId} />}

      {isMobile && <MobilePaginate clipsCount={moviesTotalPages} setSkip={setMoviesPage} skip={moviesPage} />}

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <video controls width={'70%'}>
          <source src={videoUrl} />
        </video>
      </Modal>
    </div>
  );
};

export default NewMoviesPage;
NewMoviesPage.defaultProps = {
  triggerNotifications: false,
  isMobile: false
};
