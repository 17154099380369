import { useEffect, useState, useContext } from 'react';
import { ImageStagePreview } from '../../stage/stage-objects/imageStage/ImageStagePreview';
import { VideoStagePreview } from '../../stage/stage-objects/videoStage/VideoStagePreview';
import { AudioStagePreview } from '../../stage/stage-objects/audioStage/AudioStagePreview';
import TextStageCanvasPreview from '../../stage/stage-objects/textStage/TextStageCanvasPreview';
import { CurrentTimePreviewContext } from '../../../context/current_time_preview_context';

const FullProjectPreview = ({ scenes, projectDuration, timeRangePreviewClicked }) => {
  const [sceneAssets, setSceneAssets] = useState([]);
  const [sceneBackgrounds, setSceneBackgrounds] = useState([]);
  const [currentBackground, setCurrentBackground] = useState('#000000');
  const { currentTimePreview } = useContext(CurrentTimePreviewContext);

  useEffect(() => {
    const assets = [];
    for (let i = 0; i < scenes.length; i++) {
      let sceneAssets = scenes[i].assets.filter((x) => x.deleted === false);

      for (let j = 0; j < sceneAssets.length; j++) {
        assets.push(sceneAssets[j]);
      }
    }
    setSceneAssets(assets);
    setSceneBackgrounds(scenes.map((x) => x.assets.filter((x) => x.asset.type === 'background')).flat());
  }, [scenes]);

  useEffect(() => {
    let sceneBackground = sceneBackgrounds.find((x) => currentTimePreview <=  x.start + x.length)?.htmlBackground || '#000000';

    if (currentBackground !== sceneBackground) {
      setCurrentBackground(sceneBackground);
    }
  }, [currentTimePreview, sceneBackgrounds]);

  return (
    <>
      <div id="preview-assets" style={{ width: '786px', height: '442px', backgroundColor: currentBackground }}>
        {sceneAssets
          .filter((clip) => clip.asset.type === 'image')
          .map((clip) => (
            <ImageStagePreview image={clip} key={clip.assetStyleID} />
          ))}
        {sceneAssets
          .filter((clip) => clip.asset.type === 'video')
          .map((clip, index) => (
            <VideoStagePreview clip={clip} key={clip.assetStyleID} timeRangePreviewClicked={timeRangePreviewClicked} />
          ))}
        {sceneAssets
          .filter((clip) => clip.asset.type === 'audio')
          .map((clip, index) => (
            <AudioStagePreview audio={clip} key={clip.assetStyleID} timeRangePreviewClicked={timeRangePreviewClicked} />
          ))}
        {sceneAssets
          .filter((clip) => clip.asset.type === 'html')
          .map((clip) => (
            <TextStageCanvasPreview clip={clip} key={clip.assetStyleID} sceneDuration={projectDuration} sceneStart={0} />
          ))}
      </div>
    </>
  );
};

export default FullProjectPreview;
