import React from 'react';
import ImageTag from './ImageTag';
import VideoTag from './VideoTag';

const SceneTag = ({ checkToggle, item, classStyle }) => {
  let assetType = item.assets.find((x) => x.isMaster).asset.type;
  return (
    <>
      {assetType === 'video' && <VideoTag checkToggle={checkToggle} item={item.assets.find((x) => x.isMaster).asset} classStyle={classStyle} />}

      {(assetType === 'image' || assetType === 'html') && <ImageTag checkToggle={checkToggle} item={item.assets.find((x) => x.isMaster).asset} classStyle={classStyle} />}
    </>
  );
};

export default SceneTag;
