import useCustomSidePickerHooks from '../customHooks/useCustomSidePickerHooks';
import externalApis from '../../../services/apiServices/externalApis';
import Grid from '@material-ui/core/Grid';
import CustomGrid from '../../appArchitect/CustomGrid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useState, useEffect, useRef } from 'react';
import { Cards } from './Cards';

const PexelsPicker = ({ searchValue, currentAttribute }) => {
  const { useStyle } = useCustomSidePickerHooks();
  const [pexelPage, setPexelPage] = useState(1);
  const [pexelsVideos, setPexelsVideos] = useState([]);
  const [pexelsPhotos, setPexelsPhotos] = useState([]);
  const [type, setType] = useState('videos');

  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const scrollPexDivRef = useRef();

  const { bodyPickStyle } = useStyle();

  const handleChangeType = (event) => {
    setType(event.target.value);
  };

  const searchPexelData = (timeout, page) => {
    const searchTimeout = setTimeout(() => {
      const { axiosReq, cancelTokenSource } = type === 'videos' ? externalApis.getPexelVideos(searchValue, page) : externalApis.getPexelPhotos(searchValue, page);

      axiosReq
        .then((resp) => {
          if (resp.status === 200) {
            setIsLoading(false);
            if (type === 'videos') {
              setTotalPages(Math.ceil(resp.data.total_results / 16));
              setPexelsVideos([...pexelsVideos, ...resp.data[type]]);
            } else if (type === 'photos') {
              setTotalPages(Math.ceil(resp.data.total_results / 16));
              setPexelsPhotos([...pexelsPhotos, ...resp.data[type]]);
            }
          }
        })
        .catch((err) => console.log(err));
    }, timeout);

    return searchTimeout;
  };

  useEffect(() => {
    if (type === 'videos' && pexelsVideos.length > 0) {
      setPexelsVideos([]);
      setPexelPage(1);
    } else if (type === 'photos' && pexelsPhotos.length > 0) {
      setPexelsPhotos([]);
      setPexelPage(1);
    }
    let searchTimeout = searchPexelData(800, 1);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchValue, type]);

  useEffect(() => {
    if (pexelPage === 1) return;

    let searchTimeout = searchPexelData(1, pexelPage);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [pexelPage]);

  const onScroll = () => {
    if (scrollPexDivRef.current && !isLoading) {
      const { scrollTop, scrollHeight, clientHeight } = scrollPexDivRef.current;
      if (scrollTop + clientHeight > scrollHeight - 10 && pexelPage < totalPages) {
        setIsLoading(true);
        setPexelPage(pexelPage + 1);
      }
    }
  };

  return (
    <>
      <div style={{ display: 'flex', width: '100%', marginRight: '20px', justifyContent: 'end', color: '#A0A3BD' }}>
        <RadioGroup row value={type} onChange={handleChangeType}>
          <FormControlLabel value="videos" control={<Radio />} label="Videos" />
          <FormControlLabel value="photos" control={<Radio />} label="Images" />
        </RadioGroup>
      </div>
      <div id="pex-body-picker" style={{ height: !currentAttribute ? '415px' : '340px' }} className={bodyPickStyle.bodyPicker} onScroll={() => onScroll()} ref={scrollPexDivRef}>
        <Grid container className={bodyPickStyle.cardsBodyWrapp} spacing={2}>
          {type === 'videos' &&
            pexelsVideos.map((x) => {
              return (
                <CustomGrid key={x.id} item customBreakPoints={['xlg']} xl={3} xlg={4} sm={6} xs={1}>
                  <Cards key={x.id} headerAssetType={'pexelvideos'} data={x} pexelurl={x.image} />
                </CustomGrid>
              );
            })}
          {type === 'photos' &&
            pexelsPhotos.map((x) => {
              return (
                <CustomGrid key={x.id} item customBreakPoints={['xlg']} xl={3} xlg={4} sm={6} xs={1}>
                  <Cards key={x.id} headerAssetType={'pexelphotos'} data={x} pexelurl={x.src.tiny} />
                </CustomGrid>
              );
            })}
        </Grid>
      </div>
    </>
  );
};

export default PexelsPicker;
