import useStyleCardAsset from './useStyleCardAsset';
import { AiFillCloseCircle } from 'react-icons/ai';
import AddIcon from '@mui/icons-material/Add';

const DetailsCA = ({ item, assetTags, removeAssetTag, setOpenTagDialogFor }) => {
  const { styleMovieThumbnail } = useStyleCardAsset();
  const cssStyle = styleMovieThumbnail();

  const dataFormat = (data) => {
    if (!!data) {
      return new Date(data * 1000).toISOString().substr(14, 5);
    } else {
      return data;
    }
  };

  return (
    <div className={cssStyle.infoDiv}>
      <div className={cssStyle.movieName}>
        <span className={cssStyle.nameSpan}>{item.name.replace(/\.[^/.]+$/, '')}</span>
        {item.assetType !== 'image' && <span>{dataFormat(item.originalLength)}</span>}
      </div>

      <div style={{ marginTop: '7px' }}>
        <p style={{ color: 'white', fontSize: '13px' }}>{item.description}</p>
      </div>

      {(item.assetType === 'scenes' || item.assetType === 'video' || item.assetType === 'image' || item.assetType === 'audio' || item.assetType === 'html') && (
        <div className={cssStyle.bottBtnsWrapp}>
          <div className={cssStyle.bottBtns}>
            <AddIcon style={{ cursor: 'pointer', color: '#A996FF', fontWeight: 'bold' }} onClick={() => setOpenTagDialogFor(item.id)} size={45} />

            {assetTags.map((x) => {
              return (
                <div key={x.id} style={{ position: 'relative' }}>
                  <button>{x.name}</button>

                  <AiFillCloseCircle
                    style={{ color: '#CA024F', backgroundColor: 'white', borderRadius: '50%', cursor: 'pointer', zIndex: 5, position: 'absolute', right: '-5px', top: '-5px' }}
                    onMouseOver={(e) => (e.target.style.color = 'green')}
                    onMouseOut={(e) => (e.target.style.color = '#CA024F')}
                    onClick={() => removeAssetTag(x.id)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailsCA;
