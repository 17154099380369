import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.darkviolet.css';
import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAppStyle from './components/appArchitect/css/useAppStyle';
import { useCustomCheck } from './components/appArchitect/customHookAuth/useCustomCheck';
import LoggedIn from './components/appArchitect/LoggedIn';
import LoggedOut from './components/appArchitect/LoggedOut';
import PlayVideo from './components/play-video/PlayVideo';

function App() {
  const [isLogged, setIsLogged] = useState(useCustomCheck());
  const isMobile = /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent);

  const { classes } = useAppStyle();

  const theme = createTheme({
    breakpoints: {
      keys: ['xs', 'sm', 'md', 'lg', 'xlg', 'xl'],
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xlg: 1400,
        xl: 1920
      }
    }
  });

  return (
    <>
      <Router>
        <Switch>
          <Route path="/play-video/:url">
            <PlayVideo />
          </Route>
        </Switch>
      </Router>
      <DndProvider backend={HTML5Backend}>
        <ThemeProvider theme={theme}>
          <div className={classes.app}>
            {isLogged && <LoggedIn classes={classes} isMobile={isMobile} />}
            {!isLogged && <LoggedOut setIsLogged={setIsLogged} isMobile={isMobile} />}
            <ToastContainer />
          </div>
        </ThemeProvider>
      </DndProvider>
    </>
  );
}

export default App;
