import { Grid, makeStyles } from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
import { useContext, useEffect, useState } from 'react';
import { DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import { CurrentTimeContext } from '../../context/current_time_context';
import { PlayerIntervalContext } from '../../context/player_interval_context';
import { ReactComponent as DELETE_SOUNDTRACK } from '../../files/icons/DELETE_SOUNDTRACK.svg';
import { ReactComponent as PLAY_BIG } from '../../files/icons/PLAY_BIG.svg';
import movieService from '../../services/apiServices/movieService';
import projectApi from '../../services/apiServices/projects/projectApi';
import sceneService from '../../services/apiServices/sceneService';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '../../utilities/consts';
import { hasMissingTextImage, updateMissingTextImage } from '../../utilities/helpers';
import { ItemTypes } from '../../utilities/items';
import Stage from '../stage/Stage';
import SoundtrackStage from '../stage/stage-objects/soundtrackStage/SoundtrackStage';
import projedit from './css/projeditor.module.css';
import SceneList from './sceneList/SceneList';

const useStyles = makeStyles({
  sceneEditor: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: '#14142B'
  },
  timelineWrapper: {
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden'
  },
  sceneListWrapper: {
    display: 'flex'
  },
  stageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  stageWithAssetPicker: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '10px 0'
  },
  playAllWrapper: {
    marginLeft: '1%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  time: {
    color: 'white',
    marginTop: '3px'
  },
  itemsAllWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    overflowX: 'auto'
  },
  recordsWrapp: {
    width: '100%',
    padding: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  records: {
    width: '99%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  musicRecInner: {
    display: 'flex',
    marginLeft: '1%'
  },
  movieNameInput: {
    display: 'flex',
    marginLeft: '5px'
  },
  musicLogoWrapp: {
    width: '3%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  musicLineWrapp: {
    width: '91%',
    display: 'flex',
    alignItems: 'center'
  },
  musicLine: {
    width: '100%',
    height: '70%',
    paddingLeft: '30px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    border: '1px solid #4E4B66',
    borderRadius: '9px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden'
  },
  restIconsWrapp: {
    width: '5%'
  },
  pencilIcon: {
    cursor: 'pointer',
    padding: '2px',
    height: '30px',
    marginTop: '12px'
  }
});

function ProjectEditor() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  // const { remainingSeconds, setRemainingSeconds } = useContext(SidebarActionsContext);

  const { currentTime } = useContext(CurrentTimeContext);

  const [mainProjectID, setMainProjectID] = useState('');
  const [firstLoad, setFirstLoad] = useState(false);
  const [projectDuration, setProjectDuration] = useState(0);
  const [triggerAssetRangers, setTriggerAssetRanges] = useState(false);
  const [scenes, setScenes] = useState([]);
  const [interactObject, setInteractObject] = useState({});
  const [movieName, setMovieName] = useState('');
  const [openFullPreviewModal, setOpenFullPreviewModal] = useState(false);
  const [refreshStage, setRefreshStage] = useState(false);
  const [assetInserted, setAssetInserted] = useState(false);
  const [countUsersOnProject, setCountUsersOnProject] = useState(0);
  const [intervalCount, setIntervalCount] = useState(0);
  const [libraryUpdated, setLibraryUpdated] = useState(0); // used when scene or text are saved in library
  const [enoughSize, setEnoughSize] = useState(true);

  const [changeEditorClean, setChangeEditorClean] = useState(false);

  const popupId = 'popup-id-save';

  const [soundtrack, setSoundtrack] = useState({});

  useEffect(() => {
    if (window.outerWidth < 900) {
      setEnoughSize(false);
      alert('Screen is to small');
      const isMobile = /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent);
      if (isMobile) {
        history.push('/newmovies');
      } else {
        history.push('/promotions');
      }
    } else {
      setEnoughSize(true);
    }
  }, [window.outerWidth]);

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (!changeEditorClean) {
        return window.confirm('Leave Editor?');
      }
    });

    return () => {
      unblock();
    };
  }, [changeEditorClean]);

  useEffect(() => {
    let savedProjectID = localStorage.getItem('fayeproject');

    if (!!savedProjectID) {
      setMainProjectID(savedProjectID);
    } else {
      setChangeEditorClean(true);
      setTimeout(() => history.push('/projects'), 1200);
    }
  }, [location]);

  useEffect(() => {
    if (interactObject.type === 'draggable') {
      let scenesCopy = [...scenes];
      var asset = {
        ...scenesCopy.find((x) => x.id === interactObject.sceneid).assets.find((x) => x.assetStyleID === interactObject.id)
      };

      asset.x = interactObject.x;
      asset.y = interactObject.y;
      asset.offset = {};
      asset.offset.offsetX = interactObject.offsetx;
      asset.offset.offsetY = interactObject.offsety;
      let sceneIndex = scenesCopy.indexOf(scenesCopy.find((x) => x.id === interactObject.sceneid));
      let assetIndex = scenesCopy.find((x) => x.id === interactObject.sceneid).assets.indexOf(scenesCopy.find((x) => x.id === interactObject.sceneid).assets.find((x) => x.assetStyleID === interactObject.id));

      scenesCopy[sceneIndex].assets[assetIndex] = asset;
      setScenes(scenesCopy);
      setSelectedAsset(asset);
    } else if (interactObject.type === 'textchange') {
      let scenesCopy = [...scenes];
      var asset = {
        ...scenesCopy.find((x) => x.id === activeSceneID).assets.find((x) => x.assetStyleID === interactObject.id)
      };
      asset.asset.text = interactObject.text;
      let sceneIndex = scenesCopy.indexOf(scenesCopy.find((x) => x.id === activeSceneID));
      let assetIndex = scenesCopy.find((x) => x.id === activeSceneID).assets.indexOf(scenesCopy.find((x) => x.id === activeSceneID).assets.find((x) => x.assetStyleID === interactObject.id));

      scenesCopy[sceneIndex].assets[assetIndex] = asset;
      setScenes(scenesCopy);
    } else if (interactObject.type === 'assetstyle') {
      let scenesCopy = [...scenes];
      var asset = {
        ...scenesCopy.find((x) => x.id === activeSceneID).assets.find((x) => x.assetStyleID === interactObject.id)
      };

      if (!!interactObject.canvasStyle) {
        asset.canvasStyle = interactObject.canvasStyle;
      }
      asset.opacity = interactObject.opacity;

      let sceneIndex = scenesCopy.indexOf(scenesCopy.find((x) => x.id === activeSceneID));
      let assetIndex = scenesCopy.find((x) => x.id === activeSceneID).assets.indexOf(scenesCopy.find((x) => x.id === activeSceneID).assets.find((x) => x.assetStyleID === interactObject.id));

      scenesCopy[sceneIndex].assets[assetIndex] = asset;
      setScenes(scenesCopy);
    } else if (interactObject.type === 'htmlBackground') {
      let scenesCopy = [...scenes];
      var asset = scenesCopy.find((x) => x.id === interactObject.sceneid).assets.find((x) => x.asset.type === 'background');

      let sceneIndex = scenesCopy.indexOf(scenesCopy.find((x) => x.id === interactObject.sceneid));

      if (!!asset) {
        asset.htmlBackground = interactObject.htmlBackground;

        let assetIndex = scenesCopy.find((x) => x.id === interactObject.sceneid).assets.indexOf(scenesCopy.find((x) => x.id === interactObject.sceneid).assets.find((x) => x.asset.type === 'background'));
        scenesCopy[sceneIndex].assets[assetIndex] = asset;
      } else {
        let masterClip = scenesCopy.find((x) => x.id === interactObject.sceneid).assets.find((x) => x.isMaster == true);
        let insertedAsset = {};
        insertedAsset.asset = {};
        insertedAsset.assetID = uuid();
        insertedAsset.assetStyleID = uuid();
        insertedAsset.asset.name = 'background for ' + interactObject.sceneid;
        insertedAsset.asset.type = 'background';
        insertedAsset.asset.typeID = 7;
        insertedAsset.h = 0;
        insertedAsset.w = 0;
        insertedAsset.originalHeight = SCREEN_WIDTH;
        insertedAsset.originalWidth = SCREEN_HEIGHT;
        insertedAsset.originalLength = 5;
        insertedAsset.sceneID = interactObject.sceneid;
        insertedAsset.trim = 0;
        insertedAsset.volume = 1;
        insertedAsset.scale = 1;
        insertedAsset.z = 0;
        insertedAsset.deleted = false;
        insertedAsset.start = masterClip.start;
        insertedAsset.length = masterClip.length;
        insertedAsset.htmlBackground = interactObject.htmlBackground;

        scenesCopy[sceneIndex].assets.push(insertedAsset);
      }

      setScenes(scenesCopy);
    } else if (interactObject.type === 'assetvolume') {
      let scenesCopy = [...scenes];
      var asset = {
        ...scenesCopy.find((x) => x.id === activeSceneID).assets.find((x) => x.assetStyleID === interactObject.id)
      };

      asset.volume = interactObject.volume;

      let sceneIndex = scenesCopy.indexOf(scenesCopy.find((x) => x.id === activeSceneID));
      let assetIndex = scenesCopy.find((x) => x.id === activeSceneID).assets.indexOf(scenesCopy.find((x) => x.id === activeSceneID).assets.find((x) => x.assetStyleID === interactObject.id));

      scenesCopy[sceneIndex].assets[assetIndex] = asset;
      setScenes(scenesCopy);
    } else if (interactObject.type === 'soundtrackvolume') {
      let newSoundtrack = soundtrack;
      newSoundtrack.volume = interactObject.volume;
      const timeout = setTimeout(() => {
        const { req, cancelTokenSource } = movieService.postMovieSoundtrackVolume(mainProjectID, interactObject.volume);
      }, 1200);

      setSoundtrack(newSoundtrack);

      return () => {
        clearTimeout(timeout);
      };
    } else if (interactObject.type === 'textbase64img') {
      let scenesCopy = [...scenes];
      var asset = {
        ...scenesCopy.find((x) => x.id === interactObject.sceneid).assets.find((x) => x.assetStyleID === interactObject.id)
      };

      asset.htmlImageBase64 = interactObject.htmlImageBase64;

      let sceneIndex = scenesCopy.indexOf(scenesCopy.find((x) => x.id === interactObject.sceneid));
      let assetIndex = scenesCopy.find((x) => x.id === interactObject.sceneid).assets.indexOf(scenesCopy.find((x) => x.id === interactObject.sceneid).assets.find((x) => x.assetStyleID === interactObject.id));

      scenesCopy[sceneIndex].assets[assetIndex] = asset;
      setScenes(scenesCopy);
    } else if (interactObject.type === 'resizable') {
      let scenesCopy = [...scenes];
      var asset = {
        ...scenesCopy.find((x) => x.id === interactObject.sceneid).assets.find((x) => x.assetStyleID === interactObject.id)
      };

      asset.h = interactObject.h;
      asset.w = interactObject.w;
      asset.scale = interactObject.w / asset.originalWidth;

      asset.offset = {};
      asset.offset.offsetX = interactObject.offsetx;
      asset.offset.offsetY = interactObject.offsety;

      if (!!interactObject.css) {
        asset.css = interactObject.css;
      }

      if (interactObject.canvasScaleX || interactObject.canvasScaleY) {
        let canvasStyle = JSON.parse(asset.canvasStyle) || {};
        canvasStyle.scaleX = interactObject.canvasScaleX;
        canvasStyle.scaleY = interactObject.canvasScaleY;
        asset.canvasStyle = JSON.stringify(canvasStyle);
      }

      if (!!interactObject.text) {
        asset.asset.text = interactObject.text;
      }

      if (typeof interactObject.x === 'number') {
        asset.x = interactObject.x;
      }

      if (typeof interactObject.y === 'number') {
        asset.y = interactObject.y;
      }

      if (interactObject.cropRight > 0 || interactObject.cropBottom > 0 || interactObject.cropLeft > 0 || interactObject.cropTop > 0) {
        asset.crop = {};
        asset.crop.cropRight = interactObject.cropRight || 0;
        asset.crop.cropBottom = interactObject.cropBottom || 0;
        asset.crop.cropLeft = interactObject.cropLeft || 0;
        asset.crop.cropTop = interactObject.cropTop || 0;
      }

      let sceneIndex = scenesCopy.indexOf(scenesCopy.find((x) => x.id === interactObject.sceneid));
      let assetIndex = scenesCopy.find((x) => x.id === interactObject.sceneid).assets.indexOf(scenesCopy.find((x) => x.id === interactObject.sceneid).assets.find((x) => x.assetStyleID === interactObject.id));

      scenesCopy[sceneIndex].assets[assetIndex] = asset;
      setScenes(scenesCopy);
      setAssetInserted(true);
    } else if (interactObject.type === 'interact-crop') {
      let scenesCopy = [...scenes];
      var asset = {
        ...scenesCopy.find((x) => x.id === interactObject.sceneid).assets.find((x) => x.assetStyleID === interactObject.id)
      };
      asset.offset = {};
      asset.offset.offsetX = interactObject.offsetx;
      asset.offset.offsetY = interactObject.offsety;
      asset.x = interactObject.x;
      asset.y = interactObject.y;
      asset.crop = {};
      asset.crop.cropRight = interactObject.cropRight;
      asset.crop.cropBottom = interactObject.cropBottom;
      asset.crop.cropLeft = interactObject.cropLeft;
      asset.crop.cropTop = interactObject.cropTop;

      let sceneIndex = scenesCopy.indexOf(scenesCopy.find((x) => x.id === interactObject.sceneid));
      let assetIndex = scenesCopy.find((x) => x.id === interactObject.sceneid).assets.indexOf(scenesCopy.find((x) => x.id === interactObject.sceneid).assets.find((x) => x.assetStyleID === interactObject.id));

      scenesCopy[sceneIndex].assets[assetIndex] = asset;
      setScenes(scenesCopy);
    } else if (interactObject.type === 'videostart') {
      let scenesCopy = [...scenes];
      var asset = {
        ...scenesCopy.find((x) => x.id === interactObject.sceneid).assets.find((x) => x.assetStyleID === interactObject.id)
      };
      asset.start = interactObject.start;

      let sceneIndex = scenesCopy.indexOf(scenesCopy.find((x) => x.id === interactObject.sceneid));
      let assetIndex = scenesCopy.find((x) => x.id === interactObject.sceneid).assets.indexOf(scenesCopy.find((x) => x.id === interactObject.sceneid).assets.find((x) => x.assetStyleID === interactObject.id));

      scenesCopy[sceneIndex].assets[assetIndex] = asset;
      setScenes(scenesCopy);
    } else if (interactObject.type === 'assettrim') {
      let scenesCopy = [...scenes];
      var asset = {
        ...scenesCopy.find((x) => x.id === interactObject.sceneid).assets.find((x) => x.assetStyleID === interactObject.id)
      };

      let trimDifference = asset.asset.type !== 'image' ? interactObject.trim - asset.trim : asset.length - interactObject.length;
      asset.trim = interactObject.trim;
      asset.length = interactObject.length;
      asset.start = interactObject.start;

      let sceneIndex = scenesCopy.indexOf(scenesCopy.find((x) => x.id === interactObject.sceneid));
      let assetIndex = scenesCopy.find((x) => x.id === interactObject.sceneid).assets.indexOf(scenesCopy.find((x) => x.id === interactObject.sceneid).assets.find((x) => x.assetStyleID === interactObject.id));

      scenesCopy[sceneIndex].assets[assetIndex] = asset;

      if (asset.isMaster === true) {
        for (let i = sceneIndex; i < scenesCopy.length; i++) {
          let newMasterClipLength = scenesCopy[i].assets.find((x) => x.isMaster == true).length;
          let newMasterClipTrim = scenesCopy[i].assets.find((x) => x.isMaster == true).trim;
          let newMasterClipStart = scenesCopy[i].assets.find((x) => x.isMaster == true).start;

          if (i === sceneIndex) {
            scenesCopy[i].assets.map(function (x) {
              if (!x.deleted && !x.isMaster) {
                // if trim is greater than asset start in scene
                if (trimDifference > x.start - newMasterClipStart) {
                  // if trim is greater than asset length in scene
                  if (trimDifference < x.start - newMasterClipStart + x.length) {
                    let secondsDifference = trimDifference - x.start + newMasterClipStart;

                    if (x.asset.type === 'video' || x.asset.type === 'audio') {
                      x.trim = x.trim + secondsDifference;
                    }
                    x.length = x.length - secondsDifference;
                  } else {
                    if (x.asset.type === 'video' || x.asset.type === 'audio') {
                      x.trim = x.originalLength - 2;
                    }
                    x.length = 2;
                  }
                  x.start = newMasterClipStart;
                } else {
                  x.start = x.start - trimDifference;
                }
              }
              return x;
            });
          } else if (i > sceneIndex) {
            let masterClipStart = scenesCopy[i].assets.find((x) => x.isMaster == true).start;
            let prevMasterClipLength = scenesCopy[i - 1].assets.find((x) => x.isMaster == true).length;
            let prevMasterClipStart = scenesCopy[i - 1].assets.find((x) => x.isMaster == true).start;

            scenesCopy[i].assets.map(function (x) {
              x.start = prevMasterClipStart + prevMasterClipLength + (x.start - masterClipStart);
              return x;
            });
          }
        }
      }

      // setSelectedAsset(asset)
      setScenes(scenesCopy);
    } else if (interactObject.type === 'resetmasterlen') {
      let scenesCopy = [...scenes];
      let sceneIndex = scenesCopy.indexOf(scenesCopy.find((x) => x.id === interactObject.sceneid));
      let masterClip = scenesCopy[sceneIndex].assets.find((x) => x.isMaster === true);
      if (!!masterClip) {
        masterClip.trim = 0;
        masterClip.length = masterClip.originalLength || 5;

        for (let i = sceneIndex; i < scenesCopy.length; i++) {
          if (i > sceneIndex && scenesCopy[i].assets.length > 0) {
            let masterClipStart = scenesCopy[i].assets.find((x) => x.isMaster == true).start;
            let prevMasterClipLength = scenesCopy[i - 1].assets.find((x) => x.isMaster == true).length;
            let prevMasterClipStart = scenesCopy[i - 1].assets.find((x) => x.isMaster == true).start;
            scenesCopy[i].assets.map(function (x) {
              x.start = prevMasterClipStart + prevMasterClipLength + (x.start - masterClipStart);
              return x;
            });
          }
        }

        setScenes(scenesCopy);
      }
    } else if (interactObject.type === 'videolen') {
      let scenesCopy = [...scenes];
      var asset = {
        ...scenesCopy.find((x) => x.id === interactObject.sceneid).assets.find((x) => x.assetStyleID === interactObject.id)
      };
      let oldMasterClipLength = asset.length;
      let lengthDifference = asset.length - interactObject.length;
      asset.length = interactObject.length;

      let sceneIndex = scenesCopy.indexOf(scenesCopy.find((x) => x.id === interactObject.sceneid));
      let assetIndex = scenesCopy.find((x) => x.id === interactObject.sceneid).assets.indexOf(scenesCopy.find((x) => x.id === interactObject.sceneid).assets.find((x) => x.assetStyleID === interactObject.id));

      scenesCopy[sceneIndex].assets[assetIndex] = asset;

      if (asset.isMaster === true) {
        for (let i = sceneIndex; i < scenesCopy.length; i++) {
          if (scenesCopy[i].assets.length === 0) continue;

          let newMasterClipLength = scenesCopy[i].assets.find((x) => x.isMaster == true).length;
          let newMasterClipStart = scenesCopy[i].assets.find((x) => x.isMaster == true).start;

          if (i === sceneIndex) {
            scenesCopy[i].assets.map(function (x) {
              if (!x.deleted && !x.isMaster) {
                if (x.start - newMasterClipStart + x.length > oldMasterClipLength - lengthDifference) {
                  x.length = newMasterClipLength - (x.start - newMasterClipStart);
                }
                if (x.start - newMasterClipStart > oldMasterClipLength - lengthDifference) {
                  x.start = newMasterClipStart;
                  if (x.asset.type === 'video' || x.asset.type === 'audio') {
                    x.trim = x.originalLength - 2;
                  }
                  x.length = 2;
                }
              }
              return x;
            });
          } else if (i > sceneIndex) {
            let masterClipStart = scenesCopy[i].assets.find((x) => x.isMaster == true).start;
            let prevMasterClipLength = scenesCopy[i - 1].assets.find((x) => x.isMaster == true).length;
            let prevMasterClipStart = scenesCopy[i - 1].assets.find((x) => x.isMaster == true).start;

            scenesCopy[i].assets.map(function (x) {
              x.start = prevMasterClipStart + prevMasterClipLength + (x.start - masterClipStart);
              return x;
            });
          }
        }

        //  setSelectedAsset(asset)
      }

      setScenes(scenesCopy);
    } else if (interactObject.type === 'sceneinsert') {
      let scenesCopy = [...scenes];

      let sceneIndex = scenesCopy.indexOf(scenesCopy.find((x) => x.id === activeSceneID));

      if (sceneIndex > -1) {
        let sceneStart = 0;
        let sceneLength = 0;
        let originalSavedSceneStart = 0;
        let emptyScene = false;

        if (scenesCopy[sceneIndex].assets.length === 0) {
          emptyScene = true;
          sceneStart = projectDuration;
          sceneLength = !!interactObject.assets.find((x) => x.isMaster === true) ? interactObject.assets.find((x) => x.isMaster === true).length : Math.max(...interactObject.assets.map((o) => o.length), 0);
          originalSavedSceneStart = !!interactObject.assets.find((x) => x.isMaster === true) ? interactObject.assets.find((x) => x.isMaster === true).start : Math.max(...interactObject.assets.map((o) => o.start), 0);
        } else {
          sceneStart = scenesCopy[sceneIndex].assets.find((x) => x.isMaster == true).start;
          sceneLength = scenesCopy[sceneIndex].assets.find((x) => x.isMaster == true).length;
          originalSavedSceneStart = !!interactObject.assets.find((x) => x.isMaster === true) ? interactObject.assets.find((x) => x.isMaster === true).start : Math.max(...interactObject.assets.map((o) => o.start), 0);
        }

        for (let i = 0; i < interactObject.assets.length; i++) {
          interactObject.assets[i].start = interactObject.assets[i].start - originalSavedSceneStart + sceneStart;

          if (sceneLength - (interactObject.assets[i].start - sceneStart) < interactObject.assets[i].length) {
            interactObject.assets[i].length = sceneLength - (interactObject.assets[i].start - sceneStart);
          }

          interactObject.assets[i].assetStyleID = uuid();
          interactObject.assets[i].assetID = interactObject.assets[i].asset.type === 'html' ? uuid() : interactObject.assets[i].assetID;
          interactObject.assets[i].sceneID = activeSceneID;
          interactObject.assets[i].z = Math.max(...scenesCopy[sceneIndex].assets.map((x) => x.z), 0) + i + 1;

          if (interactObject.assets[i].isMaster) {
            if (!emptyScene) {
              interactObject.assets[i].isMaster = false;
            } else {
              interactObject.assets[i].z = 1;
            }
          }

          scenesCopy[sceneIndex].assets.push(interactObject.assets[i]);
        }

        setScenes(scenesCopy);
        setAssetInserted(true);
      }
    } else if (interactObject.type === 'assetinsert') {
      let scenesCopy = [...scenes];

      let sceneIndex = scenesCopy.indexOf(scenesCopy.find((x) => x.id === activeSceneID));

      let insertedAsset = {};
      insertedAsset.asset = {};
      insertedAsset.asset.name = interactObject.name;
      insertedAsset.asset.text = interactObject.text;
      insertedAsset.asset.src = interactObject.url;
      insertedAsset.asset.previewSrc = interactObject.previewUrl;
      insertedAsset.asset.type = interactObject.assetType;
      insertedAsset.asset.typeID = interactObject.assetTypeID;

      insertedAsset.assetID = interactObject.assetId;
      insertedAsset.assetStyleID = uuid();

      if (!!interactObject.canvasStyle) {
        insertedAsset.canvasStyle = interactObject.canvasStyle;
      }

      //resize to fit in screen if asset is to wide
      if (SCREEN_HEIGHT / interactObject.originalHeight < 1 || SCREEN_WIDTH / interactObject.originalWidth < 1) {
        // choose to resize by height or width depending which dimension is wider than screen
        let whatToResize = interactObject.originalWidth / SCREEN_WIDTH < interactObject.originalHeight / SCREEN_HEIGHT ? 'height' : 'width';
        if (whatToResize === 'height') {
          let resizeFactor = interactObject.originalHeight / SCREEN_HEIGHT; // resize to full height
          insertedAsset.h = interactObject.originalHeight / resizeFactor;
          insertedAsset.w = interactObject.originalWidth / resizeFactor;
          insertedAsset.scale = insertedAsset.w / interactObject.originalWidth;
        } else {
          let resizeFactor = interactObject.originalWidth / SCREEN_WIDTH; // resize to full width
          insertedAsset.w = interactObject.originalWidth / resizeFactor;
          insertedAsset.h = interactObject.originalHeight / resizeFactor;
          insertedAsset.scale = insertedAsset.w / interactObject.originalWidth;
        }
      } else {
        insertedAsset.h = interactObject.originalHeight;
        insertedAsset.w = interactObject.originalWidth;
        insertedAsset.scale = 1;
      }

      insertedAsset.originalHeight = interactObject.originalHeight;
      insertedAsset.originalWidth = interactObject.originalWidth;
      insertedAsset.originalLength = interactObject.originalLength;

      insertedAsset.sceneID = activeSceneID;
      insertedAsset.trim = 0;
      insertedAsset.volume = 1;
      insertedAsset.x = parseFloat((SCREEN_WIDTH / 2 - insertedAsset.w / 2).toFixed(2));
      insertedAsset.y = parseFloat((SCREEN_HEIGHT / 2 - insertedAsset.h / 2).toFixed(2));
      insertedAsset.crop = {
        cropLeft: 0,
        cropTop: 0,
        cropRight: 0,
        cropBottom: 0
      };
      insertedAsset.offset = {};

      const SCREEN_MID_X = SCREEN_WIDTH / 2;
      const SCREEN_MID_Y = SCREEN_HEIGHT / 2;
      let elementWidthMid = insertedAsset.w / 2;
      let elementHeightMid = insertedAsset.h / 2;

      let elementMidPointX = insertedAsset.x + elementWidthMid;
      let elementMidPointY = insertedAsset.y + elementHeightMid;

      let offsetX = Math.abs((insertedAsset.x + elementWidthMid - SCREEN_MID_X) / SCREEN_WIDTH);
      let offsetY = Math.abs((insertedAsset.y + elementHeightMid - SCREEN_MID_Y) / SCREEN_HEIGHT);
      insertedAsset.offset.offsetX = SCREEN_MID_X < elementMidPointX ? offsetX : offsetX * -1;
      insertedAsset.offset.offsetY = SCREEN_MID_Y > elementMidPointY ? offsetY : offsetY * -1;

      insertedAsset.z = -1;
      insertedAsset.deleted = false;

      if (typeof scenesCopy[sceneIndex].assets === 'undefined' || scenesCopy[sceneIndex].assets === null) {
        scenesCopy[sceneIndex].assets = [];
      }

      // if asset is inserted on empty scene, that asset will be master clip, it's start will be current project duration
      if (scenesCopy[sceneIndex].assets.length === 0) {
        insertedAsset.start = projectDuration;
        insertedAsset.length = interactObject.originalLength;
        insertedAsset.isMaster = true;
      } else {
        // insert asset on timeline needle position
        if (!interactObject.ismain) {
          insertedAsset.start = scenesCopy[sceneIndex].assets.find((x) => x.isMaster == true).start + currentTime; // scenesCopy[sceneIndex].assets.find(x => x.isMaster == true).start;
        } else {
          insertedAsset.start = scenesCopy[sceneIndex].assets.find((x) => x.isMaster == true).start;
        }

        if (scenesCopy[sceneIndex].assets.find((x) => x.isMaster == true).length - currentTime < interactObject.originalLength) {
          insertedAsset.length = scenesCopy[sceneIndex].assets.find((x) => x.isMaster == true).length - currentTime;
        } else {
          insertedAsset.length = interactObject.originalLength;
        }
      }

      let mainClipReplaced = false;
      let oldMasterClipLength = 0;
      let lengthDifference = 0;
      if (interactObject.ismain === true && scenesCopy[sceneIndex].assets.length > 0) {
        let masterClipIndex = scenesCopy[sceneIndex].assets.indexOf(scenesCopy[sceneIndex].assets.find((x) => x.isMaster == true));

        if (masterClipIndex > -1) {
          mainClipReplaced = true;
          insertedAsset.isMaster = true;
          insertedAsset.z = scenesCopy[sceneIndex].assets[masterClipIndex].z;

          oldMasterClipLength = scenesCopy[sceneIndex].assets[masterClipIndex].length;
          lengthDifference = scenesCopy[sceneIndex].assets[masterClipIndex].length - interactObject.originalLength;

          insertedAsset.length = interactObject.originalLength;
          scenesCopy[sceneIndex].assets[masterClipIndex].isMaster = false;
          scenesCopy[sceneIndex].assets[masterClipIndex].deleted = true;
          scenesCopy[sceneIndex].assets.push(insertedAsset);
        }
      } else {
        scenesCopy[sceneIndex].assets.push(insertedAsset);
      }

      for (let i = 1; i <= scenesCopy[sceneIndex].assets.length; i++) {
        let index = scenesCopy[sceneIndex].assets.length - 1;
        // set master clip behind all assets on screen
        if (scenesCopy[sceneIndex].assets[index - i + 1].isMaster) {
          scenesCopy[sceneIndex].assets[index - i + 1].z = 1;
        } else {
          if (scenesCopy[sceneIndex].assets[index - i + 1].z === -1) {
            scenesCopy[sceneIndex].assets[index - i + 1].z = Math.max(...scenesCopy[sceneIndex].assets.map((x) => x.z)) + 1;
          }
        }
      }

      if (mainClipReplaced === true) {
        let newMasterClipLength = scenesCopy[sceneIndex].assets.find((x) => x.isMaster == true).length;
        let newMasterClipStart = scenesCopy[sceneIndex].assets.find((x) => x.isMaster == true).start;

        for (let i = sceneIndex; i < scenesCopy.length; i++) {
          if (i === sceneIndex) {
            scenesCopy[i].assets.map(function (x) {
              if (!x.deleted && !x.isMaster) {
                if (x.start - newMasterClipStart + x.length > oldMasterClipLength - lengthDifference) {
                  x.length = newMasterClipLength - (x.start - newMasterClipStart);
                }
                if (x.start - newMasterClipStart > oldMasterClipLength - lengthDifference) {
                  x.start = newMasterClipStart;
                  if (x.asset.type === 'video' || x.asset.type === 'audio') {
                    x.trim = x.originalLength - 2;
                  }
                  x.length = 2;
                }
              }
              return x;
            });
          } else if (i > sceneIndex) {
            if (scenesCopy[i].assets.length > 0) {
              let masterClipStart = scenesCopy[i].assets.find((x) => x.isMaster == true).start;
              let prevMasterClipLength = scenesCopy[i - 1].assets.find((x) => x.isMaster == true).length;
              let prevMasterClipStart = scenesCopy[i - 1].assets.find((x) => x.isMaster == true).start;

              scenesCopy[i].assets.map(function (x) {
                x.start = prevMasterClipStart + prevMasterClipLength + (x.start - masterClipStart);
                return x;
              });
            }
          }
        }
      }

      setScenes(scenesCopy);
      setAssetInserted(true);
    } else if (interactObject.type === 'removeasset') {
      let scenesCopy = [...scenes];

      let sceneIndex = scenesCopy.indexOf(scenesCopy.find((x) => x.id === activeSceneID));

      var asset = {
        ...scenesCopy.find((x) => x.id === activeSceneID).assets.find((x) => x.assetStyleID === interactObject.assetStyleID)
      };

      asset.deleted = true;

      let assetIndex = scenesCopy.find((x) => x.id === activeSceneID).assets.indexOf(scenesCopy.find((x) => x.id === activeSceneID).assets.find((x) => x.assetStyleID === interactObject.assetStyleID));

      scenesCopy[sceneIndex].assets[assetIndex] = asset;
      setScenes(scenesCopy);
    }
    setTriggerAssetRanges(!triggerAssetRangers);
  }, [interactObject]);

  const [playerInterval, setPlayerInterval] = useState(null);

  // const getRemainingSeconds = () => {
  //   const { req, cancelTokenSource } = sceneService.getRemainingSeconds();
  //   req
  //     .then((resp) => {
  //       if (resp.data.success === true) {
  //         setRemainingSeconds(resp.data.data);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  //   return () => {
  //     cancelTokenSource.cancel();
  //   };
  // };

  useEffect(() => {
    if (!!mainProjectID) {
      const { req, cancelTokenSource } = sceneService.getProjectScenes(mainProjectID, true);
      req
        .then((resp) => {
          if (resp.data.success === true) {
            if (resp.data.project === null) {
              // if someone logs in with two users who has different companies
              setChangeEditorClean(true);
              setTimeout(() => history.push('/projects'), 1200);
            } else {
              setMovieName(resp.data.project.name);
              if (!!resp.data.project.soundtrack) {
                setSoundtrack(resp.data.project.soundtrack);
              } else {
                setSoundtrack({});
              }
              setScenes(resp.data.scenes);
              setFirstLoad(true);
            }
          } else {
            toast.warn(resp.data.message, {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
            setChangeEditorClean(true);
            setTimeout(() => history.push('/projects'), 1200);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      // getRemainingSeconds();

      projectApi.subscribeToEditorHub(localStorage.username, mainProjectID, setCountUsersOnProject);

      return async () => {
        projectApi.unsubscribeToEditorHub();
      };
    }
  }, [mainProjectID, refreshStage]);

  useEffect(() => {
    let intervalid = setInterval(function () {
      setIntervalCount((prev) => prev + 1);
    }, 60000);
    if (firstLoad === true && scenes.length > 0) {
      for (let i = scenes.length - 1; i >= 0; i--) {
        setTimeout(() => {
          setActiveSceneID(scenes[i].id);
        }, 1000);
      }
      setFirstLoad(false);
    }

    return () => {
      clearInterval(intervalid);
    };
  }, [firstLoad]);

  useEffect(() => {
    if (assetInserted === true) {
      setActiveScene(activeSceneID);
      setAssetInserted(false);
    }
  }, [assetInserted]);

  const [activeSceneContent, setActiveSceneContent] = useState([]);
  const [activeSceneID, setActiveSceneID] = useState('');

  useEffect(() => {
    if (!!activeSceneID) {
      setActiveScene(activeSceneID);
      setCurrentAttribute('');
    }
  }, [activeSceneID]);

  useEffect(() => {
    if (!toast.isActive(popupId.current)) {
      if (intervalCount > 0) {
        saveProjectScenes();
      }
    }
  }, [intervalCount]);

  const setActiveScene = (sceneId) => {
    if (!sceneId) return;
    let activeAssets = scenes.find((s) => s.id === sceneId).assets || [];
    setActiveSceneContent(
      activeAssets.sort((a, b) => {
        return b.z - a.z;
      })
    );
  };

  const setSceneAssetsReorder = (sceneID, assets) => {
    let scenesCopy = [...scenes];
    scenesCopy.find((x) => x.id === sceneID).assets = [...assets, ...scenesCopy.find((x) => x.id === sceneID).assets.filter((x) => x.deleted || x.asset.typeID === 7)];
    setScenes(scenesCopy);
    setActiveScene(sceneID);
  };

  const saveProjectScenes = (saveMissingTextImgs = false, callback = () => {}) => {
    let scenesToSave = scenes;
    if (saveMissingTextImgs) {
      const hasMissingTxtImg = hasMissingTextImage(scenes);
      if (hasMissingTxtImg) {
        scenesToSave = updateMissingTextImage(scenes);
      }
    }

    const { axiosReq, cancelTokenSource } = sceneService.saveProjectScenes(scenesToSave, mainProjectID);

    axiosReq
      .then((resp) => {
        if (resp.data.success === true) {
          callback();
          toast.success(resp.data.message, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            toastId: popupId
          });
        } else {
          toast.error('Error ' + resp.data.message, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        }
      })
      .catch((err) => {
        toast.error('Error ' + err.message, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      });
  };

  const handleRenderingScenes = (name, description, privacyID) => {
    const { axiosReq, cancelTokenSource } = projectApi.addProjectToRender(mainProjectID, Math.floor(projectDuration), name, description, privacyID);
    axiosReq
      .then((r) => {
        // getRemainingSeconds();
        setRefreshStage((x) => !x);
        if (r.data.success === true) {
          toast.success(r.data.message, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        } else {
          toast.error('Error ' + r.data.message, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        }
      })
      .catch((err) => {
        toast.error('Error ' + err.message, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      });
  };

  const addToRender = (name, description, privacyID) => {
    if (!name) {
      toast.warn('Name cannot be empty.', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }

    // if (remainingSeconds === 0 || remainingSeconds < projectDuration) {
    //   alert('You dont have enough seconds left for rendering. Please buy/subscribe to additional package.');
    //   return;
    // }
    saveProjectScenes(true, () => {
      handleRenderingScenes(name, description, privacyID);
    });
  };

  const [selectedAsset, setSelectedAsset] = useState({});
  const [currentAttribute, setCurrentAttribute] = useState('');
  const [openAdvancedTextOptions, setOpenAdvancedTextOptions] = useState(false);

  const [{ canDrop7, isOver7 }, drop7] = useDrop(
    () => ({
      accept: ItemTypes.CARD,
      drop: (item, monitor) => {
        const positionedAsset = { ...monitor.getClientOffset(), ...item };
        if (!!positionedAsset.data && positionedAsset.data.assetType === 'audio') {
          const { req, cancelTokenSource } = movieService.postMovieSoundtrack(mainProjectID, positionedAsset.data.id);
          req.then((x) => {
            if (x.data.success) {
              setSoundtrack(positionedAsset.data);
            }
          });
        }
        return positionedAsset;
      },
      collect: (monitor) => {
        return { mockedKey: 'mockedVal', monitor };
      }
    }),
    [mainProjectID]
  );

  return (
    <>
      {!enoughSize ? (
        <p style={{ width: '100%', color: 'white', textAlign: 'center' }}>Screen is too small</p>
      ) : (
        <PlayerIntervalContext.Provider value={{ playerInterval, setPlayerInterval }}>
          <Grid container className={classes.sceneEditor}>
            <Grid className={classes.stageWrapper}>
              <DndProvider backend={HTML5Backend}>
                <Grid id={projedit.stageWithAssetPickerResp} className={`${classes.stageWithAssetPicker} ${projedit.stageWithAssetPickerResp}`}>
                  <Stage
                    // remainingSeconds={remainingSeconds}
                    scenes={scenes}
                    projectDuration={projectDuration}
                    setProjectDuration={setProjectDuration}
                    activeSceneContent={activeSceneContent}
                    setSceneAssetsReorder={setSceneAssetsReorder}
                    setActiveSceneID={setActiveSceneID}
                    activeSceneID={activeSceneID}
                    setInteractObject={setInteractObject}
                    triggerAssetRangers={triggerAssetRangers}
                    selectedAsset={selectedAsset}
                    setSelectedAsset={setSelectedAsset}
                    currentAttribute={currentAttribute}
                    setCurrentAttribute={setCurrentAttribute}
                    openFullPreviewModal={openFullPreviewModal}
                    setOpenFullPreviewModal={setOpenFullPreviewModal}
                    openAdvancedTextOptions={openAdvancedTextOptions}
                    setOpenAdvancedTextOptions={setOpenAdvancedTextOptions}
                    libraryUpdated={libraryUpdated}
                    mainProjectID={mainProjectID}
                    movieName={movieName}
                    setMovieName={setMovieName}
                    saveProjectScenes={saveProjectScenes}
                    addToRender={addToRender}
                    countUsersOnProject={countUsersOnProject}
                    setLibraryUpdated={setLibraryUpdated}
                  />
                </Grid>
              </DndProvider>

              <Grid className={`${classes.timelineWrapper} ${projedit.timelineWrapperResp}`}>
                <Grid item xs={12} className={classes.sceneListWrapper}>
                  <Grid className={classes.playAllWrapper}>
                    <Tooltip title="Play movie">
                      <PLAY_BIG
                        style={{
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          setOpenFullPreviewModal(true);
                        }}
                      />
                    </Tooltip>
                  </Grid>

                  <Grid className={classes.itemsAllWrapper}>
                    {scenes.length > 0 && (
                      <SceneList
                        key={activeSceneID}
                        scenes={scenes}
                        setActiveSceneID={setActiveSceneID}
                        activeSceneID={activeSceneID}
                        setScenes={setScenes}
                        setInteractObject={setInteractObject}
                        projectID={mainProjectID}
                        setLibraryUpdated={setLibraryUpdated}
                        saveProjectScenes={saveProjectScenes}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid className={classes.musicRecInner}>
                <Grid className={classes.musicLineWrapp} ref={drop7}>
                  <Grid className={`${classes.musicLine}`}>
                    <div style={{ width: '100%', background: 'transparent', position: 'relative' }}>
                      <SoundtrackStage key={mainProjectID} soundtrack={soundtrack} activeSceneID={activeSceneID} scenes={scenes} openFullPreviewModal={openFullPreviewModal} setInteractObject={setInteractObject} />
                    </div>
                  </Grid>
                  <DELETE_SOUNDTRACK
                    width={35}
                    height={35}
                    style={{ marginLeft: '10px', cursor: 'pointer' }}
                    onClick={() => {
                      const { req, cancelTokenSource } = movieService.deleteMovieSoundtrackVolume(mainProjectID);
                      req.then((x) => {
                        if (x.data.success) {
                          setSoundtrack({});
                        }
                      });
                    }}
                  />
                </Grid>
              </Grid>
              {/* <Grid style={{ color: 'white', marginLeft: '1%' }}>Remaining seconds: {remainingSeconds}</Grid> */}
            </Grid>
          </Grid>
        </PlayerIntervalContext.Provider>
      )}
    </>
  );
}

export default ProjectEditor;
