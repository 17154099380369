import clientLogo from './clientLogo.png';
import productLogo from './producttLogo.png';
import employeetLogo from './employeeLogo.png';
import partnerLogo from './partnerLogo.png';

const ClientBtn = ({ type }) => {
  const colorOfTheText = () => {
    if (type === 'Client') return '#CBFFAE';
    else if (type === 'Employee') return '#FFC3C0';
    else if (type === 'Product') return '#FFE6B0';
    else return '#8DE9FF';
  };

  const renderText = () => {
    if (type === 'Client') return 'Client';
    else if (type === 'Employee') return 'Employee';
    else if (type === 'Product') return 'Product';
    else return 'Partner';
  };
  const buttonClient = {
    width: '80%',
    height: '34px',
    borderRadius: '8px',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${colorOfTheText()}`,
    fontSize: '13px'
  };

  const imageStyle = { marginRight: '10px' };
  const textStyle = { marginRight: '15px', color: colorOfTheText() };

  const renderLogo = () => {
    if (type === 'Client') return clientLogo;
    else if (type === 'Employee') return employeetLogo;
    else if (type === 'Product') return productLogo;
    else return partnerLogo;
  };
  return (
    <button style={buttonClient} type="button">
      <img style={imageStyle} src={renderLogo()} alt={type} />
      <span style={textStyle}> {renderText()} </span>
    </button>
  );
};

export default ClientBtn;
ClientBtn.defaultProps = {
  type: 'client'
};
