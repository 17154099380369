import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popper from '@mui/material/Popper';
import { useEffect, useState } from 'react';
import { AiOutlineMore } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import noThumb from '../../files/NoThumbnail.jpg';
import projectApi from '../../services/apiServices/projects/projectApi';
import useStyleCardAsset from '../library/assetSection/cardasset/useStyleCardAsset';
import DeleteModal from '../modals/deleteModal/DeleteModal';
import image_loader from './media_movie/img_loader.gif';
import logoTestFinishedFalse from './media_movie/megaphone.png';
import logoTestFinishedTrue from './media_movie/speakerphoneTrue.png';
import ProjectSettingsDialogue from './ProjectSettingsDialogue';

const ProjectThumbnail = ({ data, projects, setProjects, selectedMoreId, setSelectedMoreId, isMobile }) => {
  const { styleMovieThumbnail } = useStyleCardAsset();
  const cssStyle = styleMovieThumbnail();

  const history = useHistory();
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [thumbnailType, setThumbnailType] = useState(2);
  const [optionsAnchorEl, setOptionsAnchorEl] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [openProjSettingsDialog, setOpenProjSettingsDialog] = useState(false);
  const [isNewProject, setIsNewProject] = useState(false);

  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const selectProject = (id) => {
    const { req } = projectApi.checkIsProjectEditable(id);
    req.then((resp) => {
      if (resp.data.success && resp.data.isEditable) {
        localStorage.removeItem('fayeproject');
        localStorage.setItem('fayeproject', id);
        history.push('/editor');
      } else {
        toast.info('You have to be Owner or Contributor to edit this project', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    });
  };

  const IsProcessingToastr = () => {
    toast.info('Wait render to finish.', {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
  };

  const deleteProject = (id) => {
    const { req } = projectApi.deleteProject(id);
    req.then((resp) => {
      const projectsFilter = projects.filter((element) => (element.id === id ? false : true)).map((element) => element);
      if (projectsFilter != null) {
        setProjects(projectsFilter);
      } else {
        setProjects([]);
      }
    });
  };

  const duplicateProject = (name, description, privacyID, contributorsList) => {
    setOpenProjSettingsDialog(false);

    let obj = {
      Name: name,
      Description: description,
      ID: data.id,
      PrivacyID: privacyID,
      AddedContributors: contributorsList
    };

    const { req } = projectApi.duplicateProject(obj);
    req.then((resp) => {
      setIsNewProject(false);
      if (resp.data.success) {
        toast.success('Project duplicated!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        setProjects([resp.data.data, ...projects]);
      } else {
        toast.error('Error ' + resp.data.message, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    });
  };

  useEffect(() => {
    setThumbnailUrl(data.firstSceneMasterClipUrl);
    setThumbnailType(data.firstSceneMasterClipTypeID === 1 ? 1 : 2);
  }, []);

  const closeMovieOptions = (event) => {
    event.stopPropagation();
    setOptionsAnchorEl(null);
    setIsNewProject(false);
    setSelectedMoreId('');
  };

  return (
    <div className={cssStyle.newMovieWrapp}>
      <div className={`${cssStyle.newMovie} ${cssStyle.thubmnailSize}`}>
        {(thumbnailType === 2 || !thumbnailUrl) && (
          <img
            className={`${cssStyle.logoMovie} ${cssStyle.thubmnailSize}`}
            src={!!thumbnailUrl ? thumbnailUrl : noThumb}
            alt="Logo"
            onClick={() => {
              selectProject(data.id);
            }}
            style={{
              wdith: '100%',
              height: '100%',
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          />
        )}
        {thumbnailType === 1 && (
          <video
            className={`${cssStyle.logoMovie} ${cssStyle.thubmnailSize}`}
            onClick={() => {
              selectProject(data.id);
            }}
            style={{
              wdith: '100%',
              height: '100%',
              backgroundImage: `url(${image_loader})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            <source src={thumbnailUrl} />
          </video>
        )}

        {data?.isTestimonialFinished === true && <img className={cssStyle.isTestimonialFinishedFalse} src={data?.isTestimonialFinished ? logoTestFinishedTrue : logoTestFinishedFalse} alt="megaphone" />}

        <div className={cssStyle.movieOptions}>
          <AiOutlineMore
            style={{ cursor: 'pointer', stroke: '#A996FF', width: '40px', strokeWidth: '100', filter: 'drop-shadow(3px 3px 3px rgb(0 0 0 / 1))' }}
            color="#A996FF"
            size={30}
            onClick={(event) => {
              event.stopPropagation();

              if (data.isProcessing === true) {
                IsProcessingToastr();
                return;
              }
              setOptionsAnchorEl(event.currentTarget);
              setSelectedMoreId(data?.id);
            }}
          />
        </div>

        <ClickAwayListener onClickAway={(event) => closeMovieOptions(event)}>
          <Popper open={data?.id === selectedMoreId} anchorEl={optionsAnchorEl} placement={'bottom'} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps}>
                <Box className="movie-options-cloud" sx={{ bgcolor: '#4E4B66', color: 'white', borderRadius: '20px' }}>
                  <MenuList>
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenProjSettingsDialog(true);
                        closeMovieOptions(e);
                      }}
                    >
                      Settings
                    </MenuItem>

                    {!isMobile && (
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsNewProject(true);
                          setOpenProjSettingsDialog(true);
                          setSelectedMoreId('');
                        }}
                      >
                        Duplicate
                      </MenuItem>
                    )}

                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteOpen();
                        setSelectedMoreId('');
                      }}
                    >
                      Delete
                    </MenuItem>
                  </MenuList>
                </Box>
              </Fade>
            )}
          </Popper>
        </ClickAwayListener>
        <div className={isMobile ? cssStyle.infoDivMobile : cssStyle.infoDiv}>
          <div className={cssStyle.movieName}>{data.name}</div>
          <div className={cssStyle.date}>{new Date(data.createdDate).toLocaleDateString('de') + (data?.isProcessing === true ? ' Rendering... ' : '')}</div>
        </div>
      </div>

      {openProjSettingsDialog && (
        <ProjectSettingsDialogue
          isMobile={isMobile}
          key={isNewProject ? {} : data}
          data={isNewProject ? {} : data}
          setOpenProjSettingsDialog={setOpenProjSettingsDialog}
          openProjSettingsDialog={openProjSettingsDialog}
          projects={isNewProject ? [] : projects}
          setProjects={isNewProject ? () => {} : setProjects}
          isNewProject={isNewProject}
          handleSaveNewProject={duplicateProject}
        />
      )}

      {openDelete && <DeleteModal setOpenDeleteModal={setOpenDelete} deleteFuncion={deleteProject} type="project" text="Are you sure you want permanently delete" projectData={data} />}
    </div>
  );
};

export default ProjectThumbnail;
