import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles({
  pageWrapp: {
    marginLeft: '20px',
    color: 'white',
    '& span': {
      borderRight: '1px solid white',
      paddingRight: '6px',
      fontWeight: 'bold'
    }
  },
  activePage: {
    paddingLeft: '5px',
    cursor: 'pointer',
    fontSize: '1.1rem'
  },
  inactivePage: {
    paddingLeft: '5px',
    cursor: 'pointer'
  }
});

const Paginate = ({ totalPages, setSkip }) => {
  const classes = useStyles();
  const [pages, setPages] = useState([]);
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    let p = [];
    for (let i = 1; i <= totalPages; i++) {
      p.push(parseInt(i));
    }
    setPages(p);
    setActivePage(1);
  }, [totalPages]);

  return (
    <div className={classes.pageWrapp}>
      {pages.map((item) => {
        return (
          <span
            key={item}
            className={item === activePage ? classes.activePage : classes.inactivePage}
            onClick={() => {
              setSkip((item - 1) * 8);
              setActivePage(item);
            }}
          >
            {item}
          </span>
        );
      })}
    </div>
  );
};

export default Paginate;
