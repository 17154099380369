import { makeStyles } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { generateVideoThumbnails } from '@rajesh896/video-thumbnails-generator';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PrivacySelect from './custom/movieSettingsDialogue/PrivacySelect';
import ThumbnailPart from './custom/movieSettingsDialogue/ThumbnailPart';
import useProject from './projsett/useProject';

const styleShareDialog = makeStyles({
  shareMovieDialogText: {
    fontFamily: 'Poppins',
    fontSize: '13px',
    opacity: '50%',
    marginTop: '20px',
    marginBottom: '0px'
  },
  shareMovieTextField: {
    backgroundColor: '#262338',
    borderRadius: '10px',
    width: '100%',
    height: '43px',
    border: 'none',
    paddingLeft: '20px',
    color: 'white',
    fontFamily: 'Poppins',
    '&:focus-visible': {
      outline: 'none',
      border: '1px solid #4E4B66'
    }
  },
  shareMovieTextarea: {
    backgroundColor: '#262338',
    borderRadius: '10px',
    width: '100%',
    height: '93px',
    border: 'none',
    color: 'white',
    padding: '7px 20px',
    fontFamily: 'Poppins',
    '&:focus-visible': {
      outline: 'none',
      border: '1px solid #4E4B66'
    }
  },
  selectWrapp: {
    width: '100%',
    padding: '10px 20px',
    borderRadius: '10px',
    outline: 'none',
    backgroundColor: '#262338',
    color: 'white',
    fontFamily: 'Poppins',
    borderColor: '#4E4B66'
  },
  checkBox: {
    position: 'relative',
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    backgroundColor: 'grey',
    '&:hover': {
      opacity: '1'
    }
  },
  contributorsWrappInner: { width: '100%', display: 'flex' },
  imgWrapp: { width: '48px', height: '48px', position: 'relative' },
  image: { width: '100%', height: '100%', borderRadius: '50%' },
  delImg: { fontSize: '10px', fontWeight: 'bold', borderRadius: '50%', padding: '5px', backgroundColor: 'red', position: 'absolute', right: '-5px', top: '-5px' },
  ulistWrapp: { width: '100%', backgroundColor: '#262338', marginTop: '10px', padding: '10px', borderRadius: '10px' },
  ulistHeader: { width: '100%', display: 'flex', justifyContent: 'space-between' },
  searchWrapp: { width: '100%' },
  searchWrappInner: { width: '100%', position: 'relative', margin: '10px 0' },
  searchInp: { color: 'white', backgroundColor: '#262338', border: '1px solid white', width: '100%', height: '40px', borderRadius: '10px', outline: 'none', paddingLeft: '30px' },
  iconSearch: { position: 'absolute', left: '5px', top: '7px', color: 'white' },
  saveBtnWrapp: { width: '100%', marginTop: '30px', padding: '5px', display: 'flex', justifyContent: 'flex-end' },
  saveBtn: {
    width: '30%',
    backgroundColor: '#A996FF',
    padding: '12px 20px',
    borderRadius: '10px',
    color: '#5312B5',
    fontWeight: 700,
    fontSize: '20px',
    '&:disabled': {
      opacity: 0.5,
      '& span': {
        opacity: 0.5,
        color: '#4700AB'
      }
    },
    '&:hover:not(:disabled)': {
      backgroundColor: ' #BFBEFC',
      color: '#5312B5'
    },
    '&:active': { boxShadow: '0 0 0 0 white', borderRadius: '4em', opacity: '1', transition: '0s' }
  }
});

const MoviesSettingsDialogue = ({ isMobile, data, setOpenMovieSettingsDialog, openMovieSettingsDialog, movies, setMovies, thumbnailUrl, setThumbnailUrl, isSettings, addToRender }) => {
  const classes = styleShareDialog();
  const [fayeUserSpec, setFayeUserSpec] = useState([]);
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [privacyID, setPrivacyID] = useState(1);
  const [contributorsList, setContributorsList] = useState([]);
  const [acceptToggle, setAcceptToggle] = useState(false);
  const [thumbnails, setThumbnails] = useState([]);
  const [searchString, setSearchString] = useState('');

  const FabButton = styled(Fab)(({ theme }) => ({
    width: isMobile ? '135px' : '144px',
    textTransform: 'none',
    marginTop: '20px',
    borderRadius: '16px',
    height: isMobile ? '53px' : '61px',
    backgroundColor: '#A996FF',
    color: '#4700AB',
    fontWeight: 'bold',
    borderColor: '#A996FF',
    outline: 'none',
    fontSize: '17px',
    '&:hover': {
      backgroundColor: '#c9befa',
      color: '#4700AB'
    }
  }));

  const { getContributors, getSearchFayeUsers, getFayeUsersByCompId, getSpecUserByCompanyId, saveMovieSettings } = useProject({
    setFayeUserSpec,
    searchString,
    projectName,
    projectDescription,
    contributorsList,
    setContributorsList,
    privacyID
  });

  useEffect(() => {
    if (searchString.trim() !== '') {
      getSearchFayeUsers();
    } else {
      getFayeUsersByCompId();
    }
  }, [searchString]);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setProjectName(data.name);
      setPrivacyID(data.privacyID);
      setProjectDescription(data.description);

      generateVideoThumbnails(data.storageUrl + data.moviePath, 4, "url")
      .then((res) => {
        setThumbnails(res);
      })
    }
  }, [data]);

  const handleSaveMovieSettings = (id) => {
    if (!data.isDataEditable) {
      toast.warn('Data can be edited only if it is created by users from same company.', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }

    saveMovieSettings(id)
      .then((res) => {
        if (res.data.success && !!res.data.data) {
          setMovies(movies.map((item) => (item.id === id ? { ...item, name: res.data.data.name, description: res.data.data.description, privacyID: res.data.data.privacyID } : item)));
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  return (
    <Dialog
      PaperProps={{ style: { zIndex: '12', backgroundColor: '#14142B', width: '90%', border: '1.5 solid #4E4B66', borderRadius: '15px', color: 'white', padding: isMobile ? '2px' : '20px', border: '2px solid #4E4B66' } }}
      open={openMovieSettingsDialog}
      onClose={(e) => {
        e.stopPropagation();
        setOpenMovieSettingsDialog(false);
      }}
    >
      <DialogTitle
        style={{ fontSize: isMobile ? '20px' : '22px', fontFamily: 'Poppins', fontWeight: 600 }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {isSettings ? 'Movie Settings' : 'Export Settings'}
        <IconButton
          style={{ color: 'white', position: 'absolute', right: '15px' }}
          onClick={(e) => {
            setOpenMovieSettingsDialog(false);
            e.stopPropagation();
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{ padding: isMobile && '15px', marginTop: isMobile && '-30px' }}
        className="dialog-content"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <p className={classes.shareMovieDialogText}>Title:</p>
        <input maxLength="250" onClick={(e) => e.stopPropagation()} onChange={(e) => setProjectName(e.target.value)} type="text" value={projectName} className={classes.shareMovieTextField} />

        <p className={classes.shareMovieDialogText}>Description:</p>
        <textarea
          style={{ height: isMobile && '81px' }}
          maxLength="2000"
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => setProjectDescription(e.target.value)}
          type="text"
          value={projectDescription}
          className={classes.shareMovieTextarea}
        >
          {projectDescription}
        </textarea>

        <PrivacySelect classes={classes} isMobile={isMobile} setAcceptToggle={setAcceptToggle} isSettings={isSettings} data={data} privacyID={privacyID} setPrivacyID={setPrivacyID} />

        {isSettings && <ThumbnailPart classes={classes} data={data} thumbnails={thumbnails} thumbnailUrl={thumbnailUrl} setThumbnailUrl={setThumbnailUrl} />}

        {/* {privacyID == 3 && (
          <>
            <div>
              <p className={classes.shareMovieDialogText}>
                By setting this project as public you understand and agree that this project and it’s content will be public for others using Faye. You confirm that you hold all necessary rights to all material provided
                and that you have the authority to share the material yourself.
              </p>
              <div
                onClick={() => {
                  setAcceptToggle((prev) => !prev);
                }}
                className={classes.checkBox}
                style={{ backgroundColor: acceptToggle ? '#A996FF' : 'grey' }}
              >
                {acceptToggle && <CheckIcon style={{ color: '#5E5594' }} />}
                <div style={{ fontFamily: 'Poppins', marginLeft: '35px', position: 'absolute', top: 0 }}>Accept</div>
              </div>
            </div>
          </>
        )} */}

        <div className={classes.saveBtnWrapp}>
          <FabButton
            onClick={(e) => {
              e.stopPropagation();

              if (isSettings) {
                handleSaveMovieSettings(data.id);
              } else {
                addToRender(projectName, projectDescription, privacyID);
              }
              setOpenMovieSettingsDialog(false);
              setAcceptToggle(false);
            }}
          >
            <span>{isSettings ? 'Save' : 'Export'}</span>
          </FabButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default MoviesSettingsDialogue;
