import AddStyle from './Add.module.css';
import Flogo from './film.png'
import ProjectSettingsDialogue from '../../ProjectSettingsDialogue';
import { useState } from 'react';
import { toast } from 'react-toastify';
import projectApi from '../../../../services/apiServices/projects/projectApi';
import { useHistory } from 'react-router-dom';


const AddProjectSection = () => {
    const [openNewProject, setOpenNewProject] = useState(false);
    const history = useHistory();

    const createNewProjectFunc = (projectName, projectDescription, privacyID, contributorsList) => {
        if (!projectName) {
          toast.error('Enter project name', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          return;
        }
    
        let obj = {
          Name: projectName,
          Description: projectDescription,
          PrivacyID: privacyID,
          AddedContributors: contributorsList
        };
    
        const { axiosReq, cancelTokenSource } = projectApi.createNewProject(obj);
        axiosReq.then((resp) => {
          if (resp.data.success === true) {
            localStorage.removeItem('fayeproject');
            localStorage.setItem('fayeproject', resp.data.data);
            history.push('/editor');
          } else {
            toast.error('Error ' + resp.data.message, {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
          }
        });
    
        setOpenNewProject(false);
      };
  return (
    <section className={AddStyle.addWrapp}>
        <button className={AddStyle.btn} type='button' onClick={()=> setOpenNewProject(true)}>
            <img className={AddStyle.img} src={Flogo} alt='Film' />
            <p className={AddStyle.para}>New Project</p>
        </button>

        {openNewProject && (
            <ProjectSettingsDialogue
            data={{}}
            setOpenProjSettingsDialog={setOpenNewProject}
            openProjSettingsDialog={openNewProject}
            projects={[]}
            setProjects={() => {}}
            isNewProject={true}
            handleSaveNewProject={createNewProjectFunc}
            />
      )}
    </section>
  )
}

export default AddProjectSection