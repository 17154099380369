import React from 'react';
import useFilterAssetStyle from './useFilterAssetStyle';
import Paginate from '../Paginate';

const FilterLeft = ({ totalPages, setSkip, skip }) => {
  const { classes } = useFilterAssetStyle();

  return (
    <div className={classes.filterLeft}>
      <Paginate totalPages={totalPages} setSkip={setSkip} skip={skip} />
    </div>
  );
};

export default FilterLeft;
