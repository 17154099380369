import React from 'react';
import PreviewQuestBox from './questionPreviewBox/PreviewQuestBox';
import SingleQuestionBox from '../../single/SingleQuestionBox';
import style from './stepTwo.module.css';
import ButtonsStepTwo from './buttons/ButtonsStepTwo';
import ButtonsStepThree from '../step3/buttons/ButtonsStepThree';

const StepTwo = ({ questionStep, setQuestionSteps, state, dispatch, typeId }) => {
  const stepTwoInputs = ['Welcome message:', 'Instructions'];

  return (
    <>
      <PreviewQuestBox state={state} typeId={typeId} />

      {stepTwoInputs.map((item, index) => (
        <SingleQuestionBox key={index} index={index} isEmailInputHere={false} questionTitle={item} questionStep={questionStep} state={state} dispatch={dispatch} />
      ))}

      <div className={style.boxWrapp}>
        <SingleQuestionBox questionTitle="Email:" isEmailInputHere={true} index={0} questionStep={questionStep} state={state} dispatch={dispatch} />
      </div>

      {questionStep === 2 && <ButtonsStepTwo setQuestionSteps={setQuestionSteps} state={state} typeId={typeId} />}
      {questionStep === 3 && <ButtonsStepThree setQuestionSteps={setQuestionSteps} dispatch={dispatch} />}
    </>
  );
};

export default StepTwo;
StepTwo.defaultProps = {
  questionStep: 1,
  setQuestionSteps: () => {},
  state: [],
  dispatch: () => {},
  typeId: 1
};
