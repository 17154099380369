import styleHeader from './headerStyle.module.css';
import SearchIcon from '../../../movies/media_movie/Search.png';

const HeaderProject = ({ setSearchTerm }) => {
  return (
    <div className={styleHeader.headerWrapp}>
      <div className={styleHeader.headerMain}>
        <h2 className={styleHeader.title}>Projects</h2>

        <div className={styleHeader.inputWrapp}>
          <input type="text" placeholder="Search" className={styleHeader.input} onChange={(e) => setSearchTerm(e.target.value)} />
          <img src={SearchIcon} alt="search" className={styleHeader.icon} />
        </div>
      </div>
    </div>
  );
};

export default HeaderProject;
HeaderProject.defaultProps = {
  setSearchTerm: () => {}
};
