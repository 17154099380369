import testimonialService from '../../../../../../../services/testimonialService';
import ButtonFab from '../../../../../../../styled/buttons/ButtonFab';
import QuestionBackButton from '../../../button/back/QuestionBackButton';
import styles from './buttonStepTwo.module.css';
import SaveDraftButton from './saveDraft/SaveDraftButton';
import { toast } from 'react-toastify';

const ButtonsStepTwo = ({ setQuestionSteps, state, typeId }) => {
  const finishStep = (isDraft) => {
    let isOneOfTheInputsEmpty = false;

    const onSaveDraft = () => {
      toast.success('Draft saved!', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    };

    Object.values(state.step2).forEach((item) => {
      if (item.trim() === '') {
        isOneOfTheInputsEmpty = true;
      }
    });

    if (isOneOfTheInputsEmpty) return window.alert('All fields should be filled!');

    const testimonialQuestions = state?.step1[typeId - 1].map((item, index) => {
      return { order: index + 1, text: item?.value?.trim() };
    });

    const testimonialData = {
      TemplateProjectID: state?.step0?.templateId,
      Name: state?.stepStart?.projectName,
      TestimonialTypeID: typeId,
      Message: state?.step2?.msg?.trim(),
      Instructions: state?.step2?.instructions?.trim(),
      Email: state?.step2?.email?.trim(),
      Questions: testimonialQuestions,
      IsDraft: isDraft
    };

    const { req } = testimonialService.saveTestimonial(testimonialData);

    req
      .then((res) => {
        if (res?.data?.success) {
          if (!isDraft) setQuestionSteps((prev) => prev + 1);
          else {
            onSaveDraft();
            setTimeout(() => (window.location.href = '/promotions'), 2000);
          }
        } else {
          const errMsg = 'Error... ' + res?.data?.message;
          window.alert(errMsg);
        }
      })
      .catch((err) => window.alert(err));
  };

  return (
    <div className={styles.bStepTwoWrapp}>
      <QuestionBackButton setQuestionSteps={setQuestionSteps} />

      <div>
        <SaveDraftButton tittle="Save Draft" onClickHandler={() => finishStep(true)} />
        <ButtonFab tittle="Send" onClickHandler={() => finishStep(false)} />
      </div>
    </div>
  );
};

export default ButtonsStepTwo;
ButtonsStepTwo.defaultProps = {
  setQuestionSteps: () => {},
  state: {},
  typeId: 1
};
