import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import LabTabs from './LabTabs';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const styleShareDialog = makeStyles({
  shareMovieDialogText: {
    fontFamily: 'poppins',
    fontSize: '14px',
    opacity: '50%',
    marginTop: '0px',
    marginBottom: '10px',
    height: '20px'
  },
  shareMovieTextField: {
    backgroundColor: '#262338',
    borderRadius: '15px',
    width: '100%',
    height: '43px',
    border: 'none',
    marginTop: '8px',
    color: 'white'
  },
  getFayedButton: {
    width: '100%',
    height: '45px',
    backgroundColor: '#610BEF',
    color: 'white',
    borderRadius: '15px',
    marginLeft: '30px'
  }
});
const ShareMovieDialog = ({ isMobile, openShareMovieDialog, setOpenShareMovieDialog, data, setOpenModal, thumbnailUrl }) => {
  const classes = styleShareDialog();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Dialog
      PaperProps={{
        style: { backgroundColor: '#14142B', width: '90%', minHeight: '50%', border: '1.5 solid #4E4B66', borderRadius: '30px', color: 'white' }
      }}
      open={openShareMovieDialog}
      onClose={(e) => {
        e.stopPropagation();
        setOpenShareMovieDialog(false);
      }}
    >
      <DialogTitle
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        Share Movie
        <IconButton
          style={{ color: 'white', position: 'absolute', right: '15px' }}
          onClick={(e) => {
            setOpenShareMovieDialog(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <p className={classes.shareMovieDialogText}>Title:</p>
        <div className={classes.shareMovieTextField} variant="outlined">
          <p style={{ marginLeft: '15px', height: '23px', paddingTop: '12px', fontFamily: 'poppins' }}>{data.name}</p>
        </div>
        {/* <hr style={{ marginTop: "20px", border: "0.25px solid #FFFFFF", opacity: "50%", marginBottom: "20px" }} /> */}
        {/* <Grid container spacing={7}>
          <Grid item xs={6}>
            <p xs={6} className={classes.shareMovieDialogText}>
              Share with members on Faye.
            </p>
          </Grid>
          <Grid item xs={6}>
            <Button xs={6} style={{ width: "100%", height: "45px", backgroundColor: "#610BEF", color: "white", borderRadius: "15px", marginLeft: "20px" }}>
              Get Fayed
            </Button>
          </Grid>
        </Grid> */}
      </DialogContent>

      <DialogActions
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <LabTabs data={data} isMobile={isMobile} />
      </DialogActions>
    </Dialog>
  );
};
export default ShareMovieDialog;
