 import * as React from 'react';
import Avatar from '@mui/joy/Avatar';
import AvatarGroup from '@mui/joy/AvatarGroup';

export default function AvatarUsers({users}) { 
  return (
    <AvatarGroup>
        {!!users?.length > 0 && users.map((item, index) => index < 2 && <Avatar key={item?.id} alt={(item?.firstName + ' ' + item?.lastName)  || 'U N'} src={item?.imageUrl} /> )} 
        <span style={{height:'1px', margin: '0 3px'}}></span>
        {users?.length > 2 && <Avatar style={{backgroundColor:'#333230'}}>+{users.length - 2}</Avatar>}
    </AvatarGroup>
  );
}