import QuestionButton from '../../button/next/QuestionButton';
import stepStartStyle from './stepStart.module.css';

const StepStart = ({ questionStep, setQuestionSteps, state, dispatch }) => {
  return (
    <div className={stepStartStyle.questionBox}>
      <p className={stepStartStyle.questionParagraph}>Project title</p>
      <div className={stepStartStyle.txtAreaWrapp} style={{ }}>
        <input className={stepStartStyle.txtArea} value={state?.stepStart?.projectName} onChange={(e)=> dispatch({ type: 'UPDATE_PROJECT_NAME', payload: e.target.value })} placeholder='Testimonial name' />
      </div>
      
      <div className={stepStartStyle.buttonArea}>
          <QuestionButton isStartStep={true} setQuestionSteps={setQuestionSteps} state={state} />
      </div>
    </div>
  );
};

export default StepStart;
