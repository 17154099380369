import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useCustomHooks from './useCustomHooks';

const useSidebarFunc = () => {
  const history = useHistory();
  const location = useLocation();
  const [sideActiveBtn, setSideActiveBtn] = useState(null);
  const { useIcons, useStyle } = useCustomHooks();
  const { MoviesIcon, EditorIcon, NewLibrary, Promotions } = useIcons();
  const { classes } = useStyle();

  const setActiveSideMenu = () => {
    if (window.location.pathname === '/promotions') {
      setSideActiveBtn(0);
    } else if (window.location.pathname === '/projects') {
      setSideActiveBtn(1);
    } else if (window.location.pathname === '/library') {
      setSideActiveBtn(2);
    } else if (window.location.pathname === '/newmovies') {
      setSideActiveBtn(3);
    } else {
      setSideActiveBtn(null);
    }
  };

  useEffect(() => {
    setActiveSideMenu();
  }, [location, history]);

  const btnArrSide = [
    <div
      className={classes.icon}
      style={sideActiveBtn === 0 ? { opacity: '1' } : { opacity: '0.5' }}
      onClick={() => {
        toIspiration();
      }}
    >
      <Promotions key={0} alt="Top Icon" />
      <div className={classes.iconText}>DASHBOARD</div>
    </div>,
    <div
      className={classes.icon}
      style={sideActiveBtn === 1 ? { opacity: '1' } : { opacity: '0.5' }}
      onClick={() => {
        toProjects();
      }}
    >
      <EditorIcon key={1} alt="Projects Icon" />
      <br />
      <div className={classes.iconText}>PROJECTS</div>
    </div>,
    <div
      className={classes.icon}
      style={sideActiveBtn === 2 ? { opacity: '1' } : { opacity: '0.5' }}
      onClick={() => {
        toLibrary();
      }}
    >
      <NewLibrary key={2} alt="Library Icon" />
      <div className={classes.iconText}>LIBRARY</div>
    </div>,
    <div
      className={classes.icon}
      style={sideActiveBtn === 3 ? { opacity: '1' } : { opacity: '0.5' }}
      onClick={() => {
        toNewMoviesPage();
      }}
    >
      <MoviesIcon key={3} alt="Movies Icon" />
      <br />
      <div className={classes.iconText}>MOVIES</div>
    </div>
  ];

  function toNewMoviesPage() {
    history.push('/newmovies');
  }

  function toLibrary() {
    history.push('/library');
  }

  function toEditor() {
    history.push('/editor');
  }

  function toIspiration() {
    history.push('/promotions');
  }

  function toProjects() {
    history.push('/projects');
  }

  const sideBtns = () => {
    return btnArrSide.map((item, ind) => {
      if (ind == sideActiveBtn) {
        return (
          <div key={ind} className={classes.active} style={{ backgroundColor: '#403A60', marginBottom: ind === 0 ? '0px' : '0px' }}>
            {item}
          </div>
        );
      } else {
        return (
          <div key={ind} className={classes.active} style={{ marginBottom: ind === 0 ? '0px' : '0px' }}>
            {item}
          </div>
        );
      }
    });
  };

  return { sideBtns };
};

export default useSidebarFunc;
