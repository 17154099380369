import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import projectApi from '../../../../services/apiServices/projects/projectApi';

const useProjectsSection = ({ allProjects, setAllProjects }) => {
  const history = useHistory();

  const selectProject = (id) => {
    const { req } = projectApi.checkIsProjectEditable(id);
    req.then((resp) => {
      if (resp.data.success && resp.data.isEditable) {
        localStorage.removeItem('fayeproject');
        localStorage.setItem('fayeproject', id);
        history.push('/editor');
      } else {
        toast.info('You have to be Owner or Contributor to edit this project', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    });
  };

  const deleteHandler = (id) => {
    const { req } = projectApi.deleteProject(id);
    req.then((resp) => {
      const projectsFilter = allProjects.filter((element) => element.id !== id);
      if (projectsFilter != null) {
        setAllProjects(projectsFilter);
      } else {
        setAllProjects([]);
      }
    });
  };

  return { deleteHandler, selectProject };
};

export default useProjectsSection;
useProjectsSection.defaultProps = {
  allProjects: [],
  setAllProjects: () => {}
};
