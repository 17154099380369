import React from 'react'

const MoviePageThumbnailImage = ({cssStyle, data, thumbnailUrl, noThumb, openPreview, image_loader}) => {
  return (
    <img
        className={`${cssStyle.logoMovie} ${cssStyle.thubmnailSize}`}
        key={Date.now()}
        src={data.thumbnailExist === true ? thumbnailUrl : noThumb}
        alt="Logo"
        onClick={(e) => {
            openPreview(e);
        }}
        style={{
            wdith:'100%',
            height:'100%',
            backgroundImage: `url(${image_loader})`,
            backgroundSize:'cover',
            backgroundPosition: 'center'
        }}
        />
  )
}

export default MoviePageThumbnailImage