import { useEffect, useState } from 'react';
import projectApi from '../../services/apiServices/projects/projectApi';
import HeaderProject from './parts/header/HeaderProject';
import SubHeader from './parts/subHeader/SubHeader';
import Table from './parts/table/Table';
import stylePS from './projectSection.module.css';

const ProjectSection = ({ triggerNotifications, isMobile }) => {
  const [allProjects, setAllProjects] = useState([]);
  const [selectedId, setSelectedId] = useState('');
  const [projectTotalPages, setProjectTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [pageCurrent, setPageCurrent] = useState(0);

  const GetProjects = (page, searchTerm = '') => {
    const { axiosReq, cancelTokenSource } = projectApi.getAllProjects(page, 12, searchTerm, false);
    axiosReq
      .then((resp) => {
        if (resp?.data?.success) {
          setAllProjects(resp.data.data);
          setProjectTotalPages(Math.ceil(resp.data.totalCount / 12));
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    GetProjects(pageCurrent, searchTerm);
  }, [searchTerm, pageCurrent, triggerNotifications]);

  return (
    <section className={stylePS.projectWrapp}>
      <HeaderProject setSearchTerm={setSearchTerm} />
      <SubHeader projectTotalPages={projectTotalPages} setPageCurrent={setPageCurrent} />
      <Table isMobile={isMobile} allProjects={allProjects} setAllProjects={setAllProjects} selectedId={selectedId} setSelectedId={setSelectedId} />
    </section>
  );
};

export default ProjectSection;
ProjectSection.defaulrProps = {
  triggerNotifications: false,
  isMobile: false
};
