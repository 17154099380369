import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ListAdd from './projsett/ListAdd';
import SearchIcon from '@mui/icons-material/Search';
import useProject from './projsett/useProject';
import { toast } from 'react-toastify';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';

const styleShareDialog = makeStyles({
  shareMovieDialogText: {
    fontFamily: 'Poppins',
    fontSize: '13px',
    opacity: '50%',
    marginTop: '20px',
    marginBottom: '0px'
  },
  shareMovieTextField: {
    backgroundColor: '#262338',
    borderRadius: '10px',
    width: '100%',
    height: '43px',
    border: 'none',
    paddingLeft: '20px',
    color: 'white',
    fontFamily: 'Poppins',
    '&:focus-visible': {
      outline: 'none',
      border: '1px solid #4E4B66'
    }
  },
  shareMovieTextarea: {
    backgroundColor: '#262338',
    borderRadius: '10px',
    width: '100%',
    height: '93px',
    border: 'none',
    color: 'white',
    padding: '7px 20px',
    fontFamily: 'Poppins',
    '&:focus-visible': {
      outline: 'none',
      border: '1px solid #4E4B66'
    }
  },
  selectWrapp: {
    width: '100%',
    padding: '10px 20px',
    borderRadius: '10px',
    outline: 'none',
    backgroundColor: '#262338',
    color: 'white',
    fontFamily: 'Poppins',
    borderColor: '#4E4B66'
  },
  checkBox: {
    position: 'relative',
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    backgroundColor: 'grey',
    '&:hover': {
      opacity: '1'
    }
  },
  contributorsWrappInner: { width: '100%', display: 'flex' },
  imgWrapp: { width: '48px', height: '48px', position: 'relative' },
  image: { width: '100%', height: '100%', borderRadius: '50%' },
  delImg: {
    fontSize: '10px',
    fontWeight: 'bold',
    borderRadius: '50%',
    padding: '5px',
    backgroundColor: 'red',
    position: 'absolute',
    right: '-5px',
    top: '0px',
    width: '15px',
    height: '15px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  ulistWrapp: { width: '100%', backgroundColor: '#262338', marginTop: '10px', padding: '10px', borderRadius: '10px' },
  ulistHeader: { width: '100%', display: 'flex', justifyContent: 'space-between' },
  searchWrapp: { width: '100%' },
  searchWrappInner: { width: '100%', position: 'relative', margin: '10px 0' },
  searchInp: { color: 'white', backgroundColor: '#262338', border: '1px solid white', width: '100%', height: '40px', borderRadius: '10px', outline: 'none', paddingLeft: '30px' },
  iconSearch: { position: 'absolute', left: '5px', top: '7px', color: 'white' },
  saveBtnWrapp: { width: '100%', marginTop: '30px', padding: '5px', display: 'flex', justifyContent: 'flex-end' },
  saveBtn: {
    width: '30%',
    backgroundColor: '#A996FF',
    padding: '12px 20px',
    borderRadius: '10px',
    color: '#5312B5',
    fontWeight: 700,
    fontSize: '20px',
    '&:disabled': {
      opacity: 0.5,
      '& span': {
        opacity: 0.5,
        color: '#4700AB'
      }
    },
    '&:hover:not(:disabled)': {
      backgroundColor: ' #BFBEFC',
      color: '#5312B5'
    },
    '&:active': { 
      boxShadow: '0 0 0 0 white', 
      borderRadius: '4em', 
      opacity: '1', 
      transition: '0s' }
  }
});

const ProjectSettingsDialogue = ({ isMobile, data, setOpenProjSettingsDialog, openProjSettingsDialog, projects, setProjects, isNewProject = false, handleSaveNewProject = () => {} }) => {
  const classes = styleShareDialog();
  const [openContributorsList, setOpenContributorsList] = useState(false);
  const [fayeUserSpec, setFayeUserSpec] = useState([]);

  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [privacyID, setPrivacyID] = useState(1);

  const [contributorsList, setContributorsList] = useState([]);

  const [acceptToggle, setAcceptToggle] = useState(false);
  const [searchString, setSearchString] = useState('');

  const { getContributors, getSearchFayeUsers, getFayeUsersByCompId, getSpecUserByCompanyId, saveSettProject } = useProject({
    setFayeUserSpec,
    searchString,
    projectName,
    projectDescription,
    contributorsList,
    setContributorsList,
    privacyID
  });

  const FabButton = styled(Fab)(({ theme }) => ({
    width: '144px',
    textTransform: 'none',
    marginTop: '20px',
    borderRadius: '16px',
    height: '61px',
    backgroundColor: '#A996FF',
    color: '#4700AB',
    fontWeight: 'bold',
    borderColor: '#A996FF',
    outline: 'none',
    fontSize: '17px',
    '&:hover': {
      backgroundColor: '#c9befa',
      color: '#4700AB'
    }
  }));

  useEffect(() => {
    if (searchString.trim() !== '') {
      getSearchFayeUsers();
    } else {
      getFayeUsersByCompId();
    }
  }, [searchString]);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      getContributors(data.id);

      setProjectName(data.name);
      setPrivacyID(data.privacyID);
      setProjectDescription(data.description);
    }
    getSpecUserByCompanyId();
  }, [data]);

  const handleSaveProjectSettings = (id) => {
    if (!data.isDataEditable && !isNewProject) {
      toast.warn('Data can be edited only if it is created by users from same company.', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }

    saveSettProject(id)
      .then((res) => {
        if (res.data.success && !!res.data.data) {
          setContributorsList(res.data.data.addedContributors || []);
          setProjects(projects.map((item) => (item.id === id ? { ...item, name: res.data.data.name, description: res.data.data.description, privacyID: res.data.data.privacyID } : item)));
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  const Contributors = () => {
    return contributorsList.map((item, ind) => {
      return (
        <div key={ind} className={classes.contributorsWrapp} style={{ marginLeft: ind > 0 ? '20px' : '0px' }}>
          <div className={classes.contributorsWrappInner}>
            <div className={classes.imgWrapp}>
              <img className={classes.image} src={item.imageUrl} alt='img' />
              <span
                onClick={() => {
                  let x = contributorsList.filter((x) => x.userId !== item.userId);
                  setContributorsList(x);
                }}
                className={classes.delImg}
              >
                X
              </span>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <Dialog
      PaperProps={{ style: { zIndex: '12', backgroundColor: '#14142B', width: '90%', borderRadius: '15px', color: 'white', padding: isMobile ? '2px' : '20px', border: '2px solid #4E4B66' } }}
      open={openProjSettingsDialog}
      onClose={() => setOpenProjSettingsDialog(false)}
    >
      <DialogTitle style={{ fontSize:isMobile ? '20px' : '22px', fontFamily: 'Poppins', fontWeight: 600, display: 'flex', justifyContent:'space-between',  alignItems: 'center' }}>
        Project Settings
        <IconButton
          onClick={() => {
            setOpenProjSettingsDialog(false);
          }}
          sx={{
            // position: 'absolute',
            // right: 8,
            // top: 30,
            color: 'white'
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="dialog-content">
        <p className={classes.shareMovieDialogText}>Title:</p>
        <input maxLength="250" onClick={(e) => e.stopPropagation()} onChange={(e) => setProjectName(e.target.value)} type="text" value={projectName} className={classes.shareMovieTextField} />

        <p className={classes.shareMovieDialogText}>Description:</p>
        <textarea  maxLength="2000" onClick={(e) => e.stopPropagation()} onChange={(e) => setProjectDescription(e.target.value)} type="text" value={projectDescription} className={classes.shareMovieTextarea}>
          {projectDescription}
        </textarea>

        <p className={classes.shareMovieDialogText}>Privacy:</p>
        <select
          style={{height: isMobile && '81px'}}
          className={classes.selectWrapp}
          value={privacyID}
          onChange={(e) => {
            setAcceptToggle(false);
            if (!data.isPrivacyEditable && !isNewProject) {
              toast.warn('Privacy can be edited only by user who created project.', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
              });
              return;
            }
            setPrivacyID(e.target.value);
          }}
        >
          <option value={1}>Private (only visible for you)</option>
          <option value={2}>My Company (visible for company)</option>
          <option value={3}>Public (open for public outside of Faye)</option>
        </select>

        <p className={classes.shareMovieDialogText}>Contributors:</p>
        <div style={{ display: 'flex' }}>
          <Contributors />
        </div>

        <div className={classes.ulistWrapp}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setOpenContributorsList((prev) => !prev);
            }}
            className={classes.ulistHeader}
          >
            <span onClick={(e) => e.stopPropagation()}>Add</span>
            <span>{openContributorsList ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
          </div>

          <div className={classes.searchWrapp}>
            {openContributorsList && (
              <div className={classes.searchWrappInner}>
                <input onClick={(e) => e.stopPropagation()} className={classes.searchInp} onChange={(e) => setSearchString(e.target.value)} value={searchString} />
                <SearchIcon className={classes.iconSearch} />
              </div>
            )}

            {openContributorsList && fayeUserSpec.length > 0 && fayeUserSpec.map((item) => <ListAdd key={item.id} item={item} contributorsList={contributorsList} setContributorsList={setContributorsList} />)}
          </div>
        </div>

        {privacyID == 3 && (
          <>
            <div>
              <p className={classes.shareMovieDialogText}>
                By setting this project as public you understand and agree that this project and it’s content will be public for others using Faye. You confirm that you hold all necessary rights to all material provided
                and that you have the authority to share the material yourself.
              </p>
              <div
                onClick={() => {
                  setAcceptToggle((prev) => !prev);
                }}
                className={classes.checkBox}
                style={{ backgroundColor: acceptToggle ? '#A996FF' : 'grey' }}
              >
                {acceptToggle && <CheckIcon style={{ color: '#5E5594' }} />}
                <div style={{ fontFamily: 'Poppins', marginLeft: '35px', position: 'absolute', top: 0 }}>Accept</div>
              </div>
            </div>
          </>
        )}

        <div className={classes.saveBtnWrapp}>
          <FabButton
            disabled={privacyID == 3 && !acceptToggle ? true : false}
            onClick={(e) => {
              e.stopPropagation();
              if (privacyID == 3 && !acceptToggle) {
                toast.warn('Accept terms before continuing.', {
                  position: 'top-right',
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true
                });
                return;
              }

              if (!isNewProject) {
                handleSaveProjectSettings(data.id);
              } else {
                handleSaveNewProject(projectName, projectDescription, privacyID, contributorsList);
              }

              setOpenProjSettingsDialog(false);
              setAcceptToggle(false);
            }}
          >
            Save
          </FabButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default ProjectSettingsDialogue;
