import { useState, useEffect } from 'react';
import Slider from '@mui/material/Slider';
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeUp from '@mui/icons-material/VolumeUp';

const AssetVolume = ({ asset, color, volumeStyle, setInteractObject, isSoundtrack = false }) => {
  const [sliderValue, setSliderValue] = useState(null);

  useEffect(() => {
    if (sliderValue === null) {
      setSliderValue(asset.volume);
    }
  }, []);

  useEffect(() => {
    if (sliderValue !== null) {
      if (!isSoundtrack) {
        let item = {};
        item.type = 'assetvolume';
        item.id = asset.assetStyleID;
        item.volume = sliderValue;
        setInteractObject(item);
      } else {
        let item = {};
        item.type = 'soundtrackvolume';
        item.volume = sliderValue;
        setInteractObject(item);
      }
    }
  }, [sliderValue]);
  
  return (
    <div className={volumeStyle}>
      <VolumeDown style={{ color: color }} />
      <Slider
        sx={{ bottom: !isSoundtrack ? '3px' : 0 }}
        aria-label="Volume"
        step={0.1}
        min={0}
        max={1.0}
        value={sliderValue}
        onChange={(event, newValue) => {
          event.stopPropagation();
          setSliderValue(newValue);
        }}
      />
      <VolumeUp style={{ color: color }} />
    </div>
  );
};

export default AssetVolume;
