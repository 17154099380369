import styles from './buttonStepThree.module.css'
import successImg from './success.png'

const ButtonsStepThree = ({setQuestionSteps, dispatch}) => { 
  const cleartAllInputs = () => {
    const emailInp = document.querySelector('.emailInput');
    emailInp.value = '';  
    dispatch({ type: 'UPDATE_EMAIL', payload:'' }); 
    setQuestionSteps(2)
  }

  return (
    <div className={styles.bStepThreeWrapp}>
        <div className={styles.bStepThreeInner}>
           <button type='button' className={styles.button}>
               <img className={styles.buttonSuccessSign} src={successImg} alt='sent'/>
               <span className={styles.sentTxt}>Sent</span>
           </button>

           <p className={styles.sendAnotherTxt} onClick={cleartAllInputs} >Send another one?</p>
        </div>
    </div>
  )
}

export default ButtonsStepThree