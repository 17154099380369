import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASE_URL;
const url = '/api/Projects';
const userFeedback = '/api/Auth/FeedbackUser';
const newMoviesUrl = '/api/Project/newmovies';
const allprojectsUrl = '/api/Project/get-all';
const renderUrl = '/api/Project/render';
const thumbnailUrl = '/api/File/movie-thumbnail';
const appversionUrl = '/api/Project/appversion';
const newProjectUrl = '/api/Project/newproject';
const changePass = '/api/Auth/Changepassword/';
const singleCompany = '/api/Company/GetSingleCompany/';
const settingsUser = '/api/Auth/GetFayeUser/';
const orgUserSave = '/api/Company/update-org-data';
const uplImageSettings = '/api/Auth/saveUserSettings/';
const fayeUsers = '/api/Auth/GetFayeUsers';
const userRoles = '/api/Auth/GetRoles';
const filterFayeUsers = '/api/Auth/FilterUsers/';
const companyFayeUsers = '/api/Project/getCompanyUsers/';
const saveProjectSettingsUrl = '/api/Project/SaveProjectSettings';
const saveMovieSettingsUrl = '/api/Project/SaveMovieSettings';
const getContributorsUrl = '/api/Project/getContributors/';
const searchSpecUsers = '/api/Project/searchSpecUser/';
const deleteCompanyAssets = '/api/Assets/DeleteAsset/';
const deleteMovieUrl = '/api/project/deletemovie/';
const deleteProjectUrl = '/api/project/deleteproject/';
const duplicateProjectUrl = '/api/project/duplicateproject/';
const getProjectByIdUrl = '/api/project/GetProjectById/';
const getCheckEditableUrl = '/api/project/project-editable/';

const settingOrgSave = (datas) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');

  return {
    axiosReq: axios({
      method: 'post',
      url: baseUrl + orgUserSave,
      data: datas,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const getSettingsUser = () => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');
  return {
    req: axios({
      method: 'get',
      url: baseUrl + settingsUser,
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const getProjects = () => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  return {
    axiosReq: axios.get(baseUrl + url, {
      cancelToken: source.token,
      headers: { Authorization: 'Bearer ' + token }
    }),
    cancelTokenSource: source
  };
};

const getAppVersion = () => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  return {
    axiosReq: axios.get(baseUrl + appversionUrl, {
      cancelToken: source.token,
      headers: { Authorization: 'Bearer ' + token }
    }),
    cancelTokenSource: source
  };
};

const getNewMovies = (skip, take, search) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');
  return {
    axiosReq: axios.get(baseUrl + newMoviesUrl, {
      cancelToken: source.token,
      params: {
        Search: search,
        Skip: skip,
        Take: take
      },
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const deleteMovie = (id) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');

  return {
    req: axios({
      method: 'post',
      url: baseUrl + deleteMovieUrl + id,
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const getAllProjects = (skip, take, search, onlyPublic = false) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  return {
    axiosReq: axios.get(baseUrl + allprojectsUrl, {
      cancelToken: source.token,
      params: {
        Search: search,
        Skip: skip,
        Take: take,
        OnlyPublic: onlyPublic
      },
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};
const deleteProject = (id) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');

  return {
    req: axios({
      method: 'post',
      url: baseUrl + deleteProjectUrl + id,
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const duplicateProject = (data) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');

  return {
    req: axios({
      method: 'post',
      url: baseUrl + duplicateProjectUrl,
      data: data,
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const getProjectById = (id) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');

  return {
    req: axios({
      method: 'post',
      url: baseUrl + getProjectByIdUrl + id,
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const addProjectToRender = (projectID, projectDuration, name, description, privacyID) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  return {
    axiosReq: axios.post(
      baseUrl + renderUrl,
      { ProjectID: projectID, ProjectDuration: projectDuration, Name: name, Description: description, PrivacyID: privacyID },
      {
        cancelToken: source.token,
        headers: { Authorization: 'Bearer ' + token }
      }
    ),
    cancelTokenSource: source
  };
};

const createNewProject = (data) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');

  return {
    axiosReq: axios({
      method: 'post',
      url: baseUrl + newProjectUrl,
      data: data,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const addThumbnail = (formData, data) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');
  return {
    req: axios({
      method: 'post',
      url: baseUrl + thumbnailUrl,
      data: formData,
      cancelToken: source.token,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const GetSingleCompany = (email) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');
  return {
    req: axios({
      method: 'get',
      url: baseUrl + singleCompany + email,
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const uploadImageSettingsAccount = (datas) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');

  return {
    axiosReq: axios({
      method: 'post',
      url: baseUrl + uplImageSettings,
      data: datas,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const changePassword = (oldPass, newPass) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');

  return {
    axiosReq: axios({
      method: 'post',
      url: baseUrl + changePass + oldPass + '/' + newPass,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const getFayeUsers = () => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  return {
    axiosReq: axios.get(baseUrl + fayeUsers, {
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const getUserRoles = () => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');
  return {
    axiosReq: axios.get(baseUrl + userRoles, {
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const getFilterUsers = (name) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');
  return {
    axiosReq: axios.get(baseUrl + filterFayeUsers, {
      cancelToken: source.token,
      params: {
        Name: name
      },
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const getFayeUsersByCompanyID = () => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');
  return {
    axiosReq: axios.get(baseUrl + companyFayeUsers, {
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const saveProjectSettings = (obj, isMovie = false) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');
  return {
    req: axios({
      method: 'post',
      url: baseUrl + (!isMovie ? saveProjectSettingsUrl : saveMovieSettingsUrl),
      data: obj,
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const getContributors = (projectID) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');
  return {
    axiosReq: axios.get(baseUrl + getContributorsUrl + projectID, {
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const getFayeUsersSearch = (searchString) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  return {
    req: axios({
      method: 'get',
      url: baseUrl + searchSpecUsers + searchString,
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const deleteAssetsFromCompany = (data, isScene) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  return {
    req: axios({
      method: 'post',
      params: {isScene},
      data: data,
      url: baseUrl + deleteCompanyAssets,
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const checkIsProjectEditable = (projectID) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem("token");

  return {
    req: axios.get(baseUrl + getCheckEditableUrl, {
      params: { ProjectID: projectID },
      cancelToken: source.token,
      headers: { Authorization: "Bearer " + token }
    }),
    cancelTokenSource: source
  };
};


let connection;
const subscribeToEditorHub = async (user, projectID, setCountUsers) => {
  
  connection = new HubConnectionBuilder()
    .withUrl(baseUrl + '/editorhub', {
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets
    })
    .configureLogging(LogLevel.Error)
    .build();

  connection.on('UserIsInEditor', (message) => {
    setCountUsers(message);
  });
  await connection.start();
  await connection.invoke('SubscribeProjectEditor', { user, room: projectID });
};

let unsubscribeToEditorHub = async () => {
  if (!!connection) {
    await connection.stop();
  }
};


const sendUserFeedback = (data) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');
  return {
    req: axios({
      method: 'post',
      url: baseUrl + userFeedback,
      data: data,
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

export default {
  sendUserFeedback,
  getFayeUsers,
  getProjects,
  addProjectToRender,
  getNewMovies,
  getAllProjects,
  addThumbnail,
  getAppVersion,
  createNewProject,
  GetSingleCompany,
  getSettingsUser,
  settingOrgSave,
  uploadImageSettingsAccount,
  changePassword,
  getUserRoles,
  getFilterUsers,
  getFayeUsersByCompanyID,
  saveProjectSettings,
  getContributors,
  getFayeUsersSearch,
  deleteAssetsFromCompany,
  deleteMovie,
  deleteProject,
  duplicateProject,
  getProjectById,
  checkIsProjectEditable,
  subscribeToEditorHub,
  unsubscribeToEditorHub
};
