import { useEffect, useState } from 'react';
import Question from './question/Question';
import styles from './testimonial.module.css';

const SingleTestimonial = ({ item, draftObject, isDraftedTemplate, setIsDraftedTemplate }) => { 
  const [openQuestion, setOpenQuestion] = useState(false);

  useEffect(()=>{
    if(isDraftedTemplate){
      setOpenQuestion(draftObject?.typeId === item?.typeId)
    }
  }, [item])

  useEffect(()=>{
    if(!openQuestion){
      setIsDraftedTemplate(false) 
       }
  }, [openQuestion])


  return (
    <>
      <div className={styles.singleTmWrapp} onClick={() => setOpenQuestion(true)}>
        <img src={item.logo} alt={item?.overlayName} className={styles.image} />
      </div>

      {openQuestion && <Question setOpenQuestion={setOpenQuestion} btnHeaderTextColor={item.overlayTxtColor} btnName={item.overlayName} typeId={item.typeId} draftObject={draftObject} isDraftedTemplate={isDraftedTemplate} />}
    </>
  );
};

export default SingleTestimonial;

SingleTestimonial.defaultProp = {
  item: {
    overlayName: 'Test',
    overlayTxtColor: 'white'
  },
  draftObject: {}, 
  isDraftedTemplate: false, 
  setIsDraftedTemplate: ()=>{}
};
