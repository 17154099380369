import QuestionBackButton from '../../button/back/QuestionBackButton';
import QuestionButton from '../../button/next/QuestionButton';
import DropdownQuestion from '../../dropwdown/DropdownQuestion';
import SingleQuestionBox from '../../single/SingleQuestionBox';
import styleStepOne from './stepOne.module.css';

const StepOne = ({ questionStep, setQuestionSteps, state, dispatch, typeId }) => {
  return (
    <>
      <div className={styleStepOne.mainQuestionWrapp}>
        {state?.step1[typeId - 1].map((question, index) => (
          <SingleQuestionBox key={index + '' + questionStep} typeId={typeId} questionTitle={question} index={index} state={state} dispatch={dispatch} questionStep={questionStep} />
        ))}
      </div>

      <DropdownQuestion state={state} dispatch={dispatch} typeId={typeId} />

      <div className={styleStepOne.buttonsWrapp}>
        <QuestionBackButton setQuestionSteps={setQuestionSteps} />
        <QuestionButton setQuestionSteps={setQuestionSteps} state={state} typeId={typeId} />
      </div>
    </>
  );
};

export default StepOne;
StepOne.defaultProps = {
  questionStep: 1,
  setQuestionSteps: () => {},
  state: () => {},
  dispatch: () => {}
};
