import React from 'react';
import styles from './questionBackButton.module.css';

const QuestionBackButton = ({ setQuestionSteps }) => {
  return (
    <button className={styles.qutton} type="button" onClick={() => setQuestionSteps((prev) => prev - 1)}>
      <span className={styles.quttonSign}> &lt; </span>
      <span className={styles.quttonTxt}>Back</span>
    </button>
  );
};

export default QuestionBackButton;
QuestionBackButton.defaultProps = {
  setQuestionSteps: () => {}
};
