import { Grid, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '../../utilities/consts';
import FullPreviewModal from '../modals/FullPreviewModal';
import SidePicker from '../sidePicker/SidePicker';
import ProjectTime from '../timeline/projectTime/ProjectTime';
import stageStyle from './css/stage.module.css';
import NameAndSaveHandlers from './stage-objects/NameSaveHandlers';

const useStyles = makeStyles({
  stagewrapp: {
    width: '100%',
    minWidth: '92%',
    backgroundColor: '#14142A',
    // overflow: 'hidden',
    // position: 'relative',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  stage: {
    width: SCREEN_WIDTH + 'px', // 16:9 width don't change it if you don't know what are you doing
    height: SCREEN_HEIGHT + 'px', // 16:9 height don't change it if you don't know what are you doing
    backgroundColor: 'lightgray',
    overflow: 'hidden',
    //position: 'relative',
    borderRadius: '5px'
  },
  frameWrapp: {
    dispaly: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80%',
    width: '22%',
    borderRight: '2px solid gray'
  },
  frame: {
    width: '87%',
    height: '99%'
  },
  frameLast: {
    dispaly: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80%',
    width: '22%'
  },

  sidePickerWrapp: {
    width: '100%',
    display: 'flex',
    height: '586px',
    maxWidth: '990px'
  },
  stageWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  }
});

const Stage = ({
  // remainingSeconds,
  activeSceneContent,
  scenes,
  projectDuration,
  setProjectDuration,
  setInteractObject,
  setSceneAssetsReorder,
  setActiveSceneID,
  activeSceneID,
  triggerAssetRangers,
  selectedAsset,
  setSelectedAsset,
  currentAttribute,
  setCurrentAttribute,
  libraryUpdated,
  openAdvancedTextOptions,
  setOpenAdvancedTextOptions,
  openFullPreviewModal,
  setOpenFullPreviewModal,
  movieName,
  setMovieName,
  mainProjectID,
  saveProjectScenes,
  addToRender,
  countUsersOnProject,
  setLibraryUpdated
}) => {
  const classes = useStyles();

  useEffect(() => {
    const assets = [];
    let totalDuration = 0;

    for (let i = 0; i < scenes.length; i++) {
      let sceneAssets = scenes[i].assets.filter((x) => x.deleted === false).sort((a, b) => b.z - a.z);

      if (sceneAssets.length > 0) {
        for (let j = 0; j < sceneAssets.length; j++) {
          // scene duration is by the first clip
          let masterClipLength = sceneAssets[j].isMaster === true ? sceneAssets[j].length : 0;
          totalDuration += masterClipLength;

          assets.push(sceneAssets[j]);
        }
      }
    }

    setProjectDuration(totalDuration);

    /// scenes are changed when asset are changed (start, trim, length...)
  }, [scenes]);

  const [newRange, setNewRange] = useState(0);
  const [timeRangeClicked, setTimeRangeClicked] = useState(false);

  const insertAsset = ({ data }, ismain = false) => {
    if (data.assetType === 'scenes') {
      let item = {};
      item.type = 'sceneinsert';
      item.assets = data.assets.sort((a, b) => a.z - b.z);
      setInteractObject(item);
    } else if (data.assetType === 'pexelvideos') {
      let videoFile = data.video_files
        .filter((x) => x.quality === 'sd')
        .reduce((prev, current) => {
          return prev.width > current.width ? prev : current;
        });

      if (!videoFile) {
        videoFile = data.video_files
          .filter((x) => x.quality === 'hd')
          .reduce((prev, current) => {
            return prev.width < current.width ? prev : current;
          });
      }
      let item = {};
      item.type = 'assetinsert';
      item.assetId = uuid();
      item.ismain = ismain;
      item.name = data.url
        .split('/')
        .filter((x) => x)
        .pop();
      item.originalHeight = videoFile.height;
      item.originalWidth = videoFile.width;
      item.assetType = 'video';
      item.assetTypeID = 1;
      item.url = videoFile.link;
      let videoMeta = document.createElement('video');
      videoMeta.preload = 'metadata';
      videoMeta.src = videoFile.link;
      videoMeta.addEventListener('loadedmetadata', function () {
        item.originalLength = videoMeta.duration;
        setInteractObject(item);
      });
    } else if (data.assetType === 'pexelphotos') {
      let item = {};
      item.type = 'assetinsert';
      item.assetId = uuid();
      item.ismain = ismain;
      item.name = data.alt;
      item.originalHeight = data.height;
      item.originalLength = 5;
      item.originalWidth = data.width;
      item.assetType = 'image';
      item.assetTypeID = 2;
      item.url = data.src.original;
      setInteractObject(item);
    } else {
      if (data.assetType === 'video' && !!data.hasPreview && !data.previewUrl) {
        toast.warning('Please wait for the video to finish processing.', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        return;
      }

      let item = {};
      item.type = 'assetinsert';
      item.assetId = data.assetType === 'html' ? uuid() : data.id;
      item.ismain = ismain;
      item.name = data.name;
      item.originalHeight = data.originalHeight;
      item.originalLength = data.assetType === 'image' || data.assetType === 'html' ? 5 : data.originalLength;
      item.originalWidth = data.originalWidth;
      item.assetType = data.assetType;
      item.assetTypeID = data.assetTypeID;
      item.url = data.url;
      item.previewUrl = data.previewUrl;
      item.canvasStyle = data.canvasStyle;
      item.text = data.text;
      setInteractObject(item);
    }
  };

  return (
    <Grid container className={classes.stageWrapper}>
      <Grid container className={`${classes.stagewrapp}`} id={stageStyle.stageWrappResp}>
        <div style={{ display: 'flex', flexBasis: 0, marginLeft: '1%' }}>
          <Grid>
            <ProjectTime
              scenes={scenes}
              activeSceneID={activeSceneID}
              newRange={newRange}
              setNewRange={setNewRange}
              timeRangeClicked={timeRangeClicked}
              setTimeRangeClicked={setTimeRangeClicked}
              activeSceneContent={activeSceneContent.filter((x) => !x.deleted)}
              selectedAsset={selectedAsset}
              setInteractObject={setInteractObject}
              triggerAssetRangers={triggerAssetRangers}
              setOpenAdvancedTextOptions={setOpenAdvancedTextOptions}
              insertAsset={insertAsset}
              setSelectedAsset={setSelectedAsset}
              setSceneAssetsReorder={setSceneAssetsReorder}
              setActiveSceneID={setActiveSceneID}
              setCurrentAttribute={setCurrentAttribute}
              setLibraryUpdated={setLibraryUpdated}
            />
          </Grid>
        </div>
        <div style={{ flex: 1, margin: '0px 1%' }}>
          <Grid item>
            <NameAndSaveHandlers
              // remainingSeconds={remainingSeconds}
              mainProjectID={mainProjectID}
              movieName={movieName}
              setMovieName={setMovieName}
              setInteractObject={setInteractObject}
              saveProjectScenes={saveProjectScenes}
              addToRender={addToRender}
              countUsersOnProject={countUsersOnProject}
            />
          </Grid>

          <Grid item className={classes.sidePickerWrapp} id="side-picker">
            <SidePicker
              libraryUpdated={libraryUpdated}
              setLibraryUpdated={setLibraryUpdated}
              selectedAsset={selectedAsset}
              currentAttribute={currentAttribute}
              setInteractObject={setInteractObject}
              openAdvancedTextOptions={openAdvancedTextOptions}
              setOpenAdvancedTextOptions={setOpenAdvancedTextOptions}
              activeSceneContent={activeSceneContent}
            />
          </Grid>
        </div>
      </Grid>
      <FullPreviewModal scenes={scenes} projectDuration={projectDuration} setOpenFullPreviewModal={setOpenFullPreviewModal} openFullPreviewModal={openFullPreviewModal} />
    </Grid>
  );
};

export default Stage;
