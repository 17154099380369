import { useState } from "react";
import MobileTabsLibrary from "../../library/mobile/MobileTabsLibrary"
import TabSocialMobile from "./TabSocialMobile";
import { CopyToClipboard } from 'react-copy-to-clipboard';


const TabShareMobile = ({root, classes, FabButton, handleChange, value, setValue}) => {
    const shareTabs = ['Social', 'Link', 'Embed']; 
  return (
    <div style={{width:'100%', padding:'16px', height:'285px', overflow:'hidden'}}>
        <MobileTabsLibrary
            isLabTabs={true}
            mobileSubLinks={shareTabs}
            setActiveSubLibTab={setValue}
            activeSubLibTab={value}
            handleChange={handleChange}

        />
        {value === 0 && <TabSocialMobile root={root} classes={classes} /> }

        {value === 1 &&
         <>
           <div style={{ width: '100%', padding:'12px' }}>
            <input style={{ width: '100%', backgroundColor: '#262338', border: 'none', outline: 'none', color: 'white', padding: '7px' }} type="text" value={root} disabled />
          </div>

          <div style={{padding:'12px'}}>
            <CopyToClipboard text={root}> 
                <FabButton size="large" variant="extended" > Copy link </FabButton>
            </CopyToClipboard>
          </div>
         </>}
    </div>
  )
}

export default TabShareMobile