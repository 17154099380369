import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASE_URL;
const getUrl = '/api/PlayMovie/GetUrl/';
const generateShareUrl = '/api/PlayMovie/generetaShareLink/';

const getShareUrl = (shareId) => {
  return {
    req: axios.get(baseUrl + getUrl + shareId)
  };
};

const generateLinkShare = (renderId) => {
  return {
    req: axios.put(baseUrl + generateShareUrl + renderId)
  };
};
export default {
  getShareUrl,
  generateLinkShare
};
