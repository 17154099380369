import PreviewQuestion from '../previewQuestion/PreviewQuestion';
import previewQuestStyle from './previewQuestBox.module.css';
import { useState } from 'react';

const PreviewQuestBox = ({ state, typeId }) => {
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const [visibleGreaterThan, setVisibleGreaterThan] = useState(true);
  const [visibleLowerThan, setVisibleLowerThan] = useState(true);

  const incrementPreviewQuestion = () => {
    if (selectedQuestion + 1 <= state?.step1[typeId-1].length) {
      setSelectedQuestion((prev) => prev + 1);
      setVisibleLowerThan(true);

      if (selectedQuestion + 1 === state?.step1[typeId-1].length - 1) {
        setVisibleGreaterThan(false);
      }
    }
  };

  const decrementPreviewQuestion = () => {
    if (selectedQuestion > 0) {
      setSelectedQuestion((prev) => prev - 1);
      setVisibleGreaterThan(true);
      if (selectedQuestion === 1) {
        setVisibleLowerThan(false);
      }
    }
  };
  return (
    <div className={previewQuestStyle.preivewQuestWrapp}>
      <div className={previewQuestStyle.preivewArrowsWrapp}>
        <span className={previewQuestStyle.preivewArrow} onClick={decrementPreviewQuestion} style={{ visibility: !visibleLowerThan && 'hidden' }}>
          &lt;
        </span>
      </div>

      <PreviewQuestion typeId={typeId} setVisibleLowerThan={setVisibleLowerThan} setVisibleGreaterThan={setVisibleGreaterThan} state={state} selectedQuestion={selectedQuestion} setSelectedQuestion={setSelectedQuestion} />

      <div className={previewQuestStyle.preivewArrowsWrapp}>
        <span className={previewQuestStyle.preivewArrow} onClick={incrementPreviewQuestion} style={{ visibility: !visibleGreaterThan && 'hidden' }}>
          &gt;
        </span>
      </div>
    </div>
  );
};

export default PreviewQuestBox;
PreviewQuestBox.defaultProps = {
  state: {},
  typeId: 1
};
