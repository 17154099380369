import { makeStyles } from '@material-ui/core';
import { useContext } from 'react';
import { CurrentTimeContext } from '../../../../../../context/current_time_context';

const useStyles = makeStyles({
  sceneVideoAsset: {
    height: '60px',
    width: '135px',
    alignSelf: 'center',
    cursor: 'pointer',
    objectFit: 'cover'
  }
});

const VideoSceneAsset = ({ asset, setSelectedAsset, activeSceneContent, setCurrentAttribute }) => {
  const classes = useStyles();

  const { setCurrentTime } = useContext(CurrentTimeContext);

  return (
    <video
      className={classes.sceneVideoAsset}
      onClick={e => {
        if (e.detail === 2) {
          let sceneStart = 0;
          if (!!activeSceneContent.find(x => x.isMaster === true)) {
            sceneStart = activeSceneContent.find(x => x.isMaster === true).start;
          }
          let assetStart = asset.start;
          setCurrentTime(assetStart - sceneStart);
        }
        setSelectedAsset(asset);
        setCurrentAttribute("video")
      }}
    >
      <source autoPlay src={asset.asset.previewSrc || asset.asset.src} />
    </video>
  );
};
export default VideoSceneAsset;
VideoSceneAsset.defaultProps = {
  asset: {}, 
  setSelectedAsset:()=>{}, 
  activeSceneContent: {}, 
  setCurrentAttribute: ()=>{}
}
