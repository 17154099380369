import SingleTestimonial from './SingleTestimonial';
import styles from './testimonial.module.css';
import clientLogo from './pics/clientt.png';
import employeeLogo from './pics/employee.png';
import partnerLogo from './pics/partner.png';
import educationLogo from './pics/educational.png';

const Testimonial = ({ draftObject, isDraftedTemplate, setIsDraftedTemplate }) => {
  const ALL_TESTIMONIALS = [
    { typeId: 1, overlayName: 'Client', overlayTxtColor: '#CBFFAE', logo: clientLogo },
    { typeId: 2, overlayName: 'Employee', overlayTxtColor: '#FFC3C0', logo: employeeLogo },
    { typeId: 3, overlayName: 'Partner', overlayTxtColor: '#8DE9FF', logo: partnerLogo },
    { typeId: 4, overlayName: 'Education', overlayTxtColor: '#F7F76D', logo: educationLogo } //ex product
  ];
  return (
    <article className={styles.tmWrapp}>
      <div className={styles.titleTm}>
        <h5 className={styles.title}>Start your new testimonial here:</h5>
      </div>

      <div className={styles.wrapperSingle}>
        {ALL_TESTIMONIALS.map((item) => (
          <SingleTestimonial key={item.typeId} item={item} draftObject={draftObject} isDraftedTemplate={isDraftedTemplate} setIsDraftedTemplate={setIsDraftedTemplate} />
        ))}
      </div>
    </article>
  );
};

export default Testimonial;
Testimonial.defaultProps = {
  draftObject: {},
  isDraftedTemplate: false,
  setIsDraftedTemplate: () => {}
};
