import { makeStyles } from '@material-ui/core';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton, ViberIcon, ViberShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import movieShareService from '../../services/apiServices/movieShareService';
import TabShareMobile from './tabsShare/TabShareMobile';

const FabButton = styled(Fab)(({ theme }) => ({
  width: '144px',
  textTransform: 'none',
  marginTop: '20px',
  height: '61px',
  backgroundColor: '#A996FF',
  color: '#4700AB',
  fontWeight: 'bold',
  borderColor: '#A996FF',
  outline: 'none',
  fontSize: '17px',
  '&:hover': {
    backgroundColor: '#c9befa',
    color: '#4700AB'
  }
}));

const styleAccountSettings = makeStyles({
  saveBtn: {
    backgroundColor: '#A996FF',
    padding: '8px 16px',
    color: '#4700AB',
    borderRadius: '10px',
    fontWeight: 'bold',
    marginTop: '0px',
    border: '1px solid #A996FF',
    // width:'50%', height:'50px', backgroundColor:'#A996FF', borderRadius:'12px',color:'#4B457A', fontWeight:'bold', borderColor:'#A996FF',
    outline: 'none',
    fontSize: '1.05rem',
    transitionDuration: '0.4s',
    webkitTransitionDuration: '0.4s',
    '&:hover': { backgroundColor: '#c9befa', transitionDuration: '0.1s' },
    '&:active': { boxShadow: '0 0 0 0 white', borderRadius: '4em', opacity: '1', transition: '0s' }
  },
  innerTabMobile: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '5px',
    height: '225px',
    overflow: 'auto',
    paddingRight: '9px'
  },
  shareRowMobile: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    padding: '15px 0px',
    borderBottom: '1px solid #888893'
  },
  rowLeftMobile: {
    paddingLeft: '5px',
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  shareBtnMobile: {
    width: '97px',
    fontFamily: 'Poppins',
    color: '#4700AB',
    fontWeight: 'bold',
    fontSize: '13px',
    height: '37px',
    backgroundColor: '#A996FF',
    borderRadius: '10px',
    padding: '7px',
    border: '1px solid #A996FF'
  }
});

const notifyCopy = () => {
  toast.success('Link copied to clipboard', {
    position: 'top-right',
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
  });
}

const LabTabs = (props) => {
  const { data, isMobile } = props;

  const classes = styleAccountSettings();
  const [value, setValue] = React.useState(0);
  const [rootUrl, setRootUrl] = useState('');

  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      setValue(newValue);
    } else if (newValue === 2) {
      const { req } = movieShareService.generateLinkShare(data?.renderID);

      req
        .then((res) => {
          if (res.data.success === true) {
            let url = 'https://app.faye.io/play-video/';
            setRootUrl(url + res?.data?.code);
          }
        })
        .then(() => setValue(newValue))
        .catch((err) => {
          setRootUrl('Generating link failed!');
          setValue(newValue);
        });
    } else {
      setValue(0);
    }
  };

  useEffect(() => {
    const { req } = movieShareService.generateLinkShare(data?.renderID);

    req
      .then((res) => {
        if (res.data.success === true) {
          let url = 'https://app.faye.io/play-video/';
          setRootUrl(url + res?.data?.code);
        }
      })
      .then(() => setValue(0))
      .catch((err) => {
        setRootUrl('Generating link failed!');
        setValue(0);
      });
  }, []);

  if (isMobile) {
    return <TabShareMobile root={rootUrl} classes={classes} FabButton={FabButton} handleChange={handleChange} value={value} setValue={setValue} />;
  }

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value} sx={{ width: '100%', typography: 'body1' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Social" value={0} />
            <Tab label="Link" value={2} />
          </TabList>
        </Box>

        <TabPanel value={0} style={{ height: '145px' }}>
          <FacebookShareButton image={'https://www.w3schools.com/images/lamp.jpg'} url={rootUrl} className="Demo__some-network__share-button">
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <TwitterShareButton title={'test'} url={rootUrl} hashtags={['hashtag1', 'hashtag2']}>
            <TwitterIcon size={32} round style={{ marginLeft: '3px' }} />
          </TwitterShareButton>
          {/* <EmailShareButton url={data.data.storageUrl + data.data.moviePath}>
              <EmailIcon size={32} round style={{marginLeft:'3px'}}/>
            </EmailShareButton> */}
          <LinkedinShareButton url={rootUrl}>
            <LinkedinIcon size={32} round style={{ marginLeft: '3px' }} />
          </LinkedinShareButton>
          <ViberShareButton url={rootUrl}>
            <ViberIcon size={32} round style={{ marginLeft: '3px' }} />
          </ViberShareButton>
          <WhatsappShareButton url={rootUrl}>
            <WhatsappIcon size={32} round style={{ marginLeft: '3px' }} />
          </WhatsappShareButton>
        </TabPanel>

        <TabPanel value={2} style={{ height: isMobile ? '225px' : '145px' }}>
          {/* {showCopy && <> */}
          <div style={{ width: '100%' }}>
            <input style={{ width: '100%', backgroundColor: '#262338', border: 'none', outline: 'none', color: 'white', padding: '7px' }} type="text" value={rootUrl} disabled />
          </div>
          <CopyToClipboard text={rootUrl}>
            <FabButton size="large" variant="extended" onClick={notifyCopy}>
              Copy link
            </FabButton>
          </CopyToClipboard>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default LabTabs;
