import { Grid, makeStyles } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { useContext } from 'react';
import { AiOutlineMenuUnfold } from 'react-icons/ai';
import { SidebarActionsContext } from '../../context/sidebar_actions_context';

const headerAssetStyles = makeStyles({
  headLibWrapp: {
    width: '100%',
    display: 'flex',
    marginBottom: '30px',
    justifyContent: 'space-between'
  },

  headTitleWrapp: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: 'white',
    '& h1': {
      fontSize: '2.5rem'
    }
  },
  restlibWrapp: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '12px 0px'
  },
  searchWrapp: {
    width: '60%',
    position: 'relative',
    margin: '10px 10px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  searchInp: {
    color: '#A0A3BD',
    height: '100%',
    backgroundColor: '#262338',
    border: '1px solid #262338',
    width: '100%',
    borderRadius: '12px',
    outline: 'none',
    paddingLeft: '50px',
    '&::placeholder': {
      fontSize: '1.2rem',
      fontWeight: 'bold',
      color: '#A0A3BD'
    }
  },

  iconSearch: { position: 'absolute', left: '14px', top: '13px', color: '#A0A3BD' },
  shuffleWrapp: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#14142B',
    padding: '5px',
    borderRadius: '9px',
    border: '3px solid #A996FF'
  }
});

const HeaderAsset = ({ title, setSearchTerm, children: HeaderAssetButtons }) => {
  const cssHeaderStyle = headerAssetStyles();

  const { showSideMenu, setShowSideMenu } = useContext(SidebarActionsContext);

  return (
    <Grid container className={cssHeaderStyle.headLibWrapp}>
      <Grid item xs={12} sm={6} lg={4} md={4} className={cssHeaderStyle.headTitleWrapp}>
        {!showSideMenu && <AiOutlineMenuUnfold style={{ fontSize: '33px', marginRight: '20px' }} onClick={() => setShowSideMenu(true)} />}
        <h1>{title}</h1>
      </Grid>
      <Grid item xs={12} sm={12} lg={8} md={8} className={cssHeaderStyle.restlibWrapp}>
        {HeaderAssetButtons}
        <div className={cssHeaderStyle.searchWrapp}>
          <input onChange={(e) => setSearchTerm(e.target.value)} className={cssHeaderStyle.searchInp} placeholder="Search" />
          <SearchIcon className={cssHeaderStyle.iconSearch} style={{ fontSize: '29px' }} />
        </div>

        <div className={cssHeaderStyle.shuffleWrapp}>
          <SyncAltIcon style={{ fontSize: '37px', padding: '5px', color: '#A996FF' }} />
        </div>
      </Grid>
    </Grid>
  );
};

export default HeaderAsset;
