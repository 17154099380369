import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;
const url = '/api/Auth/DeleteUser/';
const deleteUser = (id) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  return {
    req: axios.delete(baseUrl + url + id, {
      method: 'DELETE',
      headers: {
      },
      params: {},
      cancelToken: source.token,
      
    }),
    cancelTokenSource: source
  };
};

export default {
  deleteUser
}