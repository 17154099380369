import UploadIcon from '@mui/icons-material/Upload';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popper from '@mui/material/Popper';
import { useEffect, useRef, useState } from 'react';
import { AiOutlineMore, AiOutlineStar } from 'react-icons/ai';
import { FiThumbsUp } from 'react-icons/fi';
import { toast } from 'react-toastify';
import noThumb from '../../files/NoThumbnail.jpg';
import projectApi from '../../services/apiServices/projects/projectApi';
import useStyleCardAsset from '../library/assetSection/cardasset/useStyleCardAsset';
import DeleteModal from '../modals/deleteModal/DeleteModal';
import MovieTranscribeModal from '../modals/MovieTranscribeModal';
import MovieTranslationModal from '../modals/MovieTranslationModal';
import MoviePageThumbnailImage from './custom/MoviePageThumbnailImage';
import image_loader from './media_movie/img_loader.gif';
import playBtnImg from './media_movie/playbtn.png';
import MoviesSettingsDialogue from './MoviesSettingsDialogue';
import MovieThumbnailUpload from './MovieThumbnailUpload';
import ShareMovieDialog from './ShareDialog';

const NewMoviePageThumbnail = ({ isMobile, data, setOpenModal, setVideoUrl, setMovies, movies, languageList, selectedMoreId, setSelectedMoreId }) => {
  const { styleMovieThumbnail } = useStyleCardAsset();
  const cssStyle = styleMovieThumbnail();
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [movieOptionsAnchorEl, setMovieOptionsAnchorEl] = useState(null);
  const [openShareMovieDialog, setOpenShareMovieDialog] = useState(false);
  const [openMovieSettingsDialog, setOpenMovieSettingsDialog] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openTranslation, setOpenTranslation] = useState(false);
  const [openTranscription, setOpenTranscription] = useState(false);

  const handleDeleteOpen = () => {
    setOpenDelete(true);
  };

  const IsProcessingToastr = () => {
    toast.info('Wait render to finish.', {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
  };

  const deleteMovie = (id) => {
    const { req } = projectApi.deleteMovie(id);
    req.then((resp) => {
      let moviesFilter = movies.filter((element) => (element.id === id ? false : true)).map((element) => element);
      if (moviesFilter != null) {
        setMovies(moviesFilter);
      } else {
        setMovies([]);
      }
    });
  };

  useEffect(() => {
    setThumbnailUrl(data.storageUrl + data.thumbnailPath + '?' + new Date().getTime());
  }, []);

  const uploadFileRef = useRef(null);

  const uploadThumbnail = (e) => {
    e.stopPropagation();
    let el = uploadFileRef.current;
    el.click();
  };

  const closeMovieOptions = (event) => {
    event.stopPropagation();
    setMovieOptionsAnchorEl(null);
    //setOpenMovieOptions(false);
    setSelectedMoreId('');
  };

  const openPreview = (e) => {
    e.stopPropagation();
    if (data.isProcessing === true) {
      return;
    }

    setOpenModal(true);
    setVideoUrl(data.storageUrl + data.moviePath);
  };

  return (
    <div className={cssStyle.newMovieWrapp}>
      <div className={`${cssStyle.newMovie} ${cssStyle.thubmnailSize}`}>
        <div className={cssStyle.newMovie}>
          {!data?.isProcessing ? (
            <MoviePageThumbnailImage cssStyle={cssStyle} data={data} thumbnailUrl={thumbnailUrl} noThumb={noThumb} openPreview={openPreview} image_loader={image_loader} />
          ) : (
            <div className={`${cssStyle.thubmnailSize} ${cssStyle.logoMovie} ${cssStyle.loaderWrapp}`}>
              <CircularProgress disableShrink style={{ color: 'white' }} />
            </div>
          )}

          {/* <div className={cssStyle.interaction}>
            <AiOutlineStar color="white" size={20} />
            <FiThumbsUp color="white" size={20} style={{ marginLeft: '10px' }} />
          </div> */}

          {!data?.isProcessing && <img onClick={openPreview} src={playBtnImg} alt="play-btn" className={cssStyle.playBtn} />}

          <div
            onClick={(e) => {
              if (data.isProcessing === true) {
                IsProcessingToastr();
                return;
              }
              uploadThumbnail(e);
            }}
            className={cssStyle.uploadImg}
          >
            <UploadIcon style={{ color: 'white', fontSize: '20px' }} />
            <MovieThumbnailUpload data={data} setThumbnailUrl={setThumbnailUrl} uploadFileRef={uploadFileRef} />
          </div>
        </div>

        <div className={cssStyle.movieOptions}>
          <AiOutlineMore
            style={{ cursor: 'pointer', stroke: '#A996FF', width: '40px', strokeWidth: '100', filter: 'drop-shadow(3px 3px 3px rgb(0 0 0 / 1))' }}
            color="#A996FF"
            size={30}
            onClick={(event) => {
              event.stopPropagation();

              if (data.isProcessing === true) {
                IsProcessingToastr();
                return;
              }
              setMovieOptionsAnchorEl(event.currentTarget);
              setSelectedMoreId(data?.id);
            }}
          />
        </div>

        {/* infoDivMobile */}
        <div className={isMobile ? cssStyle.infoDivMobile : cssStyle.infoDiv}>
          <div className={cssStyle.movieName}>{data.name}</div>
          <div className={cssStyle.date}>{new Date(data.renderedDate).toLocaleDateString('de') + (data.isProcessing === true ? ' Rendering... ' : '')}</div>
        </div>
      </div>

      <ClickAwayListener onClickAway={(event) => closeMovieOptions(event)}>
        <Popper open={selectedMoreId === data?.id} anchorEl={movieOptionsAnchorEl} placement={'bottom'} transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Box className="movie-options-cloud" sx={{ bgcolor: '#4E4B66', color: 'white', borderRadius: '20px' }}>
                <MenuList>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenMovieSettingsDialog(true);
                      closeMovieOptions(e);
                    }}
                  >
                    Settings
                  </MenuItem>

                  {!isMobile && (
                    <MenuItem
                      onClick={(e) => {
                        openPreview(e);
                      }}
                    >
                      Preview
                    </MenuItem>
                  )}

                  <MenuItem
                    onClick={(e) => {
                      setOpenShareMovieDialog(true);
                      closeMovieOptions(e);
                      e.stopPropagation();
                    }}
                  >
                    Share
                  </MenuItem>

                  {!isMobile && (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        window.location.href = data.storageUrl + data.moviePath;
                        closeMovieOptions(e);
                      }}
                    >
                      Download
                    </MenuItem>
                  )}

                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteOpen();
                      closeMovieOptions(e);
                    }}
                  >
                    Delete
                  </MenuItem>
                  {!!data.movieAudio && !!data.movieAudio?.speechPath && !isMobile && (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenTranslation(true);
                      }}
                    >
                      Subtitle
                    </MenuItem>
                  )}
                  {!data.movieAudio && !data.movieAudio?.speechPath && !isMobile && (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenTranscription(true);
                      }}
                    >
                      Subtitle
                    </MenuItem>
                  )}
                </MenuList>
              </Box>
            </Fade>
          )}
        </Popper>
      </ClickAwayListener>

      {openMovieSettingsDialog && (
        <MoviesSettingsDialogue
          isMobile={isMobile}
          data={data}
          openMovieSettingsDialog={openMovieSettingsDialog}
          setOpenMovieSettingsDialog={setOpenMovieSettingsDialog}
          thumbnailUrl={thumbnailUrl}
          movies={movies}
          setMovies={setMovies}
          setThumbnailUrl={setThumbnailUrl}
          uploadFileRef={uploadFileRef}
          uploadThumbnail={uploadThumbnail}
          isSettings={true}
          addToRender={() => {}}
        />
      )}

      <ShareMovieDialog data={data} isMobile={isMobile} openShareMovieDialog={openShareMovieDialog} setOpenShareMovieDialog={setOpenShareMovieDialog} thumbnailUrl={thumbnailUrl} />
      {openDelete && <DeleteModal setOpenDeleteModal={setOpenDelete} deleteFuncion={deleteMovie} type="project" text="Are you sure you want delete this?" projectData={data} />}

      <MovieTranslationModal openTranslation={openTranslation} setOpenTranslation={setOpenTranslation} movieData={data} languageList={languageList} />
      <MovieTranscribeModal openTranscription={openTranscription} setOpenTranscription={setOpenTranscription} movieData={data} languageList={languageList} />
    </div>
  );
};

export default NewMoviePageThumbnail;
NewMoviePageThumbnail.defaultProps = {
  isMobile: false, 
  data: [], 
  setOpenModal: ()=>{}, 
  setVideoUrl:()=>{}, 
  setMovies: ()=>{}, 
  movies: [], 
  languageList: [], 
  selectedMoreId: '', 
  setSelectedMoreId: ()=>{}
}