import { typography } from "@mui/system";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;
const saveBrandingUrl = "/api/Project/savebranding";
const getBrandingUrl = "/api/Project/branding";
const deleteBrandingUrl = "/api/Project/delete-branding";
const uploadBrandingUrl = "/api/File/upload-branding";

const saveBranding = ({ ID, BrandingTypeID, Item, Url }) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem("token");
  
  return {
    axiosReq: axios({
      method: "post",
      url: baseUrl + saveBrandingUrl,
      data: {
        id: ID,
        brandingTypeID: BrandingTypeID,
        item: Item,
        url: Url
      },
      headers: {
        Authorization: "Bearer " + token
      }
    }),
    cancelTokenSource: source
  };
};

const getBranding = () => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem("token");

  return {
    axiosReq: axios.get(baseUrl + getBrandingUrl, {
      cancelToken: source.token,
      headers: { Authorization: "Bearer " + token }
    }),
    cancelTokenSource: source
  };
};

const deleteBranding = (id) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem("token");

  return {
    req: axios.get(baseUrl + deleteBrandingUrl, {
      params: { ID: id },
      cancelToken: source.token,
      headers: { Authorization: "Bearer " + token }
    }),
    cancelTokenSource: source
  };
};

const uploadBranding = (data) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem("token");

  return {
    axiosReq: axios({
      method: "post",
      url: baseUrl + uploadBrandingUrl,
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token
      }
    }),
    cancelTokenSource: source
  };
};

export default {
  saveBranding,
  getBranding,
  deleteBranding,
  uploadBranding
};
