import {useState} from 'react'
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import folderIcon from '../../movies/media_movie/Folders.png';
import searchIcon from '../../movies/media_movie/Search.png';
import UploadFilAsset from '../assetSection/filterAsset/UploadFilAsset';
import FilterRight from '../assetSection/filterAsset/FilterRight';

const MobileTabsLibrary = ({isLabTabs=false, isOnSettings=false, tittleSection='Asset Librarys',mobileSubLinks=[], setActiveSubLibTab=()=>{}, activeSubLibTab=0 }) =>{ 
  return (
    <Box style={{ padding: isOnSettings && '14px', marginTop:isOnSettings && '70px'}}>
        {!isLabTabs &&<Box style={{color:'white' }}>
            <h4>{tittleSection}</h4>
        </Box>}


         <Box 
            style={{
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                marginTop: '0px'
            }}
        >
        <div
            style={{ 
            marginTop:'10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start'
            }}
         >
            {mobileSubLinks?.length > 0 && mobileSubLinks.map((item, index) => {
                return (
                    <div
                        key={index}
                        onClick={() => setActiveSubLibTab(index)}
                        style={{
                            width:isOnSettings ? '50%' :'33%',
                            display: 'flex',
                            flexDirection: 'column',
                            //marginLeft: index === 0 ? '3px' : '30px',
                            marginLeft: '3px',
                            fontSize: '15px',
                            color: activeSubLibTab === index ? '#B9A4E9' : '#545773'
                        }}
                    >
                    <p 
                        style={{
                        width: '120px',
                        height: '22px',
                        fontFamily: "'Poppins'",
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '13px',
                        lineHeight: '22px',
                        letterSpacing: '0.25px'
                        }}
                    >{item}</p>
                    <span
                        style={{
                        marginTop: '-10px',
                        height: '2px',
                        width: '100%',
                        backgroundColor: activeSubLibTab === index ? '#B9A4E9' : '#545773'
                        }}
                    ></span>
                    </div>
                );
                })}
        </div>
    </Box> 
  </Box>
  )
}

export default MobileTabsLibrary