import projectApi from '../../../services/apiServices/projects/projectApi';

const useProject = ({ setFayeUserSpec, searchString, projectName, projectDescription, setContributorsList, contributorsList, privacyID }) => {
  const getFayeUsersByCompId = () => {
    const { axiosReq } = projectApi.getFayeUsersByCompanyID();

    axiosReq
      .then((res) => {
        setFayeUserSpec(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getSearchFayeUsers = () => {
    const { req } = projectApi.getFayeUsersSearch(searchString);

    req
      .then((res) => {
        setFayeUserSpec(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getSpecUserByCompanyId = () => {
    const { axiosReq } = projectApi.getFayeUsersByCompanyID();

    axiosReq
      .then((res) => {
        setFayeUserSpec(res.data.data);
      })
      .catch((err) => console.log('err' + err) );
  };

  const saveSettProject = (ID) => {
    let obj = {
      Name: projectName,
      Description: projectDescription,
      ID: ID,
      PrivacyID: privacyID,
      AddedContributors: contributorsList
    };

    const { req } = projectApi.saveProjectSettings(obj);
    return req;
  };

  const saveMovieSettings = (ID) => {
    let obj = {
      Name: projectName,
      Description: projectDescription,
      ID: ID,
      PrivacyID: privacyID
    };

    const { req } = projectApi.saveProjectSettings(obj, true);
    return req;
  };

  const getContributors = (projId) => {
    const { axiosReq } = projectApi.getContributors(projId);

    axiosReq.then((res) => {
      if (res.data.success) {
        setContributorsList(res.data.data);
      }
    });
  };

  return { getFayeUsersByCompId, getSearchFayeUsers, getSpecUserByCompanyId, saveSettProject, saveMovieSettings, getContributors };
};

export default useProject;
