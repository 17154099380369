import { Grid } from '@material-ui/core';
import CardAsset from '../CardAsset';
import MobileFolder from '../mobile/MobileFolder';

const LibraryList = ({ isMobile, setActiveBtnHeader, assetsData, itemsReadyDelete, setActiveSound, activeSound, activeBtnHeader, setRerenderSave }) => {
  return (
    <>
      <Grid container style={{ marginTop: activeBtnHeader === 0 ? '77px' : '20px' }}>
        {isMobile && activeBtnHeader === 0 && <MobileFolder setActiveBtnHeader={setActiveBtnHeader} />}
        {assetsData.map((p) => (
          <Grid key={p.id} item xl={3} lg={4} md={6} sm={isMobile ? 6 : 12} xs={isMobile ? 6 : 12} align="center" onClick={() => { }} style={{ marginTop: isMobile && activeBtnHeader === 0 && '15px' }}>
            <CardAsset isMobile={isMobile} key={p.id} activeBtnHeader={activeBtnHeader} setRerenderSave={setRerenderSave} itemsReadyDelete={itemsReadyDelete} item={p} setActiveSound={setActiveSound} activeSound={activeSound} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default LibraryList;
