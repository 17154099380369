import { useEffect, useState } from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import noThumb from '../../../../files/NoThumbnail.jpg';

const AudioTag = ({ checkToggle, item, activeSound, setActiveSound, classStyle }) => {
  const [isPlay, setIsPlay] = useState(false);

  const setSoundItem = () => {
    if (isPlay) {
      setIsPlay(false);
      setActiveSound({})
    }
    else {
      setIsPlay(true);
      setActiveSound(item)
    }
  }

  useEffect(() => {
    if (Object.keys(activeSound).length > 0 && activeSound.id === item.id) {
      setIsPlay(true);
    }
    else {
      setIsPlay(false);
    }
  }, [activeSound]);

  return (
    <div className={classStyle}  style={{ background: item.previewUrl !== null ? 'url(' + item.previewUrl + ')' : 'url(' + noThumb + ')', backgroundSize: 'cover', border: checkToggle ? '3px solid #A996FF' : '0px solid #A996FF' }}>
      {!isPlay ? <PlayArrowIcon style={{ fontSize: '45px' }} onClick={() => setSoundItem()} /> : <PauseIcon style={{ fontSize: '45px' }} onClick={() => setSoundItem()}  />}
    </div>
  );
};

export default AudioTag;
