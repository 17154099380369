import { makeStyles } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useEditAsset from './useEditAsset';
import { toast } from 'react-toastify';

const styleShareDialog = makeStyles({
  shareMovieDialogText: {
    fontFamily: 'Poppins',
    fontSize: '13px',
    opacity: '50%',
    marginTop: '20px',
    marginBottom: '0px',
    height: '20px'
  },
  shareMovieTextField: {
    backgroundColor: '#262338',
    borderRadius: '15px',
    width: '100%',
    height: '55px',
    border: 'none',
    padding: '7px',
    marginTop: '8px',
    color: 'white',
    '& input': {
      marginLeft: '15px',
      height: '23px',
      paddingTop: '12px',
      fontFamily: 'Poppins'
    }
  },
  shareMovieTextarea: {
    backgroundColor: '#262338',
    borderRadius: '15px',
    width: '100%',
    height: '93px',
    border: 'none',
    marginTop: '8px',
    color: 'white',
    padding: '7px',
    resize: 'none',
    '& p': { marginLeft: '15px', height: '23px', paddingTop: '12px', fontFamily: 'poppins' }
  },
  selectWrapp: {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    outline: 'none',
    backgroundColor: '#262338',
    color: 'white',
    marginTop: '8px'
  },
  contributorsWrappInner: { width: '100%', display: 'flex' },
  imgWrapp: { width: '48px', height: '48px', position: 'relative' },
  image: { width: '100%', height: '100%', borderRadius: '50%' },
  delImg: { fontSize: '10px', fontWeight: 'bold', borderRadius: '50%', padding: '5px', backgroundColor: 'red', position: 'absolute', right: '-5px', top: '-5px' },
  ulistWrapp: { width: '100%', backgroundColor: '#262338', marginTop: '10px', padding: '10px', borderRadius: '10px' },
  ulistHeader: { width: '100%', display: 'flex', justifyContent: 'space-between' },
  searchWrapp: { width: '100%' },
  searchWrappInner: { width: '100%', position: 'relative', margin: '10px 0' },
  searchInp: { color: 'white', backgroundColor: '#262338', border: '1px solid white', width: '100%', height: '40px', borderRadius: '10px', outline: 'none', paddingLeft: '30px' },
  iconSearch: { position: 'absolute', left: '5px', top: '7px', color: 'white' },
  saveBtnWrapp: { width: '100%', marginTop: '30px', display: 'flex', justifyContent: 'flex-end' },
  saveBtn: { width: '26%', backgroundColor: '#A996FF', padding: '15px 25px', borderRadius: '10px', borderColor: '#A996FF', color: '#5312B5', fontWeight: 'bold', fontSize: '1.15rem', display: 'flex', justifyContent: 'center' }
});

const EditAsset = ({ item, setMore, setRerenderSave, activeBtnHeader }) => {
  const classes = styleShareDialog();
  const { name, descr, privacyID, setName, setDescr, setPrivacyID, updateAsset } = useEditAsset({ item, setMore, setRerenderSave, activeBtnHeader });

  const handleSaveAssetSettings = () => {
    if (!item.isDataEditable) {
      toast.warn('Data can be edited only if it is created by users from same company.', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }
    updateAsset();
  };

  return (
    <Dialog
      PaperProps={{ style: { zIndex: '12', backgroundColor: '#14142B', width: '90%', border: '1.5 solid #4E4B66', borderRadius: '30px', color: 'white', fontFamily: 'Poppins' } }}
      open={true}
      onClose={() => setMore(false)}
    >
      <DialogTitle style={{ fontFamily: 'Poppins' }}>Asset Settings</DialogTitle>

      <DialogContent>
        <p className={classes.shareMovieDialogText}>Name:</p>
        <input onClick={(e) => e.stopPropagation()} onChange={(e) => setName(e.target.value)} type="text" value={name} className={classes.shareMovieTextField} />

        <p className={classes.shareMovieDialogText}>Description:</p>
        <textarea onClick={(e) => e.stopPropagation()} onChange={(e) => setDescr(e.target.value)} type="text" value={descr} className={classes.shareMovieTextarea}>
          {descr}
        </textarea>

        <p className={classes.shareMovieDialogText}>Privacy:</p>
        <select
          className={classes.selectWrapp}
          value={privacyID}
          onChange={(e) => {
            if (!item.isPrivacyEditable) {
              toast.warn('Privacy can be edited only by user who created project.', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
              });
              return;
            }
            setPrivacyID(e.target.value);
          }}
        >
          <option value={1}>Private</option>
          <option value={2}>My Company</option>
          <option value={3}>Public</option>
        </select>

        <div className={classes.saveBtnWrapp}>
          <button onClick={() => handleSaveAssetSettings()} type="button" className={classes.saveBtn}>
            Save
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditAsset;

EditAsset.defaultProps = {
  item: {
    id: '..',
    name: '',
    description: ''
  }
};
